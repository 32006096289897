import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";

import TextLink from "../TextLink";

export enum ExtractionStatusType {
  Approved = "approved",
  Extracting = "extracting",
  Failed = "failed",
  Review = "review",
  Rejected = "rejected",
  Canceled = "canceled",
}

export interface ExtractionStatusProps {
  type: ExtractionStatusType;
  onRetryClick?: () => void;
  textId?: string;
  retryTextId?: string;
}

export function ExtractionStatus(props: ExtractionStatusProps) {
  const { type, onRetryClick, textId } = props;

  const defaultTextId = `extraction_status.${type}`;
  const statusTextId = textId ?? defaultTextId;
  const retryTextId = props.retryTextId ?? "extraction_status.retry";

  return (
    <div className="flex items-center gap-[4px] text-sm">
      {type === ExtractionStatusType.Failed && (
        <Icon iconName="Info" className="text-red-700" />
      )}
      <span
        className={cn({
          "text-green-500": type === ExtractionStatusType.Approved,
          "text-tomato-600": type === ExtractionStatusType.Extracting,
          "text-red-700": type === ExtractionStatusType.Failed,
          "text-yellow-700": type === ExtractionStatusType.Review,
          "text-red-600": type === ExtractionStatusType.Rejected,
          "text-blue-500": type === ExtractionStatusType.Canceled,
        })}
      >
        <FormattedMessage id={statusTextId} />
      </span>
      {type === ExtractionStatusType.Failed && (
        <TextLink onClick={onRetryClick} className="select-none ml-[4px]">
          <FormattedMessage id={retryTextId} />
        </TextLink>
      )}
    </div>
  );
}
