import * as yup from "yup";

import { deepEqual } from "../utils/deepEqual";
import {
  extractAlphaNumbericAndHyphenChars,
  generateRandomStringWithLowercaseAndNumber,
} from "../utils/string";
import { pageInfoWithOffsetSchema } from "./pageInfo";
import { planQuotaSchema } from "./quota";
import { resourceOwnerSchema } from "./resourceOwner";

export const teamLookupEntrySchema = yup
  .object({
    region: yup.string().defined(),
  })
  .camelCase();

export const teamRefSchema = yup
  .object({
    name: yup.string().defined(),
    lookupId: yup.string().defined(),
  })
  .camelCase();

export type TeamLookup = yup.InferType<typeof teamLookupEntrySchema>;
export type TeamRef = yup.InferType<typeof teamRefSchema>;

export const briefTeamSchema = resourceOwnerSchema
  .shape({
    name: yup.string().required(),
    lookupId: yup.string().defined(),
    createdAt: yup.date().defined(),
    contactEmail: yup.string().nullable(),
  })
  .camelCase();

export const permissionSchema = yup
  .object({
    viewSubscription: yup.boolean().defined(),
    editSubscription: yup.boolean().defined(),
    viewMembership: yup.boolean().defined(),
    editMembership: yup.boolean().defined(),
    viewResource: yup.boolean().defined(),
    editResource: yup.boolean().defined(),
    createResource: yup.boolean().defined(),
    removeResource: yup.boolean().defined(),
    viewTeamSetting: yup.boolean().defined(),
    editTeamSetting: yup.boolean().defined(),
    viewAuditLog: yup.boolean().defined(),
  })
  .camelCase();

export const teamPermissionSchema = yup
  .object({
    teamId: yup.string().required(),
    permission: permissionSchema.required(),
  })
  .camelCase();

export const teamUserSchema = yup
  .object({
    email: yup.string().required(),
    userId: yup.string().required(),
    permission: permissionSchema.required(),
  })
  .camelCase();

export const teamInvitationSchema = yup
  .object({
    email: yup.string().required(),
    permission: permissionSchema.required(),
    id: yup.string().required(),
  })
  .camelCase();

export const teamInvitationListItemSchema = yup
  .object({
    permission: permissionSchema.required(),
    id: yup.string().required(),
    teamName: yup.string().required(),
    createdAt: yup.string().required(),
  })
  .camelCase();

export type TeamInvitationListItem = yup.InferType<
  typeof teamInvitationListItemSchema
>;

export enum TeamInvitationStatus {
  Pending,
  Deleted,
  Accepted,
}

export type TeamInvitationListItemStore = TeamInvitationListItem & {
  status: TeamInvitationStatus;
  teamId?: string;
};

export const teamMembersAndInvitationsSchema = yup
  .object({
    members: yup.array(teamUserSchema.defined()).defined(),
    invitations: yup.array(teamInvitationSchema.defined()).defined(),
    updatedAt: yup.string().defined(),
  })
  .camelCase();

export type TeamMembersAndInvitations = yup.InferType<
  typeof teamMembersAndInvitationsSchema
>;

export const teamInvitationIsValidRespSchema = yup
  .object({
    isValid: yup.boolean().defined(),
    reason: yup.string().optional(),
    permission: permissionSchema.optional().default(undefined),
    teamName: yup.string().optional(),
  })
  .camelCase();

export type TeamInvitationIsValidResp = yup.InferType<
  typeof teamInvitationIsValidRespSchema
>;

export const teamSetUserPermissionRespSchema = yup
  .object({
    teamUser: teamUserSchema.defined(),
    updatedAt: yup.string().defined(),
  })
  .camelCase();

export type TeamSetUserPermissionResp = yup.InferType<
  typeof teamSetUserPermissionRespSchema
>;

export const teamRenameRespSchema = yup
  .object({
    updatedAt: yup.string().defined(),
  })
  .camelCase();

export type TeamRenameResp = yup.InferType<typeof teamRenameRespSchema>;

export const acceptTeamInvitationRespSchema = yup.object({
  team: briefTeamSchema.defined(),
  permission: permissionSchema.defined(),
});
export type AcceptTeamInvitationResp = yup.InferType<
  typeof acceptTeamInvitationRespSchema
>;

export type Permission = yup.InferType<typeof permissionSchema>;
export type PermissionResp = {
  edit_resource: boolean;
  view_resource: boolean;
  create_resource: boolean;
  edit_membership: boolean;
  remove_resource: boolean;
  view_membership: boolean;
  edit_subscription: boolean;
  edit_team_setting: boolean;
  view_subscription: boolean;
  view_team_setting: boolean;
  view_audit_log: boolean;
};

export type TeamPermission = yup.InferType<typeof teamPermissionSchema>;
export type BriefTeam = yup.InferType<typeof briefTeamSchema>;
export type TeamUser = yup.InferType<typeof teamUserSchema>;
export type TeamInvitationListItemTeamInvitation = yup.InferType<
  typeof teamInvitationSchema
>;

// Also update getTeamRole/getTeamRole(by)MessageId if you add/remove any of these
export const adminPermission: Permission = {
  viewSubscription: true,
  editSubscription: true,
  viewMembership: true,
  editMembership: true,
  viewResource: true,
  editResource: true,
  createResource: true,
  removeResource: true,
  viewTeamSetting: true,
  editTeamSetting: true,
  viewAuditLog: true,
} as const;
type AdminPermission = typeof adminPermission;

// Also update getTeamRole/getTeamRole(by)MessageId if you add/remove any of these
export const editorPermission: Permission = {
  viewSubscription: true,
  editSubscription: false,
  viewMembership: true,
  editMembership: false,
  viewResource: true,
  editResource: true,
  createResource: true,
  removeResource: true,
  viewTeamSetting: true,
  editTeamSetting: false,
  viewAuditLog: false,
} as const;
type EditorPermission = typeof editorPermission;

// Also update getTeamRole/getTeamRole(by)MessageId if you add/remove any of these
export const readOnlyPermission: Permission = {
  viewSubscription: true,
  editSubscription: false,
  viewMembership: true,
  editMembership: false,
  viewResource: true,
  editResource: false,
  createResource: false,
  removeResource: false,
  viewTeamSetting: true,
  editTeamSetting: false,
  viewAuditLog: false,
} as const;
type ReadOnlyPermission = typeof readOnlyPermission;

// Also update getTeamRole/getTeamRole(by)MessageId if you add/remove any of these
export type TeamRole = "admin" | "editor" | "read-only" | "custom";

export function isAdminPermission(
  permission: Permission
): permission is AdminPermission {
  return deepEqual(permission, adminPermission);
}

export function isEditorPermission(
  permission: Permission
): permission is EditorPermission {
  return deepEqual(permission, editorPermission);
}

export function isReadOnlyPermission(
  permission: Permission
): permission is ReadOnlyPermission {
  return deepEqual(permission, readOnlyPermission);
}

export function getTeamRole(permission: Permission): TeamRole {
  switch (true) {
    case isReadOnlyPermission(permission):
      return "read-only";
    case isEditorPermission(permission):
      return "editor";
    case isAdminPermission(permission):
      return "admin";
    default:
      return "custom";
  }
}

export function getTeamRoleMessageId(
  permission: Permission,
  withArticle = false
) {
  switch (true) {
    case isReadOnlyPermission(permission):
      return withArticle
        ? "team.role.read_only.with_article"
        : "team.role.read_only";
    case isEditorPermission(permission):
      return withArticle ? "team.role.editor.with_article" : "team.role.editor";
    case isAdminPermission(permission):
      return withArticle ? "team.role.admin.with_article" : "team.role.admin";
    default:
      return withArticle ? "team.role.custom.with_article" : "team.role.custom";
  }
}

export function getTeamRoleByMessageId(messageId: string) {
  switch (messageId) {
    case "team.role.read_only":
      return readOnlyPermission;
    case "team.role.editor":
      return editorPermission;
    case "team.role.admin":
      return adminPermission;
    default:
      return undefined;
  }
}

export const teamListItemSchema = yup
  .object({
    id: yup.string().required(),
    lookupId: yup.string().required(),
    name: yup.string().required(),
    createdAt: yup.date().defined(),
    planName: yup.string().nullable(),
    planId: yup.string().nullable(),
    contactEmail: yup.string().nullable(),
    totalUsage: yup.number().nullable(),
  })
  .camelCase();

export type TeamListItem = yup.InferType<typeof teamListItemSchema>;

export const paginatedTeamsSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    teams: yup.array(teamListItemSchema).defined(),
  })
  .camelCase();

export type PaginatedTeams = yup.InferType<typeof paginatedTeamsSchema>;

export const teamDetailSchema = briefTeamSchema
  .shape({
    createdAt: yup.date().defined(),
    totalUsage: yup.number().nullable(),
    lastUseAt: yup.date().nullable(),
    rawQuota: planQuotaSchema.nullable(),
    planQuota: planQuotaSchema.nullable(),
    subscriptionSubscribedAt: yup.string().nullable(),
    stripeCustomerId: yup.string().nullable(),
    stripeSubscriptionId: yup.string().nullable(),
    planEndAt: yup.date().nullable(),
    enabledFeatures: yup.array(yup.string().defined()).defined(),
    enabledAuditLog: yup.boolean().defined(),
  })
  .camelCase();

export type TeamDetail = yup.InferType<typeof teamDetailSchema>;

export const adminGetTeamDetailRespSchema = yup.object({
  team: teamDetailSchema.defined(),
});
export type AdminGetTeamDetailResp = yup.InferType<
  typeof adminGetTeamDetailRespSchema
>;

export const teamUsageItemSchema = yup
  .object({
    team: teamListItemSchema.required(),
    usageCount: yup.number().required(),
  })
  .camelCase();

export type TeamUsageItem = yup.InferType<typeof teamUsageItemSchema>;

export const adminTeamsUsagesSchema = yup
  .object({
    teamsUsages: yup.array(teamUsageItemSchema).defined(),
  })
  .camelCase();

export type AdminTeamsUsages = yup.InferType<typeof adminTeamsUsagesSchema>;

const TEAM_LOOKUPID_HASH_SHORT_LENGTH = 4;
const TEAM_LOOKUPID_HASH_LONG_LENGTH = 8;
const TEAM_LOOKUPID_MIN_LENGTH = 8;
const TEAM_LOOKUPID_MAX_LENGTH = 100;

export function generateTeamLookupId(teamName: string, uuid?: string) {
  const prefix = extractAlphaNumbericAndHyphenChars(teamName)
    .replaceAll(" ", "-")
    .toLowerCase();

  const suffix = uuid
    ? uuid
    : generateRandomStringWithLowercaseAndNumber(
        TEAM_LOOKUPID_HASH_LONG_LENGTH
      );

  const teamLookupId = `${prefix}-${suffix.substring(
    0,
    TEAM_LOOKUPID_HASH_SHORT_LENGTH
  )}`;

  return teamLookupId.length >= TEAM_LOOKUPID_MIN_LENGTH &&
    teamLookupId.length <= TEAM_LOOKUPID_MAX_LENGTH
    ? teamLookupId
    : suffix;
}
