import { TextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import cntl from "cntl";
import * as React from "react";

import { JSON_INDENTATION } from "../../constants/format";
import {
  ExtractDataFieldType,
  ExtractHTMLTableData,
  ExtractListTableData,
} from "../../types/extractDataField";
import { ExtractedContentSchemaType } from "../../types/extractedContentSchema";
import { SmallIconButton } from "../SmallIconButton";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

function RawValue({ value }: { value: any }) {
  if (value === null) {
    return <></>;
  }

  return (
    <pre className={styles["raw-json-viewer"]}>
      {JSON.stringify(value, null, JSON_INDENTATION)}
    </pre>
  );
}

type HTMLTableProps = {
  label: string;
  value: ExtractHTMLTableData;
  className?: string;
  actions?: React.ReactNode;
};

export function ExtractHTMLTableDataField(props: HTMLTableProps) {
  const { label, value, actions } = props;
  const className = cn(styles["data-item-table"], props.className);

  let content = null;
  try {
    content = value.map((row, rowIndex) => {
      return row.map((cell, index) => {
        if (cell == null) {
          return null;
        }
        return (
          <div
            className={styles["data-item-table-cell"]}
            key={`row/${rowIndex}/cell/${index}`}
            style={{
              gridColumn: `${cell.colStart} / ${cell.colEnd}`,
              gridRow: `${cell.rowStart} / ${cell.rowEnd}`,
            }}
          >
            {cell.text}
          </div>
        );
      });
    });
  } catch {}

  return (
    <div className={className}>
      <div className={styles["data-item-table-topbar"]}>
        <div className={styles["data-item-table-label"]}>{label}</div>
        {actions}
      </div>
      <div className={styles["data-item-table-scrollable"]}>
        <div
          className={styles["data-item-table-content"]}
          style={{
            display: "grid",
            gridTemplateRows: "repeat(32px)",
          }}
        >
          {content}
        </div>
      </div>
    </div>
  );
}

type ListTableProps = {
  label: string;
  value: ExtractListTableData;
  className?: string;
  actions?: React.ReactNode;
  isEditable?: boolean;
  onChange?: (value: any) => void;
};

const TRASH_HEADER_KEY = "<<<TRASH-HEADER-VALUE>>>";

type CurrencyCellValue = {
  currency: string;
  value?: number | string;
};

function isCurrencyCellValue(value: unknown): value is CurrencyCellValue {
  return (
    typeof value === "object" &&
    value !== null &&
    "currency" in value &&
    "value" in value
  );
}

type CurrencyCellProps = {
  cellValue: any;
  isEditable: boolean;
  onChange: (value: CurrencyCellValue) => void;
};

function CurrencyCell(props: CurrencyCellProps) {
  const { cellValue, isEditable, onChange } = props;
  const editingCellValue = React.useMemo<CurrencyCellValue>(
    () =>
      isCurrencyCellValue(cellValue)
        ? cellValue
        : { currency: "", value: undefined },
    [cellValue]
  );

  const updateCurrency = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: string | undefined
  ) => {
    if (newValue == null) {
      return;
    }
    onChange({ ...editingCellValue, currency: newValue });
  };

  const updateValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: string | undefined
  ) => {
    if (newValue == null) {
      return;
    }
    onChange({
      ...editingCellValue,
      value: isNaN(parseFloat(newValue)) ? newValue : parseFloat(newValue),
    });
  };

  if (isEditable) {
    return (
      <div className="flex gap-2">
        <TextField
          value={editingCellValue.currency ?? ""}
          onChange={updateCurrency}
        />
        <TextField
          value={
            editingCellValue.value == null
              ? ""
              : editingCellValue.value.toString()
          }
          onChange={updateValue}
        />
      </div>
    );
  } else {
    if (isCurrencyCellValue(cellValue)) {
      return (
        <div className="flex">
          <div className="flex-1">{cellValue.currency}</div>
          <div className="flex-1">{cellValue.value}</div>
        </div>
      );
    }
    return <RawValue value={cellValue} />;
  }
}

export function ExtractListTableDataField(props: ListTableProps) {
  const { label, value, actions, onChange } = props;
  const tableClassName = cn(styles["data-item-table"], props.className);
  const isEditable = props.isEditable ?? false;

  const listValue = React.useMemo(() => {
    const { rows } = value;
    const normalized = Array.isArray(rows) ? rows : rows == null ? [] : [rows];
    return normalized.map(item => {
      return item == null ? {} : item;
    });
  }, [value]);

  const headers = React.useMemo(() => {
    const ret = [...value.headers];

    if (isEditable) {
      ret.push(TRASH_HEADER_KEY);
    }
    return ret;
  }, [value.headers, isEditable]);

  const subHeaderCellClasses = cntl`
  text-[14px] 
  font-normal 
  font-[400] 
  leading-[20px] 
  p-[10px_8px] 
  text-[#3231307f]
  sticky 
  top-[36px] 
  z-[1] 
  h-[36px] 
  border-t 
  border-t-[#edebe9] 
  border-r 
  border-r-[#edebe9]
  [border-top-style:solid]
  [border-right-style:solid]
  bg-[#faf9f8]
  `;

  const subHeaders = React.useMemo(() => {
    if (
      value.types?.some(type => type === ExtractedContentSchemaType.Currency)
    ) {
      return (
        <>
          {value.types.map((type, index) =>
            type === ExtractedContentSchemaType.Currency ? (
              <div
                key={`sub-header-${index}`}
                className={cntl`${subHeaderCellClasses} min-w-[210px] flex [&>div]:flex-1`}
              >
                <div>
                  <FormattedMessage id="extract_table_data_field.currency" />
                </div>
                <div>
                  <FormattedMessage id="extract_table_data_field.amount" />
                </div>
              </div>
            ) : (
              <div
                key={`sub-header-${index}`}
                className={subHeaderCellClasses}
              />
            )
          )}

          {isEditable && <div className={subHeaderCellClasses} />}
        </>
      );
    }
    return null;
  }, [value, isEditable, subHeaderCellClasses]);

  const appendRow = React.useCallback(() => {
    const newValue = [...listValue, {}];
    onChange?.(newValue);
  }, [listValue, onChange]);

  const removeRow = React.useCallback(
    (index: number) => {
      const newValue = [...listValue];
      newValue.splice(index, 1);
      onChange?.(newValue);
    },
    [onChange, listValue]
  );

  const setField = React.useCallback(
    (index: number, key: string, value: string | CurrencyCellValue) => {
      const newValue = [...listValue];
      const newRow = { ...newValue[index], [key]: value };
      newValue[index] = newRow;
      onChange?.(newValue);
    },
    [onChange, listValue]
  );

  return (
    <div className={tableClassName}>
      <div className={styles["data-item-table-topbar"]}>
        <div className={styles["data-item-table-label"]}>{label}</div>
        {actions}
      </div>
      {listValue.length > 0 && (
        <div className={styles["data-item-table-scrollable"]}>
          <div
            className={styles["data-item-table-content"]}
            style={{
              display: "grid",
              gridTemplateColumns: isEditable
                ? `repeat(${headers.length - 1}, 1fr) 44px`
                : `repeat(${headers.length}, 1fr)`,
              gridTemplateRows: "repeat(32px)",
            }}
          >
            {headers.map(header => {
              if (header === TRASH_HEADER_KEY) {
                return (
                  <div
                    className={styles["data-item-table-header-cell"]}
                    key={`header/${header}`}
                  ></div>
                );
              }
              return (
                <div
                  className={styles["data-item-table-header-cell"]}
                  key={`header/${header}`}
                >
                  {header}
                </div>
              );
            })}
            {subHeaders}
            {listValue.map((row, index) => {
              return headers.map((header, headerIndex) => {
                const cellValue = row[header];
                const type = value.types?.[headerIndex];

                const className =
                  header === TRASH_HEADER_KEY
                    ? styles["data-item-table-trash-cell"]
                    : isEditable
                    ? styles["data-item-table-edit-cell"]
                    : styles["data-item-table-cell"];

                return (
                  <div
                    className={className}
                    key={`row/${index}/cell/${header}`}
                  >
                    <>
                      {header === TRASH_HEADER_KEY ? (
                        <SmallIconButton
                          iconName="IconTrash"
                          color="#C4141F"
                          onClick={() => removeRow(index)}
                        />
                      ) : type === ExtractedContentSchemaType.Currency ? (
                        <CurrencyCell
                          cellValue={cellValue}
                          isEditable={isEditable}
                          onChange={(value: CurrencyCellValue) =>
                            setField(index, header, value)
                          }
                        />
                      ) : isEditable ? (
                        <TextField
                          value={cellValue}
                          onChange={(_, newValue) => {
                            if (newValue == null) {
                              return;
                            }
                            setField(index, header, newValue);
                          }}
                          multiline={
                            type === ExtractedContentSchemaType.MultiLineText
                          }
                        />
                      ) : typeof cellValue === "string" ? (
                        cellValue
                      ) : (
                        <RawValue value={cellValue} />
                      )}
                    </>
                  </div>
                );
              });
            })}
          </div>
          {isEditable && (
            <ActionButton
              className={styles["content-list-value-field-add-button"]}
              iconName="Add"
              textId="extract_table_data_field.add_new_row"
              onClick={appendRow}
            />
          )}
        </div>
      )}
    </div>
  );
}

export enum ExtractTableDataType {
  ListTable = ExtractDataFieldType.ListTable,
  HTMLTable = ExtractDataFieldType.HTMLTable,
}

type Props = {
  type: ExtractTableDataType;
  label: string;
  value: ExtractHTMLTableData | ExtractListTableData | undefined;
  className?: string;
  actions?: React.ReactNode;
  isEditable?: boolean;
  onChange?: (value: any) => void;
};

export function ExtractTableDataField(props: Props) {
  const { type, value, onChange } = props;
  if (value === undefined) {
    return <></>;
  }

  switch (type) {
    case ExtractTableDataType.HTMLTable:
      return (
        <ExtractHTMLTableDataField
          {...props}
          value={value as ExtractHTMLTableData}
        />
      );
    case ExtractTableDataType.ListTable:
      return (
        <ExtractListTableDataField
          {...props}
          value={value as ExtractListTableData}
          onChange={onChange}
        />
      );
    default:
      return <></>;
  }
}
