import * as yup from "yup";

export const googleDriveEntitySchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
  })
  .camelCase();

export const googleSpreadsheetListSchema = yup
  .object({
    spreadsheets: yup.array().of(googleDriveEntitySchema.defined()).defined(),
  })
  .camelCase();

export const detailedGoogleSheetSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    columns: yup.array().of(yup.string().defined()).defined(),
  })
  .camelCase();

export const detailedGoogleSheetListSchema = yup
  .object({
    sheets: yup.array().of(detailedGoogleSheetSchema.defined()).defined(),
  })
  .camelCase();

export const googleSheetFieldMappingSchema = yup.object({
  column_index: yup.number().required(),
  column_name: yup.string().required(),
  field_name: yup.string().required(),
});

export type GoogleSpreadsheet = yup.InferType<typeof googleDriveEntitySchema>;

export type GoogleSpreadsheetList = yup.InferType<
  typeof googleSpreadsheetListSchema
>;

export type DetailedGoogleSheet = yup.InferType<
  typeof detailedGoogleSheetSchema
>;

export type DetailedGoogleSheetList = yup.InferType<
  typeof detailedGoogleSheetListSchema
>;

export type GoogleSheetFieldMappingSchema = yup.InferType<
  typeof googleSheetFieldMappingSchema
>;

export type DetailedGoogleSpreadsheet = GoogleSpreadsheet & {
  sheets: DetailedGoogleSheet[];
};

export type GoogleSheetFieldMapping = {
  columnIndex: number;
  columnName: string;
  fieldName: string;
};

export const GoogleSheetFieldMappingMapper = {
  fromStorage: (
    data: GoogleSheetFieldMappingSchema
  ): GoogleSheetFieldMapping => {
    return {
      columnIndex: data.column_index,
      columnName: data.column_name,
      fieldName: data.field_name,
    };
  },
};

export const workspaceGoogleSheetExportConfigSchema = yup.object({
  mappings: yup.array().of(googleSheetFieldMappingSchema.defined()).defined(),
});

export type WorkspaceGoogleSheetExportConfigSchema = yup.InferType<
  typeof workspaceGoogleSheetExportConfigSchema
>;

export type WorkspaceGoogleSheetExportConfig = {
  mappings: GoogleSheetFieldMapping[];
};

export const workspaceGoogleSheetExportSchema = yup.object().shape({
  id: yup.string().required(),
  config: workspaceGoogleSheetExportConfigSchema.required(),
  google_sheet_id: yup.string().required(),
  google_spreadsheet_id: yup.string().required(),
  google_spreadsheet_name: yup.string().required(),
  google_sheet_name: yup.string().required(),
  oauth_credential_id: yup.string().required(),
  workspace_id: yup.string().required(),
});

export type WorkspaceGoogleSheetExportSchema = yup.InferType<
  typeof workspaceGoogleSheetExportSchema
>;

export const WorkspaceGoogleSheetExportMapper = {
  fromStorage: (
    data: WorkspaceGoogleSheetExportSchema
  ): WorkspaceGoogleSheetExport => {
    return {
      id: data.id,
      googleSheetId: data.google_sheet_id,
      googleSpreadsheetId: data.google_spreadsheet_id,
      googleSpreadsheetName: data.google_spreadsheet_name,
      googleSheetName: data.google_sheet_name,
      oauthCredentialId: data.oauth_credential_id,
      workspaceId: data.workspace_id,
      config: {
        mappings: data.config.mappings.map(
          GoogleSheetFieldMappingMapper.fromStorage
        ),
      },
    };
  },
};
export type WorkspaceGoogleSheetExport = {
  id: string;
  config: WorkspaceGoogleSheetExportConfig;
  googleSheetId: string;
  googleSheetName: string;
  googleSpreadsheetId: string;
  googleSpreadsheetName: string;
  oauthCredentialId: string;
  workspaceId: string;
};

export const workspaceGoogleSheetExportRespSchema = yup.object().shape({
  workspace_google_sheet_export: workspaceGoogleSheetExportSchema,
});

export type WorkspaceGoogleSheetExportResp = yup.InferType<
  typeof workspaceGoogleSheetExportRespSchema
>;

export const workspaceGoogleSheetExportListRespSchema = yup
  .object({
    workspace_google_sheet_exports: yup
      .array()
      .of(workspaceGoogleSheetExportSchema.required())
      .required(),
  })
  .required();

export type WorkspaceGoogleSheetExportListResp = yup.InferType<
  typeof workspaceGoogleSheetExportListRespSchema
>;

export const googleSheetCreateParamsSchema = yup.object({
  name: yup.string().required(),
  columns: yup.array().of(yup.string().required()).required(),
});

export type GoogleSheetCreateParams = yup.InferType<
  typeof googleSheetCreateParamsSchema
>;

export const googleSheetCreateRequestSchema = yup
  .object({
    oauth_credential_id: yup.string().required(),
    spreadsheet_name: yup.string().required(),
    sheets: yup.array().of(googleSheetCreateParamsSchema.required()).required(),
  })
  .camelCase();

export const googleSheetCreateResponseSchema = yup.object({
  spreadsheet_id: yup.string().required(),
  spreadsheet_name: yup.string().required(),
  sheets: yup.array().of(detailedGoogleSheetSchema.defined()).defined(),
});

export type CreatedGoogleSheet = yup.InferType<
  typeof googleSheetCreateResponseSchema
>;

export type GoogleSheetCreateRequest = yup.InferType<
  typeof googleSheetCreateRequestSchema
>;
export type GoogleSheetCreateResponse = yup.InferType<
  typeof googleSheetCreateResponseSchema
>;

export const googleSheetSchema = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
});

export type GoogleSheet = yup.InferType<typeof googleSheetSchema>;

export const googleWorksheetListRespSchema = yup.object({
  sheets: yup.array().of(googleSheetSchema.required()).required(),
});

export type GoogleSheetListResp = yup.InferType<
  typeof googleWorksheetListRespSchema
>;

export interface GoogleSheetListRequest {
  oauth_credential_id: string;
  spreadsheet_id: string;
}

export interface DeleteWorkspaceGoogleSheetExportRequest {
  workspace_id: string;
  workspace_google_sheet_export_id: string;
}
