import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import classnames from "classnames";
import cntl from "cntl";
import * as React from "react";

import { UNSUPPORTED_PREVIEW_MIME } from "../../constants/support";
import styles from "./styles.module.scss";

interface ImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  frameClass?: string;
  filename?: string;
  filetype?: string;
}

export enum LoadingState {
  Initial,
  Loading,
  Loaded,
  Errored,
}

export function useLoadImage(src?: string) {
  const loadingStateRef = React.useRef<LoadingState>(LoadingState.Initial);
  const [loadingState, setLoadingState] = React.useState<LoadingState>(
    loadingStateRef.current
  );

  React.useEffect(() => {
    if (loadingStateRef.current === LoadingState.Initial && src !== undefined) {
      const onLoad = () => {
        loadingStateRef.current = LoadingState.Loaded;
        setLoadingState(loadingStateRef.current);
      };

      const onError = () => {
        loadingStateRef.current = LoadingState.Errored;
        setLoadingState(loadingStateRef.current);
      };

      const image = new Image();
      image.addEventListener("load", onLoad);
      image.addEventListener("error", onError);

      image.src = src;
      if (!image.complete) {
        loadingStateRef.current = LoadingState.Loading;
        setLoadingState(loadingStateRef.current);
      }

      return () => {
        image.removeEventListener("load", onLoad);
        image.removeEventListener("error", onError);
      };
    }

    return () => {};
  }, [src]);

  return loadingState;
}

const ImgFilename: React.FC<{
  filename: string;
  className?: string;
}> = props => {
  const { filename, className } = props;
  const dotIndex = filename.lastIndexOf(".");
  const [namePart, extPart] =
    dotIndex !== -1
      ? [filename.substring(0, dotIndex), filename.substring(dotIndex + 1)]
      : [filename, ""];
  return (
    <div
      className={cntl`
      ${className}
      flex justify-center w-full
      text-body-small text-text-secondary
    `}
    >
      <span className="truncate">{namePart}</span>
      {extPart.length > 0 && <span className="flex-shrink-0">.{extPart}</span>}
    </div>
  );
};

export const Img: React.FC<ImageProps> = (props: ImageProps) => {
  const loadingState = useLoadImage(props.src);

  const { frameClass, filename, filetype, ...rest } = props;

  switch (loadingState) {
    case LoadingState.Loading:
      return (
        <div className={classnames(styles["frame"], frameClass)}>
          <div>
            <Spinner size={SpinnerSize.medium} />
          </div>
        </div>
      );
    case LoadingState.Initial:
    case LoadingState.Loaded:
      if (frameClass) {
        return (
          <div className={frameClass}>
            {<img {...rest} alt={rest.alt || ""} />}
          </div>
        );
      }
      return <img {...rest} alt={rest.alt || ""} />;
    case LoadingState.Errored:
      if (filetype != null && UNSUPPORTED_PREVIEW_MIME.includes(filetype)) {
        return (
          <div
            className={cntl`
              ${frameClass}
              flex flex-col items-center
              m-0.5 gap-2
            `}
          >
            <div className="w-[60px] h-[60px] flex justify-center items-center rounded-xs bg-[#f8f8f8]">
              <Icon iconName="Page" />
            </div>
            {filename && (
              <ImgFilename
                filename={filename}
                className="min-w-[60px] max-w-[150px]"
              />
            )}
          </div>
        );
      }
      return (
        <div
          className={classnames(styles["frame"], styles["errored"], frameClass)}
        >
          <div>
            <Icon iconName="Cancel" />
          </div>
        </div>
      );
  }
};
