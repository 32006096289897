import * as React from "react";
import { useCallback } from "react";

import { apiClient } from "../apiClient";
import type {
  DetailedGoogleSpreadsheet,
  GoogleSheet,
  GoogleSheetCreateParams,
} from "../types/googleSheet";

export function useGoogleSheet() {
  const [isFetchingWorkSheets, setIsFetchingWorkSheets] =
    React.useState<boolean>(false);

  const getWorkSheetList = useCallback(
    async (
      oauthCredentialId: string,
      spreadsheetId: string,
      sheetId: string
    ) => {
      setIsFetchingWorkSheets(true);
      await apiClient.listGoogleSheetColumns(
        oauthCredentialId,
        spreadsheetId,
        sheetId
      );
      setIsFetchingWorkSheets(false);
    },
    []
  );

  const listGoogleSpreadsheets = useCallback(
    async (oauthCredentialId: string, query: string | null) => {
      return (await apiClient.listGoogleSpreadsheets(oauthCredentialId, query))
        .spreadsheets;
    },
    []
  );

  const createNewGoogleSheet = useCallback(
    async (
      oauthCredentialId: string,
      spreadsheetName: string,
      sheets: GoogleSheetCreateParams[]
    ): Promise<DetailedGoogleSpreadsheet> => {
      try {
        const response = await apiClient.createGoogleSpreadsheet(
          oauthCredentialId,
          spreadsheetName,
          sheets
        );
        return {
          id: response.spreadsheet_id,
          name: response.spreadsheet_name,
          sheets: response.sheets.map(sheet => ({
            id: sheet.id,
            name: sheet.name,
            columns: sheet.columns,
          })),
        };
      } catch (error) {
        console.error("Failed to create Google Sheet:", error);
        throw error;
      }
    },
    []
  );

  const listGoogleSheets = useCallback(
    async (
      oauthCredentialId: string,
      spreadsheetId: string
    ): Promise<GoogleSheet[]> => {
      try {
        return (
          await apiClient.listGoogleSheets(oauthCredentialId, spreadsheetId)
        ).sheets;
      } catch (error) {
        console.error("Failed to list worksheets:", error);
        throw error;
      }
    },
    []
  );

  const listGoogleSheetColumns = useCallback(
    async (
      oauthCredentialId: string,
      spreadsheetId: string,
      sheetId?: string | null
    ) => {
      const result = await apiClient.listGoogleSheetColumns(
        oauthCredentialId,
        spreadsheetId,
        sheetId
      );
      return result.sheets.flatMap(sheet => sheet.columns);
    },
    []
  );

  return React.useMemo(
    () => ({
      isFetchingWorkSheets,
      getSheetList: getWorkSheetList,
      listGoogleSpreadsheets,
      createNewGoogleSheet,
      listGoogleSheets,
      listGoogleSheetColumns,
    }),
    [
      isFetchingWorkSheets,
      getWorkSheetList,
      listGoogleSpreadsheets,
      createNewGoogleSheet,
      listGoogleSheets,
      listGoogleSheetColumns,
    ]
  );
}
