import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useFormActionCreator } from "../../actions/form";
import { MANIPULATION_TYPES } from "../../constants";
import { buildFormSettings } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import { ManipulationType } from "../../types/autoExtractionItemConfig";

export function useDetectDocumentManipulationSettingsModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openDetectDocumentManipulationSettingsModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const form = useSelector((state: RootState) => state.form.currentForm);
  const { updateForm } = useFormActionCreator();

  const onConfirm = useCallback(
    (types: ManipulationType[]) => {
      if (!form) {
        return;
      }
      const autoExtractionItemConfig =
        form.config.auto_extraction_item_config || {};
      const settings = buildFormSettings(form, {
        autoExtractionItemConfig: {
          ...autoExtractionItemConfig,
          detect_document_manipulation: {
            ...(autoExtractionItemConfig.detect_document_manipulation || {}),
            manipulation_types: types,
          },
        },
      });
      updateForm(settings);
      onClose();
    },
    [form, onClose, updateForm]
  );

  const detectDocumentManipulationSettingsPayload = useMemo(
    () => ({
      types:
        form?.config?.auto_extraction_item_config?.detect_document_manipulation
          ?.manipulation_types || MANIPULATION_TYPES,
      isOpen,
      onClose,
      onConfirm,
    }),
    [
      form?.config?.auto_extraction_item_config?.detect_document_manipulation
        ?.manipulation_types,
      isOpen,
      onClose,
      onConfirm,
    ]
  );

  return useMemo(
    () => ({
      detectDocumentManipulationSettingsPayload,
      openDetectDocumentManipulationSettingsModal,
    }),
    [
      detectDocumentManipulationSettingsPayload,
      openDetectDocumentManipulationSettingsModal,
    ]
  );
}

interface Props {
  types: ManipulationType[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (types: ManipulationType[]) => void;
}

export function DetectDocumentManipulationSettingsModal(props: Props) {
  const { localized } = useLocale();
  const { isOpen, onClose, onConfirm, types: existingTypes } = props;
  const [manipulationTypes, setManipulationTypes] =
    React.useState(existingTypes);

  const onCheckboxChange = useCallback(
    (key: ManipulationType, value?: boolean) => {
      if (value === null) {
        return;
      }

      setManipulationTypes(prev => {
        const updatedSet = new Set(prev);
        if (value) {
          updatedSet.add(key);
        } else {
          updatedSet.delete(key);
        }
        return Array.from(updatedSet);
      });
    },
    [setManipulationTypes]
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("manipulation_type.settings.modal.title"),
    }),
    [localized]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <Dialog
      minWidth={405}
      hidden={!isOpen}
      onDismiss={onClose}
      dialogContentProps={dialogContentProps}
    >
      {MANIPULATION_TYPES.map(key => (
        <Checkbox
          className="mt-4"
          defaultChecked={existingTypes.includes(key)}
          label={localized(`manipulation_type.${key}`)}
          onChange={(_e, value) => onCheckboxChange(key, value)}
          disabled={!hasPermissionToEditResource}
          key={key}
        />
      ))}

      <DialogFooter>
        <DefaultButton
          onClick={onClose}
          text={localized(
            hasPermissionToEditResource ? "common.cancel" : "common.close"
          )}
        />
        {hasPermissionToEditResource && (
          <PrimaryButton
            onClick={() => {
              onConfirm(manipulationTypes);
            }}
            text={localized("common.ok")}
          />
        )}
      </DialogFooter>
    </Dialog>
  );
}
