import { Dropdown, IDropdownOption, Icon } from "@fluentui/react";
import { TextField as MSTextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import * as React from "react";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useLocale } from "../../contexts/locale";
import { ExtractedContentSchemaType } from "../../types/extractedContentSchema";
import { SmallIconButton } from "../SmallIconButton";
import styles from "./styles.module.scss";

const DRAG_ITEM_TYPE = "fsl-field-table-type";

const MAX_COLUMN_COUNT = 3;

type SubFieldRowProps = {
  name: string;
  type: string;
  description?: string;
  index: number;
  hasEmptyFieldError: boolean;
  hasDuplicatedFieldError: boolean;
  hasInvalidFormatFieldError: boolean;
};

export function useSubFieldRowState(props: SubFieldRowProps) {
  const {
    index,
    hasDuplicatedFieldError,
    hasEmptyFieldError,
    hasInvalidFormatFieldError,
    name,
    type,
    description,
  } = props;
  const { localized } = useLocale();
  const {
    setSelectedEditingSchemaSubField,
    removeEditingSchemaSubField,
    moveEditingSchemaSubField,
  } = useFSLCustomModelEditor();

  const nameError = React.useMemo(() => {
    if (hasEmptyFieldError) {
      return localized(
        "fsl_custom_model.field_schema_editor.error.empty_field_name_error"
      );
    } else if (hasInvalidFormatFieldError) {
      return localized(
        "fsl_custom_model.field_schema_editor.error.invalid_field_name_error"
      );
    } else if (hasDuplicatedFieldError) {
      return localized(
        "fsl_custom_model.field_schema_editor.error.duplicated_field_name_error"
      );
    } else {
      return undefined;
    }
  }, [
    hasEmptyFieldError,
    hasDuplicatedFieldError,
    hasInvalidFormatFieldError,
    localized,
  ]);

  const placeholder =
    index < MAX_COLUMN_COUNT
      ? localized(
          `fsl_custom_model.field_schema_editor.field_edit_panel.subfield.placeholder_${
            index + 1
          }`
        )
      : "";

  const options = React.useMemo(() => {
    return Object.entries(ExtractedContentSchemaType)
      .filter(
        item =>
          ![
            ExtractedContentSchemaType.FieldGroup,
            ExtractedContentSchemaType.Checkbox,
          ].includes(item[1])
      )
      .map(([key, value]) => {
        return {
          key: value,
          text: localized(
            `fsl_custom_model.field_type.${
              ExtractedContentSchemaType[
                key as keyof typeof ExtractedContentSchemaType
              ]
            }`
          ),
        };
      }) as IDropdownOption[];
  }, [localized]);

  const onNameChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue: string | undefined
    ) => {
      event.stopPropagation();
      event.preventDefault();

      if (newValue !== undefined) {
        setSelectedEditingSchemaSubField(index, {
          name: newValue,
        });
      }
    },
    [setSelectedEditingSchemaSubField, index]
  );

  const onTypeChange = React.useCallback(
    (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setSelectedEditingSchemaSubField(index, {
          type: option.key as ExtractedContentSchemaType,
        });
      }
    },
    [setSelectedEditingSchemaSubField, index]
  );

  const onDescriptionChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue: string | undefined
    ) => {
      event.stopPropagation();
      event.preventDefault();

      if (newValue !== undefined) {
        setSelectedEditingSchemaSubField(index, {
          description: newValue,
        });
      }
    },
    [setSelectedEditingSchemaSubField, index]
  );

  const onTrashClick = React.useCallback(() => {
    removeEditingSchemaSubField(index);
  }, [removeEditingSchemaSubField, index]);

  const [{ isDragging }, dragRef, dragPreviewRef] = useDrag({
    type: DRAG_ITEM_TYPE,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isDropOver }, dropRef] = useDrop({
    accept: DRAG_ITEM_TYPE,
    collect: monitor => ({
      isDropOver: monitor.isOver(),
    }),
    drop: (item: { index: number }) => {
      moveEditingSchemaSubField(item.index, index);
    },
  });

  const indicatorVisible = isDropOver;

  return React.useMemo(
    () => ({
      onTrashClick,
      onDescriptionChange,
      onTypeChange,
      options,
      onNameChange,
      nameError,
      placeholder,
      name,
      type,
      description,
      indicatorVisible,
      dragRef,
      dragPreviewRef,
      isDragging,
      dropRef,
    }),
    [
      onTrashClick,
      onDescriptionChange,
      onTypeChange,
      options,
      onNameChange,
      nameError,
      placeholder,
      name,
      type,
      description,
      indicatorVisible,
      dragRef,
      dragPreviewRef,
      isDragging,
      dropRef,
    ]
  );
}

export function Indicator(props: { className?: string }) {
  return (
    <div
      className={cntl`h-[2px] w-full bg-primary-500 ${props.className}`}
    ></div>
  );
}

export function SubFieldRow(props: SubFieldRowProps) {
  return <SubFieldRowImpl {...useSubFieldRowState(props)} />;
}

export function SubFieldRowImpl(props: ReturnType<typeof useSubFieldRowState>) {
  const {
    onTrashClick,
    onDescriptionChange,
    onTypeChange,
    options,
    onNameChange,
    nameError,
    placeholder,
    name,
    type,
    description,
    indicatorVisible,
    dragRef,
    dragPreviewRef,
    dropRef,
  } = props;

  const { localized } = useLocale();

  return (
    <div
      className={cntl`grid col-span-6 row-span-3 grid-cols-subgrid grid-rows-subgrid`}
      ref={el => {
        dropRef(el);
        dragPreviewRef(el);
      }}
    >
      <div className="col-span-6 h-[6px] relative">
        {indicatorVisible && <Indicator className="mt-[1px]" />}
      </div>
      <div className="col-span-6 min-h-[32px] grid grid-cols-subgrid">
        <div
          className={cntl`flex justify-center items-center
            hover:*:bg-gray-200
            cursor-grab
            select-none
            h-[32px]
            `}
          ref={dragRef}
        >
          <Icon className="py-[2px]" iconName="GripperDotsVertical" />
        </div>
        <MSTextField
          value={name}
          placeholder={placeholder}
          onChange={onNameChange}
          errorMessage={nameError}
        />
        <Dropdown
          selectedKey={type}
          options={options}
          onChange={onTypeChange}
        />

        <MSTextField
          value={description ?? ""}
          placeholder={localized(
            "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.description_placeholder"
          )}
          onChange={onDescriptionChange}
        />

        <div>
          <SmallIconButton iconName="Trash" onClick={onTrashClick} />
        </div>
      </div>
      <div className="col-span-6 h-[6px] relative"></div>
    </div>
  );
}

export function Footer() {
  const { moveToLastEditingSchemaSubField } = useFSLCustomModelEditor();

  const [{ isDropOver }, dropRef] = useDrop({
    accept: DRAG_ITEM_TYPE,
    collect: monitor => ({
      isDropOver: monitor.isOver(),
    }),
    drop: (item: { index: number }) => {
      moveToLastEditingSchemaSubField(item.index);
    },
  });

  return (
    <div
      className="grid col-span-6 grid-cols-subgrid grid-rows-subgrid"
      ref={dropRef}
    >
      <div className="h-[32px] col-span-6">
        <div className="col-span-6 h-[6px] relative">
          {isDropOver && <Indicator className="mt-[1px]" />}
        </div>
      </div>
    </div>
  );
}

export function GridView(props: { children: React.ReactNode }) {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles["field-item-edit-panel-subfield-grid"]}>
        <div className="grid col-span-6 grid-cols-subgrid min-h-[32px] pb-[8px]">
          {/* Empty block for drag handle */}
          <div></div>

          <div className={styles["field-item-edit-panel-subfield-grid-header"]}>
            <FormattedMessage id="fsl_custom_model.field_schema_editor.field_edit_panel.subfield.name" />
          </div>
          <div className={styles["field-item-edit-panel-subfield-grid-header"]}>
            <FormattedMessage id="fsl_custom_model.field_schema_editor.field_edit_panel.subfield.type" />
          </div>
          <div className={styles["field-item-edit-panel-subfield-grid-header"]}>
            <FormattedMessage id="fsl_custom_model.field_schema_editor.field_edit_panel.subfield.description" />
          </div>
          <div></div>
        </div>
        {props.children}
        <Footer />
      </div>
    </DndProvider>
  );
}
