import {
  GoogleSheetFieldMapping,
  WorkspaceGoogleSheetExport,
  type WorkspaceGoogleSheetExportConfig,
  WorkspaceGoogleSheetExportMapper,
  WorkspaceGoogleSheetExportSchema,
  workspaceGoogleSheetExportListRespSchema,
} from "../../types/googleSheet";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface WorksapceGoogleSheetExportApiClient {
  createWorkspaceGoogleSheetExport: (
    workspaceId: string,
    oauthCredentialId: string,
    googleSpreadsheetId: string,
    googleSpreadsheetName: string,
    googleSheetId: string,
    googleSheetName: string,
    config: WorkspaceGoogleSheetExportConfig
  ) => Promise<WorkspaceGoogleSheetExport>;

  listWorkspaceGoogleSheetExports: (
    workspaceId: string
  ) => Promise<WorkspaceGoogleSheetExport[]>;

  deleteWorkspaceGoogleSheetExport: (
    workspaceGoogleSheetExportId: string
  ) => Promise<void>;

  updateWorkspaceGoogleSheetExport: (
    workspaceGoogleSheetExportId: string,
    mappings: GoogleSheetFieldMapping[]
  ) => Promise<WorkspaceGoogleSheetExportSchema>;
}

export function withWorksapceGoogleSheetExportApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async createWorkspaceGoogleSheetExport(
      workspaceId: string,
      oauthCredentialId: string,
      googleSpreadsheetId: string,
      googleSpreadsheetName: string | null,
      googleSheetId: string | null,
      googleSheetName: string | null,
      config: WorkspaceGoogleSheetExportConfig
    ): Promise<WorkspaceGoogleSheetExportSchema> {
      return this.lambda("workspace_google_sheet_export:create", {
        config: {
          mappings: config.mappings.map(mapping => ({
            column_index: mapping.columnIndex,
            column_name: mapping.columnName,
            field_name: mapping.fieldName,
          })),
        },
        google_sheet_id: googleSheetId,
        google_spreadsheet_id: googleSpreadsheetId,
        google_spreadsheet_name: googleSpreadsheetName,
        google_sheet_name: googleSheetName,
        oauth_credential_id: oauthCredentialId,
        workspace_id: workspaceId,
      });
    }

    async listWorkspaceGoogleSheetExports(
      workspaceId: string
    ): Promise<WorkspaceGoogleSheetExport[]> {
      const result = await this.lambda(
        "workspace_google_sheet_export:list",
        {
          workspace_id: workspaceId,
        },
        workspaceGoogleSheetExportListRespSchema,
        null
      );
      return result.workspace_google_sheet_exports.map(item => {
        return WorkspaceGoogleSheetExportMapper.fromStorage(item);
      });
    }

    async deleteWorkspaceGoogleSheetExport(
      workspaceGoogleSheetExportId: string
    ): Promise<void> {
      await this.lambda("workspace_google_sheet_export:delete", {
        workspace_google_sheet_export_id: workspaceGoogleSheetExportId,
      });
    }

    async updateWorkspaceGoogleSheetExport(
      workspaceGoogleSheetExportId: string,
      mappings: GoogleSheetFieldMapping[]
    ): Promise<WorkspaceGoogleSheetExportSchema> {
      return this.lambda("workspace_google_sheet_export:update", {
        workspace_google_sheet_export_id: workspaceGoogleSheetExportId,
        mappings: mappings.map(mapping => ({
          column_name: mapping.columnName,
          column_index: mapping.columnIndex,
          field_name: mapping.fieldName,
        })),
      });
    }
  };
}
