import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { useFormActionCreator } from "../actions/form";
import { AppConfig } from "../config";
import { FOCRError } from "../errors";
import {
  ExtractAPIV2Response,
  ExtractAPIV2ResponseAccessor,
  OCRTestReport,
  accessExtractAPIV2Response,
} from "../models";
import { PdfService } from "../services/pdfservice";
import { UploadFileKeeperService } from "../services/uploadFileKeeper";
import { FormExtractionMode } from "../types/form";
import { readFileAsDataUri } from "../utils/file";
import { imageURLToFile } from "../utils/image";
import { sleep } from "../utils/sleep";
import { ExtractV2Options } from "../workerClient";
import { useWorkerToken } from "./app";
import { useWaitUntil } from "./common";
import { URLParamsKey, useSearchParamUtils } from "./searchParamUtils";
import { useToast } from "./toast";
import { useCreateOrigin } from "./tracking";

export async function readFileAsImageDataUris(file: File): Promise<string[]> {
  const pages = await PdfService.getInstance().renderPDFAsDataUris(file);

  if (pages.length === 0) {
    return [await readFileAsDataUri(file)];
  }

  return pages;
}

export function useExtractTest(entityId?: string) {
  const toast = useToast();
  const { token } = useWorkerToken();
  const { extractFieldInTestMode } = useFormActionCreator();
  const [isRecognizing, setIsRecognizing] = React.useState<boolean>(false);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [testReport, setTestReport] = React.useState<OCRTestReport | undefined>(
    undefined
  );
  const [
    shouldShowFormExtractionModeSelector,
    setShouldShowFormExtractionModeSelector,
  ] = React.useState<boolean>(false);

  const [extractionMode, setExtractionMode] =
    React.useState<FormExtractionMode>(FormExtractionMode.multiPagePdf);

  const onExtractionModeChanged = React.useCallback(
    (value: FormExtractionMode) => {
      if (value !== extractionMode) {
        setExtractionMode(value);
        setTestReport(undefined);
      }
    },
    [setExtractionMode, setTestReport, extractionMode]
  );

  const { shouldAsyncDisabled } = AppConfig.worker;
  const extractWithFile = React.useCallback(
    async (file?: File) => {
      if (!token) {
        toast.error("error.no_access_token");
        return;
      }

      if (!file || !entityId) return;

      if (shouldAsyncDisabled) {
        setIsRecognizing(true);
      } else {
        setIsUploading(true);
      }

      try {
        const response = await extractFieldInTestMode(
          token,
          entityId,
          file,
          extractionMode,
          () => {
            if (!shouldAsyncDisabled) {
              setIsUploading(false);
              setIsRecognizing(true);
            }
          }
        );

        setIsRecognizing(false);
        setTestReport(response);
      } catch (error) {
        setIsUploading(false);
        setIsRecognizing(false);
        if (error instanceof FOCRError) {
          toast.error(error.messageId, undefined, error.detail);
        } else {
          toast.error("error.cannot_load_image");
        }
      }
    },
    [
      token,
      entityId,
      shouldAsyncDisabled,
      extractionMode,
      toast,
      extractFieldInTestMode,
    ]
  );

  const extractWithURL = React.useCallback(
    (url: string) => {
      imageURLToFile(url)
        .then(extractWithFile)
        .catch(() => {
          toast.error("error.cannot_load_image");
        });
    },
    [extractWithFile, toast]
  );

  React.useEffect(() => {
    setTestReport(undefined);
  }, [entityId]);

  return {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    testReport,
    setTestReport,
    extractionMode,
    onExtractionModeChanged,
    shouldShowFormExtractionModeSelector,
    setShouldShowFormExtractionModeSelector,
  };
}

export interface UseExtractTestV2Params {
  extractorId: string | undefined;
  options?: ExtractV2Options;
  hasTransformResponseScript: boolean;
  multipleDocumentsPerPage: boolean;
}

export interface ExtractImageSource {
  uris: string[];
  mime?: string;
}

export function useExtractTestV2(params: UseExtractTestV2Params) {
  const toast = useToast();
  const { token } = useWorkerToken();
  const { extractFieldV2 } = useFormActionCreator();
  const [isRecognizing, setIsRecognizing] = React.useState<boolean>(false);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const [extractResult, _setExtractResult] = React.useState<
    ExtractAPIV2Response | undefined
  >(undefined);
  const [extractImageSource, setExtractImageSource] =
    React.useState<ExtractImageSource>({
      uris: [],
    });
  const [selectedResultIndex, setSelectedResultIndex] = React.useState(0);

  const paramsRef = React.useRef(params);
  paramsRef.current = params;

  const createOrigin = useCreateOrigin();

  const waitUntilReady = useWaitUntil(
    token != null && params.extractorId != null
  );

  const setExtractResult = React.useCallback(
    (result: ExtractAPIV2Response | undefined) => {
      _setExtractResult(result);
      if (!result) {
        setExtractImageSource({
          uris: [],
        });
      }
    },
    [_setExtractResult]
  );

  const getUseAsync = React.useCallback(async (file: File) => {
    const { shouldAsyncDisabled } = AppConfig.worker;
    const { docPagesThresholdToEnableAsync, expectedDocCountPerPage } =
      AppConfig.testLab;

    const pdfInfo = await PdfService.getInstance().getPDFInfo(file);
    const numberOfPages = pdfInfo.isPdf ? pdfInfo.numPages : 1;

    const _expectedDocCountPerPage = paramsRef.current.multipleDocumentsPerPage
      ? expectedDocCountPerPage
      : 1;

    return (
      _expectedDocCountPerPage * numberOfPages >
        docPagesThresholdToEnableAsync && !shouldAsyncDisabled
    );
  }, []);

  const lastUploadedFileRef = React.useRef<File | null>(null);

  const extractWithFile = React.useCallback(
    async (file?: File) => {
      if (!token) {
        toast.error("error.no_access_token");
        return;
      }

      if (!file || !paramsRef.current.extractorId) return;

      const useAsync = await getUseAsync(file);

      if (!useAsync) {
        setIsRecognizing(true);
      } else {
        setIsUploading(true);
      }

      const origin = await createOrigin("formx.portal.testtab");

      const options = {
        origin,
        ...paramsRef.current.options,
      };

      try {
        const response = await extractFieldV2(
          token,
          paramsRef.current.extractorId,
          file,
          options,
          () => {
            if (useAsync) {
              setIsUploading(false);
              setIsRecognizing(true);
            }
          },
          useAsync,
          !!paramsRef.current.hasTransformResponseScript
        );

        const images = await readFileAsImageDataUris(file);
        const responseAccessor = accessExtractAPIV2Response(response);
        setExtractImageSource({
          uris: images,
          mime: file.type,
        });
        _setExtractResult(responseAccessor.data);

        setIsRecognizing(false);

        lastUploadedFileRef.current = file;
      } catch (error) {
        setIsUploading(false);
        setIsRecognizing(false);
        if (error instanceof FOCRError) {
          toast.error(error.messageId, undefined, error.detail);
        } else {
          toast.error("error.cannot_load_image");
        }
      }
    },
    [token, toast, extractFieldV2, createOrigin, getUseAsync]
  );

  const extractWithFileRef = React.useRef(extractWithFile);
  extractWithFileRef.current = extractWithFile;

  const extractWithURL = React.useCallback(
    (url: string) => {
      imageURLToFile(url)
        .then(extractWithFile)
        .catch(() => {
          toast.error("error.cannot_load_image");
        });
    },
    [extractWithFile, toast]
  );

  const { getParam } = useSearchParamUtils();

  const extractIfFileKeySet = React.useCallback(async () => {
    const fileKey = getParam(URLParamsKey.file);
    if (!fileKey) {
      return;
    }

    await waitUntilReady();
    await sleep(0); // Make sure the extractWithFileRef updated

    const file = UploadFileKeeperService.getInstance().getFile(fileKey);
    if (!file) {
      return;
    }
    UploadFileKeeperService.getInstance().deleteFile(fileKey);
    await extractWithFileRef.current(file);
  }, [getParam, waitUntilReady]);

  const extractIfPreviewResultKeySet = React.useCallback(
    async (options?: { extractorId?: string; extractorType?: string }) => {
      const previewResultKey = getParam(URLParamsKey.previewResult);
      if (!previewResultKey) {
        return;
      }

      await waitUntilReady();
      try {
        const { extractedContentSchema, extractionResult } =
          UploadFileKeeperService.getInstance().getMetadata(previewResultKey);
        let response = accessExtractAPIV2Response(
          ExtractAPIV2ResponseAccessor.createFromExtractedContentSchema(
            extractedContentSchema,
            extractionResult
          )
        ).setRequestId(uuidv4()).data;
        if (options?.extractorId != null) {
          response = accessExtractAPIV2Response(response).setExtractorId(
            options.extractorId
          ).data;
        }
        if (options?.extractorType != null) {
          response = accessExtractAPIV2Response(response).setExtractorType(
            options.extractorType
          ).data;
        }
        const file =
          UploadFileKeeperService.getInstance().getFile(previewResultKey);
        if (!file) {
          return;
        }
        UploadFileKeeperService.getInstance().deleteFile(previewResultKey);

        const images = await readFileAsImageDataUris(file);
        // Only pick the first page
        if (images.length > 0) {
          setExtractImageSource({
            uris: [images[0]],
            mime: file.type,
          });
        } else {
          setExtractImageSource({
            uris: [],
          });
        }

        _setExtractResult(response);
      } catch (e) {
        return;
      }
    },
    [getParam, waitUntilReady]
  );

  const extractIfQueryKeySet = React.useCallback(
    async (options?: { extractorId?: string; extractorType?: string }) => {
      const previewResultKey = getParam(URLParamsKey.previewResult);
      if (previewResultKey != null) {
        return extractIfPreviewResultKeySet(options);
      }
      const fileKey = getParam(URLParamsKey.file);
      if (fileKey != null) {
        return extractIfFileKeySet();
      }
    },
    [extractIfFileKeySet, extractIfPreviewResultKeySet, getParam]
  );

  return React.useMemo(
    () => ({
      extractWithFile,
      extractWithURL,
      isRecognizing,
      isUploading,
      extractResult,
      extractImageSource,
      setExtractResult,
      extractIfFileKeySet,
      extractIfPreviewResultKeySet,
      extractIfQueryKeySet,
      lastUploadedFileRef,
      selectedResultIndex,
      setSelectedResultIndex,
    }),
    [
      extractWithFile,
      isRecognizing,
      isUploading,
      extractResult,
      extractImageSource,
      setExtractResult,
      extractWithURL,
      extractIfFileKeySet,
      extractIfPreviewResultKeySet,
      extractIfQueryKeySet,
      lastUploadedFileRef,
      selectedResultIndex,
      setSelectedResultIndex,
    ]
  );
}
