import { PrebuiltExtractor } from "../types/extractor";
import { OnboardingAvailblePrebuiltExtractorType } from "../types/onboarding";

export type GtmExtractorDocumentType =
  | PrebuiltExtractor
  | "fixed_layout"
  | "custom_model"
  | "combine"
  | "others";

export enum GtmEventType {
  Identified = "formx.lifecycle.identified",
  PageView = "formx.event.pageview",
  ClickedExtractor = "formx.event.formx-clicked-extractor",
  ClickedExtractorTab = "formx.event.formx-clicked-extractor_tab",
  ClickedDetectDocumentTab = "formx.event.formx-clicked-detect_document_tab",
  ClickedDetectPIITab = "formx.event.formx-clicked-detect_pii_tab",
  ClickedSaveExtractor = "formx.event.formx-clicked-save_extractor",
  ClickedCreateNewExtractor = "formx.event.formx-clicked-create_new_extractor",
  CreatedTeam = "formx.event.formx-clicked-create_team", // The original formx-created-team will be recorded by the backend
  CreatedExtractor = "formx.event.formx-clicked-create_extractor", // The original formx-created-extractor will be recorded by the backend
  InvitedMember = "formx.event.formx-invited-member",
  SubscribedPlan = "formx.event.formx-subscribed-plan",
  SwitchedTeam = "formx.event.formx-switched-team",
  TestedExtraction = "formx.event.formx-tested-extraction",
  ViewedErrorMessage = "formx.event.formx-viewed-error_message",
  CustomSavedSchema = "formx.event.custom-saved-schema",
  CustomFslUploadedSample = "formx.event.custom-uploaded-fsl_sample",
  CustomFslReviewedSample = "formx.event.custom-reviewed-fsl_sample",
  CustomMslEnabled = "formx.event.custom-enabled-msl",
  CustomMslUploadedSample = "formx.event.custom-uploaded-msl_sample",
  CustomClickedMslStartLabelling = "formx.event.custom-clicked-msl_start_labelling",
  CustomMslRequestedTraining = "formx.event.custom-requested-msl_training",
  CustomMslStartedTraining = "formx.event.custom-started-msl_training",
  CustomSwitchedModel = "formx.event.custom-switched-model",

  ClickedContactUs = "formx.event.formx-clicked-contact_us",
  ClickedSupport = "formx.event.formx-clicked-support",
  ClickedImportExtractor = "formx.event.formx-clicked-import_extractor",

  // Billing Events
  ClickedUpgradeToPayAsYouGo = "formx.event.formx-clicked-upgrade-to-pay-as-you-go",
  ClickedUpgradeToStarter = "formx.event.formx-clicked-upgrade-to-starter",

  // Survey Events
  SurveyClickedBack = "formx.event.survey-clicked-back",
  SurveySetName = "formx.event.survey-set-name",
  SurveySetCompany = "formx.event.survey-set-company",
  SurveySetUseCases = "formx.event.survey-set-use_cases",
  SurveySetPhone = "formx.event.survey-set-phone",
  SurveyCompletedSurvey = "formx.event.survey-completed-survey",

  // Onboarding Events
  OnboardingAddSample = "formx.event.onboarding-add-sample",
  OnboardingExtract = "formx.event.onboarding-extract",
  OnboardingExtractFirstLink = "formx.event.onboarding-extract-first-link",
  OnboardingSkipExtract = "formx.event.onboarding-skip-extract",
  OnboardingSkipFirstLink = "formx.event.onboarding-skip-first-link",
  OnboardingCreateCustomModel = "formx.event.onboarding-create-custom-model",
  OnboardingCreateCustomModelFirstLink = "formx.event.onboarding-create-custom-model-first-link",
  OnboardingFtgExit = "formx.event.onboarding-ftg-exit",
  OnboardingFtgFirstDialogShowMe = "formx.event.onboarding-ftg-first-dialog-show-me",
  OnboardingFtgManageRulesTooltipViewed = "formx.event.onboarding-ftg-manage-rules-tooltip-viewed",
  OnboardingFtgZoomInOutTooltipViewed = "formx.event.onboarding-ftg-zoominout-tooltip-viewed",
  OnboardingFtgReuploadTooltipViewed = "formx.event.onboarding-ftg-reupload-tooltip-viewed",
  OnboardingFtgBookDemoTooltipViewed = "formx.event.onboarding-ftg-book-demo-tooltip-viewed",

  TestTabReupload = "formx.event.formx-test-tab-reupload",
  ClickedManagedRules = "formx.event.formx-click-managed-rules",
}

export type GtmEventIdentified = {
  event: GtmEventType.Identified;
  event_data: {
    user_id: string;
    distinct_id: string;
    user_email: string;
  };
};

export type GtmEventPageView = {
  event: GtmEventType.PageView;
  event_data: {
    team_id: string;
    plan: string;
  };
};

export type GtmClickedExtractor = {
  event: GtmEventType.ClickedExtractor;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
  };
};

export type GtmClickedExtractorTab = {
  event: GtmEventType.ClickedExtractorTab;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
    tab_name: string;
  };
};

export type GtmClickedDetectDocumentTab = {
  event: GtmEventType.ClickedDetectDocumentTab;
  event_data: {
    team_id: string;
    plan: string;
    tab_name: string;
  };
};

export type GtmClickedDetectPIITab = {
  event: GtmEventType.ClickedDetectPIITab;
  event_data: {
    team_id: string;
    plan: string;
    tab_name: string;
  };
};

export type GtmClickedSaveExtractor = {
  event: GtmEventType.ClickedSaveExtractor;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
  };
};

export type GtmClickedCreateNewExtractor = {
  event: GtmEventType.ClickedCreateNewExtractor;
  event_data: {
    team_id: string;
    plan: string;
  };
};

export type GtmClickedCustomMslStartLabelling = {
  event: GtmEventType.CustomClickedMslStartLabelling;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
  };
};

export type GtmCreatedTeam = {
  event: GtmEventType.CreatedTeam;
  event_data: {
    new_team_id: string;
    new_team_region: string;
    new_team_name: string;
  };
};

export type ExtractorType = "prebuilt" | "custom" | "zonal" | "combined";

export type GtmCreatedExtractor = {
  event: GtmEventType.CreatedExtractor;
  event_data: {
    team_id: string;
    plan: string;
    type: ExtractorType;
    doc_type?: GtmExtractorDocumentType;
    extractor_name: string;
    extractor_id: string;
  };
};

export type GtmInvitedMember = {
  event: GtmEventType.InvitedMember;
  event_data: {
    team_id: string;
    plan: string;
    invited_email: string;
    invited_role: string;
  };
};

export type GtmSubscribedPlan = {
  event: GtmEventType.SubscribedPlan;
  event_data: {
    team_id: string;
    plan: string;
    new_plan_id: string;
    new_plan_name: string;
  };
};

export type GtmSwitchedTeam = {
  event: GtmEventType.SwitchedTeam;
  event_data: {
    team_id: string;
    to_team_id: string;
    plan: string;
  };
};

export type TestedExtractionStatus =
  | "success"
  | "failure"
  | "partial-success"
  | "transform-success";

export type GtmTestedExtraction = {
  event: GtmEventType.TestedExtraction;
  event_data: {
    team_id: string;
    plan: string;
    status: TestedExtractionStatus;
    extractor_id: string;
  };
};

export type GtmViewedErrorMessage = {
  event: GtmEventType.ViewedErrorMessage;
  event_data: {
    team_id: string;
    plan: string;
    error_message?: string;
  };
};

export type GtmCustomSavedSchema = {
  event: GtmEventType.CustomSavedSchema;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
    document_type: string;
    custom_schema?: string;
  };
};

// TODO: current_number_of_fsl_samples shouldn't be optional, to be fixed by #2924
export type GtmCustomFslUploadedSample = {
  event: GtmEventType.CustomFslUploadedSample;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
    current_number_of_fsl_samples?: number;
  };
};

// TODO: current_number_of_fsl_samples shouldn't be optional, to be fixed by #2924
export type GtmCustomMslUploadedSample = {
  event: GtmEventType.CustomMslUploadedSample;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
    current_number_of_msl_samples?: number;
  };
};

export type GtmCustomFslReviewedSample = {
  event: GtmEventType.CustomFslReviewedSample;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
    corrected_data: boolean;
  };
};

export type GtmCustomMslEnabled = {
  event: GtmEventType.CustomMslEnabled;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
  };
};

export type GtmCustomMslRequestedTraining = {
  event: GtmEventType.CustomMslRequestedTraining;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
  };
};

export type GtmCustomMslStartedTraining = {
  event: GtmEventType.CustomMslStartedTraining;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
  };
};

export type GtmCustomModelType = "fsl" | "msl";

export type GtmCustomSwitchedModel = {
  event: GtmEventType.CustomSwitchedModel;
  event_data: {
    team_id: string;
    plan: string;
    extractor_id: string;
    model_type: GtmCustomModelType;
    model_id?: string;
  };
};

export type GtmClickedContactUs = {
  event: GtmEventType.ClickedContactUs;
  event_data: {
    source:
      | "contact_us_nav_bar"
      | "book_a_demo"
      | "plan_billing_pay_as_you_go"
      | "plan_billing_starter"
      | "plan_billing_custom_pricing";
    current_doc_type: GtmExtractorDocumentType;
    team_id: string;
  };
};

export type GtmClickedSupport = {
  event: GtmEventType.ClickedSupport;
  event_data: {
    source: "plan_billing_pay_as_you_go" | "plan_billing_starter";
    team_id: string;
  };
};

export type GtmClickedImportExtractor = {
  event: GtmEventType.ClickedImportExtractor;
  event_data: {
    team_id: string;
  };
};

export type GtmClickedUpgradeToPayAsYouGo = {
  event: GtmEventType.ClickedUpgradeToPayAsYouGo;
  event_data: {
    team_id: string;
    current_plan: string;
  };
};

export type GtmClickedUpgradeToStarter = {
  event: GtmEventType.ClickedUpgradeToStarter;
  event_data: {
    team_id: string;
    current_plan: string;
  };
};

export type GtmSurveyClickedBack = {
  event: GtmEventType.SurveyClickedBack;
  event_data: object;
};

export type GtmSurveySetName = {
  event: GtmEventType.SurveySetName;
  event_data: {
    $set: {
      survey_name: string;
    };
  };
};

export type GtmSurveySetCompany = {
  event: GtmEventType.SurveySetCompany;
  event_data: {
    $set: {
      survey_company: string;
    };
  };
};

export type GtmSurveySetUseCase = {
  event: GtmEventType.SurveySetUseCases;
  event_data: {
    $set: {
      survey_use_cases: string[];
    };
  };
};

export type GtmSurveySetPhone = {
  event: GtmEventType.SurveySetPhone;
  event_data: {
    $set: {
      survey_phone: string;
    };
  };
};

export type GtmSurveyCompletedSurvey = {
  event: GtmEventType.SurveyCompletedSurvey;
  event_data: {
    $set: {
      survey_name: string;
      survey_company: string;
      survey_use_cases: string[];
      survey_phone: string;
    };
  };
};

export type GtmOnboardingAddSample = {
  event: GtmEventType.OnboardingAddSample;
  event_data: {
    source: "self-upload" | "formx-sample";
    team_id: string;
  };
};

export type GtmOnboardingExtract = {
  event: GtmEventType.OnboardingExtract;
  event_data: {
    team_id: string;
    doc_type: OnboardingAvailblePrebuiltExtractorType;
  };
};

export type GtmOnboardingExtractFirstLink = {
  event: GtmEventType.OnboardingExtractFirstLink;
  event_data: {
    first_page_url: string;
    extractor_id: string;
    team_id: string;
    doc_type: OnboardingAvailblePrebuiltExtractorType;
  };
};

export type GtmOnboardingSkipExtract = {
  event: GtmEventType.OnboardingSkipExtract;
  event_data: {
    team_id: string;
    doc_type: OnboardingAvailblePrebuiltExtractorType | null;
  };
};

export type GtmOnboardingSkipFirstLink = {
  event: GtmEventType.OnboardingSkipFirstLink;
  event_data: {
    first_page_url: string;
    team_id: string;
  };
};

export type GtmOnboardingCreateCustomModel = {
  event: GtmEventType.OnboardingCreateCustomModel;
  event_data: {
    team_id: string;
    doc_type: "custom_model";
  };
};

export type GtmOnboardingCreateCustomModelFirstLink = {
  event: GtmEventType.OnboardingCreateCustomModelFirstLink;
  event_data: {
    first_page_url: string;
    extractor_id: string;
    team_id: string;
    doc_type: "others";
  };
};

export type ExitTooltip =
  | "first_dialog"
  | "manage_rules"
  | "zoom"
  | "reupload"
  | "book_demo";

export type GtmOnboardingFtgExit = {
  event: GtmEventType.OnboardingFtgExit;
  event_data: {
    exit_tooltip: ExitTooltip;
    doc_type: GtmExtractorDocumentType;
    team_id: string;
  };
};

export type GtmOnboardingFtgFirstDialogShowMe = {
  event: GtmEventType.OnboardingFtgFirstDialogShowMe;
  event_data: {
    team_id: string;
    doc_type: GtmExtractorDocumentType;
  };
};

export type GtmOnboardingFtgTooltipViewed = {
  event:
    | GtmEventType.OnboardingFtgManageRulesTooltipViewed
    | GtmEventType.OnboardingFtgZoomInOutTooltipViewed
    | GtmEventType.OnboardingFtgReuploadTooltipViewed
    | GtmEventType.OnboardingFtgBookDemoTooltipViewed;
  event_data: {
    team_id: string;
    doc_type: GtmExtractorDocumentType;
  };
};
export type GtmTestTabReupload = {
  event: GtmEventType.TestTabReupload;
  event_data: {
    team_id: string;
    doc_type: GtmExtractorDocumentType;
    is_first_session: boolean;
  };
};

export type GtmClickedManagedRules = {
  event: GtmEventType.ClickedManagedRules;
  event_data: {
    team_id: string;
    is_first_session: boolean;
    is_manage_rules_tooltip_present: boolean;
    doc_type: GtmExtractorDocumentType;
  };
};

export type GtmEvent =
  | GtmEventIdentified
  | GtmEventPageView
  | GtmClickedExtractor
  | GtmClickedExtractorTab
  | GtmClickedDetectDocumentTab
  | GtmClickedDetectPIITab
  | GtmClickedSaveExtractor
  | GtmClickedCreateNewExtractor
  | GtmClickedCustomMslStartLabelling
  | GtmCreatedTeam
  | GtmCreatedExtractor
  | GtmInvitedMember
  | GtmSubscribedPlan
  | GtmSwitchedTeam
  | GtmTestedExtraction
  | GtmViewedErrorMessage
  | GtmCustomSavedSchema
  | GtmCustomFslUploadedSample
  | GtmCustomMslUploadedSample
  | GtmCustomFslReviewedSample
  | GtmCustomMslEnabled
  | GtmCustomMslRequestedTraining
  | GtmCustomMslStartedTraining
  | GtmCustomSwitchedModel
  | GtmClickedContactUs
  | GtmClickedSupport
  | GtmClickedImportExtractor
  | GtmClickedUpgradeToPayAsYouGo
  | GtmClickedUpgradeToStarter
  | GtmSurveyClickedBack
  | GtmSurveySetName
  | GtmSurveySetCompany
  | GtmSurveySetUseCase
  | GtmSurveySetPhone
  | GtmSurveyCompletedSurvey
  | GtmOnboardingAddSample
  | GtmOnboardingExtract
  | GtmOnboardingExtractFirstLink
  | GtmOnboardingSkipExtract
  | GtmOnboardingSkipFirstLink
  | GtmOnboardingCreateCustomModel
  | GtmOnboardingCreateCustomModelFirstLink
  | GtmOnboardingFtgExit
  | GtmOnboardingFtgFirstDialogShowMe
  | GtmOnboardingFtgTooltipViewed
  | GtmTestTabReupload
  | GtmClickedManagedRules;

export type GtmEventListener = (event: GtmEvent) => boolean;

export interface GtmService {
  push(event: GtmEvent): void;

  // Add a listener to listen to the events pushed to the data layer.
  // The listener should return true if it wants to continue to listen.
  addEventListener(listener: GtmEventListener): void;
}

export class NullGtmService implements GtmService {
  push(_event: GtmEvent): void {
    console.log(`null gtm service sending event ${_event.event}`);
  }
  addEventListener(_listener: GtmEventListener): void {
    console.log(`null gtm service adding listener`);
  }
}

type InternalGtmEvent = GtmEvent & { _clear: boolean };

interface DataLayer {
  push(event: InternalGtmEvent): void;
}

export class GtmServiceImpl implements GtmService {
  private dataLayer: DataLayer;
  private listeners: GtmEventListener[] = [];

  constructor() {
    this.dataLayer = GtmServiceImpl.getDataLayer();
  }

  private static getDataLayer(): DataLayer {
    // Ref: https://developers.google.com/tag-platform/tag-manager/datalayer
    const anyWindow = window as any;
    anyWindow.dataLayer = anyWindow.dataLayer || [];
    return anyWindow.dataLayer as DataLayer;
  }

  push(event: GtmEvent): void {
    try {
      this.dataLayer.push({
        ...event,
        // Prevent data layer from keeping old properties
        // https://github.com/google/data-layer-helper#preventing-default-recursive-merge
        _clear: true,
      });

      this.listeners = this.listeners.filter(listener => listener(event));
    } catch (e: any) {
      console.error("Failed to push to data layer", e);
    }
  }

  addEventListener(listener: GtmEventListener): void {
    this.listeners.push(listener);
  }
}

// To be initialized in the root index.tsx
export let GtmServiceInstance: GtmService = new NullGtmService();

export function initializeGtmServiceInstance(service: GtmService): void {
  GtmServiceInstance = service;
}
