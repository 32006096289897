import {
  Dropdown,
  IComboBoxOption,
  IDropdown,
  IDropdownOption,
  IDropdownProps,
  IRenderFunction,
  Icon,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, { useCallback } from "react";

import { useLocale } from "../../contexts/locale";
import { IconButtonV3 } from "../IconButtonV3";
import ShortSpinner from "../ShortSpinner";

export interface AddableDropdownProps {
  className?: string;
  options: IDropdownOption[];
  isLoading?: boolean;
  loadingMessageId?: string;
  selectedKey?: string | number | null;
  onSelected?: (key: number | string | null) => void;
  onAdded?: () => void | Promise<void>;
  addMessageId?: string;
  placeholderMessageId?: string;
  disabled?: boolean;
  errorMessageId?: string | undefined;
  onPendingValueChanged?: (
    _option?: IComboBoxOption | undefined,
    _index?: number | undefined,
    value?: string | undefined
  ) => void;
  shouldWaitForAdded?: boolean;
}

export function useAddableDropdownState(props: AddableDropdownProps) {
  const {
    options,
    onSelected,
    selectedKey,
    onAdded,
    isLoading,
    loadingMessageId,
    addMessageId,
    disabled,
    errorMessageId,
  } = props;

  const [isAdding, setIsAdding] = React.useState(false);
  const shouldWaitForAdded = props.shouldWaitForAdded ?? false;

  const componentRef = React.useRef<IDropdown>(null);

  const onChange = useCallback(
    (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option?.key) {
        onSelected?.(option.key);
      }
    },
    [onSelected]
  );

  const onRenderList = useCallback<NonNullable<IDropdownProps["onRenderList"]>>(
    (props?, defaultRenderer?) => {
      if (defaultRenderer == null) {
        return null;
      }
      if (isLoading || isAdding) {
        return (
          <div className="p-4 flex items-center justify-center">
            <ShortSpinner
              labelId={loadingMessageId ?? "common.loading"}
              labelPosition="bottom"
            />
          </div>
        );
      }

      if (addMessageId == null) {
        return defaultRenderer(props);
      }

      return (
        <>
          <div
            className={cn(
              "flex flex-row gap-2 leading-4 items-center",
              "h-[36px] hover:bg-gray-100 px-[10px]"
            )}
            onClick={() => {
              onSelected?.(null);
              if (!shouldWaitForAdded) {
                onAdded?.();
                componentRef.current?.dismissMenu();
              } else {
                (async () => {
                  try {
                    setIsAdding(true);
                    await onAdded?.();
                    componentRef.current?.dismissMenu();
                  } finally {
                    setIsAdding(false);
                  }
                })();
              }
            }}
          >
            <Icon className="text-primary-500" iconName="Add" />
            <div className="text-primary-500">
              <FormattedMessage id={addMessageId} />
            </div>
          </div>

          {defaultRenderer(props)}
        </>
      );
    },
    [
      isLoading,
      isAdding,
      loadingMessageId,
      addMessageId,
      onSelected,
      onAdded,
      shouldWaitForAdded,
    ]
  );

  const onRenderTitle = useCallback<IRenderFunction<IDropdownOption[]>>(
    props => {
      return (
        <div className="flex flex-row items-center justify-center">
          <span className="flex-1">{props?.[0]?.text ?? ""}</span>
          {selectedKey !== undefined && (
            <IconButtonV3
              iconName="Cancel"
              iconClassName={disabled ? "" : "text-type-800"}
              disabled={disabled}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                e.preventDefault();
                onSelected?.(null);
              }}
            />
          )}
        </div>
      );
    },
    [onSelected, selectedKey, disabled]
  );

  return React.useMemo(() => {
    return {
      className: props.className,
      options,
      onChange,
      selectedKey,
      placeholderMessageId:
        props.placeholderMessageId ?? "dropdown.placeholder",
      disabled: props.disabled,
      onPendingValueChanged: props.onPendingValueChanged,
      onRenderList,
      componentRef,
      onRenderTitle,
      errorMessageId,
    };
  }, [
    options,
    onChange,
    props.placeholderMessageId,
    props.className,
    props.disabled,
    props.onPendingValueChanged,
    onRenderList,
    componentRef,
    selectedKey,
    onRenderTitle,
    errorMessageId,
  ]);
}

const AddableDropdownImpl = (
  props: ReturnType<typeof useAddableDropdownState>
) => {
  const {
    options,
    selectedKey,
    onChange,
    placeholderMessageId,
    disabled,
    onRenderList,
    componentRef,
    onRenderTitle,
    errorMessageId,
  } = props;

  const { localized } = useLocale();
  const errorMessage = errorMessageId ? localized(errorMessageId) : undefined;

  return (
    <Dropdown
      componentRef={componentRef}
      disabled={disabled}
      className={props.className}
      options={options}
      calloutProps={{
        calloutMaxHeight: 264,
        calloutMinWidth: 200,
        alignTargetEdge: true,
      }}
      placeholder={localized(placeholderMessageId)}
      selectedKey={selectedKey}
      onChange={onChange}
      onRenderList={onRenderList}
      onRenderTitle={onRenderTitle}
      errorMessage={errorMessage}
    />
  );
};

export const AddableDropdown = (props: AddableDropdownProps) => {
  const state = useAddableDropdownState(props);

  return <AddableDropdownImpl {...state} />;
};
