import React, { ReactElement, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { OAuthCallbackPayload } from "../actions/oauth";
import LoadingModal from "../components/LoadingModal";

// NOTE: expect this is opened as popup during google auth in flow, and will be closed by parent when finish
const OAuthContainer = (): ReactElement => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const data: OAuthCallbackPayload = {
      oauthErrorId: searchParams.get("error"),
      oauthErrorDescription: searchParams.get("error_description"),
      id: searchParams.get("id"),
      nonce: searchParams.get("nonce"),
      email: searchParams.get("email"),
    };

    window.opener?.postMessage(data, window.location.origin);
  }, [searchParams]);

  return <LoadingModal isOpen={true} />;
};

export default OAuthContainer;
