import * as yup from "yup";

export const quotaSchema = yup
  .object({
    hardLimit: yup.number().nullable(),
    softLimit: yup.number().nullable(),
    usageCap: yup.number().nullable(),
  })
  .camelCase();

export const planQuotaSchema = yup
  .object({
    aggregate: quotaSchema.nullable(),
    annually: quotaSchema.nullable(),
    daily: quotaSchema.nullable(),
    monthly: quotaSchema.nullable(),
  })
  .camelCase();

export type Quota = yup.InferType<typeof quotaSchema>;

export type PlanQuota = yup.InferType<typeof planQuotaSchema>;
