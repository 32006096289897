import {
  IButtonProps,
  IIconProps,
  ActionButton as MSActionButton,
  DefaultButton as MSDefaultButton,
  PrimaryButton as MSPrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { Values } from "@oursky/react-messageformat";
import cn from "classnames";
import React, { useMemo } from "react";

import { useLocale } from "../../../contexts/locale";
import styles from "./styles.module.scss";

interface BaseButtonProps extends Omit<IButtonProps, "text"> {
  textId: string;
  textValues?: Values;
  iconName?: string;
  loading?: boolean;
  reverseIconPosition?: boolean;
}

function LoadingButtonWrapper(Component: React.ComponentType<IButtonProps>) {
  return (props: BaseButtonProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { localized } = useLocale();
    const { className, loading, textId, textValues, iconName, ...restProps } =
      props;
    const reverseIconPosition = props.reverseIconPosition ?? false;
    const icon: IIconProps = { iconName };

    const customStyles = {
      root: {
        whiteSpace: "nowrap",
      },
      flexContainer: {
        flexDirection: reverseIconPosition ? "row-reverse" : "row",
      },
    };

    return (
      <Component
        className={cn(className, { [styles.buttonLoading]: loading })}
        iconProps={icon}
        text={localized(textId, textValues)}
        styles={customStyles}
        {...restProps}
      >
        {loading && (
          <Spinner size={SpinnerSize.medium} className={styles.buttonSpinner} />
        )}
      </Component>
    );
  };
}

export const PrimaryButton = LoadingButtonWrapper(MSPrimaryButton);
export const DefaultButton = LoadingButtonWrapper(MSDefaultButton);

export enum ActionButtonStyleType {
  Normal,
  TableRow,
  Info,
}

export type ActionButtonProps = IButtonProps & {
  isDestroy?: boolean;
  iconName?: string;
  textId: string;
  textValues?: Values;
  className?: string;
  styleType?: ActionButtonStyleType;
};

export const ActionButton: React.FC<ActionButtonProps> = props => {
  const { textId, textValues, iconName, className, styleType } = props;

  const { localized } = useLocale();
  const isDestroy = props.isDestroy ?? false;
  const sameIconColorActionButtonClass =
    styles["same-icon-color-action-button"];
  const classes = cn(
    className,
    isDestroy
      ? cn(
          sameIconColorActionButtonClass,
          "font-semibold enabled:text-[#a4262c] hover:enabled:text-[#a4262c] hover:enabled:opacity-70 enabled:active:opacity-50"
        )
      : "",
    styleType === ActionButtonStyleType.TableRow
      ? `${sameIconColorActionButtonClass} bg-[#f3f2f1] text-[#3b3a39] max-h-[28px]`
      : "",
    styleType === ActionButtonStyleType.Info
      ? `${sameIconColorActionButtonClass} text-[#0078d4]`
      : ""
  );

  const iconProps = useMemo<IIconProps>(() => ({ iconName }), [iconName]);

  return (
    <MSActionButton
      text={localized(textId, textValues)}
      iconProps={iconProps}
      {...props}
      className={classes}
    />
  );
};
