import React, { useState } from "react";

import { useAdminActionCreator } from "../actions/admin";
import TeamDetailPlan from "../components/TeamDetailPlan";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { AdminPathParam } from "../models";

function _AdminTeamDetailPlanContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const { team } = useAppSelector(state => state.admin.teamDetail);

  const { setTrial, setFree, setEnterprise, setQuota } =
    useAdminActionCreator();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const giveTrial = React.useCallback(
    async (quota: number | null, planEndAt: Date | null) => {
      setIsLoading(true);
      try {
        await setTrial(
          teamId,
          region,
          quota ?? undefined,
          planEndAt ?? undefined
        );
        toast.success("team.detail.subscription.toasts.set_trial.success");
      } catch (e) {
        console.error("Failed to set trial: ", e);
        toast.error("team.detail.subscription..toasts.set_trial.failed");
      } finally {
        setIsLoading(false);
      }
    },
    [setTrial, teamId, region, toast]
  );

  const removeTrial = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await setFree(teamId, region);
      toast.success("team.detail.subscription.toasts.remove_trial.success");
    } catch (e) {
      console.error("Failed to clear trial: ", e);
      toast.success("team.detail.subscription.toasts.remove_trial.failed");
    } finally {
      setIsLoading(false);
    }
  }, [setFree, teamId, region, toast]);

  const upgradeToEnterprise = React.useCallback(
    async (quota: number | null, planEndAt: Date | null) => {
      setIsLoading(true);
      try {
        await setEnterprise(
          teamId,
          region,
          quota ?? undefined,
          planEndAt ?? undefined
        );
        toast.success(
          "team.detail.subscription.toasts.upgrade_enterprise.success"
        );
      } catch (e) {
        console.error("Failed to upgrade to enterprise: ", e);
        toast.success(
          "team.detail.subscription.toasts.upgrade_enterprise.failed"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [setEnterprise, teamId, region, toast]
  );

  const downgradeFromEnterprise = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await setFree(teamId, region);
      toast.success(
        "team.detail.subscription.toasts.downgrade_enterprise.success"
      );
    } catch (e) {
      console.error("Failed to downgrade enterprise plan: ", e);
      toast.success(
        "team.detail.subscription.toasts.downgrade_enterprise.failed"
      );
    } finally {
      setIsLoading(false);
    }
  }, [setFree, teamId, region, toast]);

  const doSetQuota = React.useCallback(
    async (
      softLimit: number | null,
      hardLimit: number | null,
      usageCap: number | null
    ) => {
      setIsLoading(true);
      try {
        await setQuota(teamId, region, softLimit, hardLimit, usageCap);
        toast.success("team.detail.subscription.toasts.set_quota.success");
      } catch (e) {
        console.error("Failed to downgrade enterprise plan: ", e);
        toast.success("team.detail.subscription.toasts.set_quota.failed");
      } finally {
        setIsLoading(false);
      }
    },
    [setQuota, teamId, region, toast]
  );

  return team == null ? null : (
    <TeamDetailPlan
      team={team}
      isLoading={isLoading}
      giveTrial={giveTrial}
      removeTrial={removeTrial}
      upgradeToEnterprise={upgradeToEnterprise}
      downgradeFromEnterprise={downgradeFromEnterprise}
      setQuota={doSetQuota}
    />
  );
}

export const AdminTeamDetailPlanContainer = React.memo(
  _AdminTeamDetailPlanContainer
);
export default AdminTeamDetailPlanContainer;
