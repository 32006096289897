import { Icon } from "@fluentui/react";
import cn from "classnames";
import React from "react";

type IconButtonV3Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  iconName: string;
  iconClassName?: string;
};

export function IconButtonV3(props: IconButtonV3Props) {
  const { className, iconName, iconClassName, ...rest } = props;

  return (
    <button
      className={cn(
        "w-[32px] h-[32px]",
        "border-0 rounded-[2px]",
        "select-none",
        "bg-transparent",
        "outline-none",
        "hover:enabled:bg-gray-100 active:enabled:bg-gray-200",
        "focus-visible:outline focus-visible:outline-[1px] focus-visible:outline-offset-[-4px]",
        "focus-visible:outline-gray-800",
        className
      )}
      onClick={props.onClick}
      {...rest}
    >
      <Icon
        iconName={iconName}
        className={cn(
          "w-[16px] h-[16px]",
          props.disabled ? "text-gray-600" : "",
          iconClassName
        )}
      />
    </button>
  );
}
