import { IconButton } from "@fluentui/react";
import cn from "classnames";
import React, { useState } from "react";

import { GroupHeader } from "../GroupHeader";
import styles from "./ConfigurationCardLayout.module.scss";

interface Props {
  containerClassName?: string;
  contentClassName?: string;
  title: React.ReactNode;
  children: React.ReactNode;
  onRemoveClick: () => void;
  defaultCollapsed?: boolean;
}

function ConfigurationCardLayoutImpl(props: Props) {
  const {
    containerClassName,
    contentClassName,
    title,
    children,
    onRemoveClick,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(
    props.defaultCollapsed ?? false
  );

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={cn("bg-white", containerClassName)}>
      <div
        className={cn(
          "flex items-center cursor-pointer justify-between px-5 h-[64px]"
        )}
      >
        <GroupHeader
          size="m"
          isExpanded={!isCollapsed}
          onToggle={toggleCollapse}
        >
          {title}
        </GroupHeader>
        <div className={styles["card-header-right"]}>
          <IconButton
            className={cn(styles["card-delete-button"], "text-red-700")}
            iconProps={{
              iconName: "Trash",
            }}
            size={38}
            onClick={onRemoveClick}
          />
        </div>
      </div>
      {!isCollapsed && (
        <>
          <div
            className={cn(
              "border-0 border-t border-gray-200 border-solid",
              contentClassName
            )}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
}

export const ConfigurationCardLayout = React.memo(ConfigurationCardLayoutImpl);
