import type { PDFDocumentProxy } from "pdfjs-dist";
import { v4 as uuidv4 } from "uuid";

import { CustomModelApiClient } from "../apiClient/mixin/customModel";
import { CustomModelImageApiClient } from "../apiClient/mixin/customModelImage";
import errors, { FOCRError } from "../errors";
import { CustomModelImageExtraInfo } from "../types/customModel";
import { CustomModelImage } from "../types/customModelImage";
import { ensureFOCRError } from "../utils/errors";
import { getCMapUrl, getWorkerSrc } from "./pdfLib";

export type AdditionalImagedAddedHandler = (
  customModelId: string,
  count: number
) => void;

export type ImageUploadedHandler = (
  customModelId: string,
  customModelImage: CustomModelImage
) => void;

export type ImageUploadFailedHandler = (
  customModelId: string,
  file: File,
  error: FOCRError
) => void;

export type ImageDeletedHandler = (
  customModelId: string,
  customModelImageId: string
) => void;

export type ImageDeleteFailedHandler = (
  customModelId: string,
  customModelImageId: string,
  error: FOCRError
) => void;

type ApiClient = CustomModelApiClient & CustomModelImageApiClient;

type PdfJsLib = typeof import("pdfjs-dist");

export interface UploadImagesCallbacks {
  onAllFilesProcessed?: () => void;
  onUploadedAnImage?: (customModelImage: CustomModelImage) => void;
  onFailedToUploadAnImage?: (error: FOCRError) => void;
}

export interface CustomModelImageService {
  uploadImages: (
    customModelId: string,
    files: File[],
    info?: CustomModelImageExtraInfo,
    callbacks?: UploadImagesCallbacks
  ) => void;
  deleteImages: (
    customModelId: string,
    customModelImageIds: string[],
    shouldDeleteGroup?: boolean
  ) => void;
  cancelPendingOperations: () => Promise<void>;
  onImageUploaded: (handler: ImageUploadedHandler) => void;
  onImageUploadFailed: (handler: ImageUploadFailedHandler) => void;
  onImageDeleted: (handler: ImageDeletedHandler) => void;
  onImageDeleteFailed: (handler: ImageDeleteFailedHandler) => void;
  onAdditionalImagedAdded: (handler: AdditionalImagedAddedHandler) => void;
}

interface Task {
  enqueuedAt: number;
  onProcessed?: () => void;
}

interface UploadTask extends Task {
  file: File;
  customModelId: string;
  info?: CustomModelImageExtraInfo;
  onUploadedAnImage?: UploadImagesCallbacks["onUploadedAnImage"];
  onFailedToUploadAnImage?: UploadImagesCallbacks["onFailedToUploadAnImage"];
}

interface DeleteTask extends Task {
  customModelImageId: string;
  customModelId: string;
  shouldDeleteGroup?: boolean;
}

interface ValidatedUploadTask extends UploadTask {
  dimension?: {
    width: number;
    height: number;
  };
}

interface TaskQueue<A extends Task> {
  queue: A[];
  runner: (task: A) => Promise<void>;
  isRunning: boolean;
}

export class CustomModelImageServiceImpl implements CustomModelImageService {
  imageUploadedHandler?: ImageUploadedHandler;
  imageUploadFailedHandler?: ImageUploadFailedHandler;

  imageDeletedHandler?: ImageDeletedHandler;
  imageDeleteFailedHandler?: ImageDeleteFailedHandler;

  additionalImagedAddedHandler?: AdditionalImagedAddedHandler;

  apiClient: ApiClient;
  canvas: HTMLCanvasElement;
  renderingContext: CanvasRenderingContext2D | null;

  taskValidAfter: number;

  uploadImageTaskQueue: TaskQueue<UploadTask>;
  uploadPdfTaskQueue: TaskQueue<UploadTask>;
  deleteTaskQueue: TaskQueue<DeleteTask>;

  pdfJsLib?: PdfJsLib;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
    this.canvas = document.createElement("canvas");
    this.renderingContext = this.canvas.getContext("2d");
    this.taskValidAfter = new Date().getTime();

    this.uploadImageTaskQueue = {
      queue: [],
      isRunning: false,
      runner: this.validateAndUploadImage,
    };
    this.uploadPdfTaskQueue = {
      queue: [],
      isRunning: false,
      runner: this.splitAndUploadPdfDocument,
    };
    this.deleteTaskQueue = {
      queue: [],
      isRunning: false,
      runner: this.doDeleteImage,
    };
  }

  uploadImages(
    customModelId: string,
    files: File[],
    info?: CustomModelImageExtraInfo,
    callbacks?: UploadImagesCallbacks
  ) {
    const onProcessed = callbacks?.onAllFilesProcessed
      ? this.createCounter(files.length, callbacks.onAllFilesProcessed)
      : undefined;

    const enqueuedAt = new Date().getTime();
    const commonProperties = {
      customModelId,
      enqueuedAt,
      info,
      onProcessed,
      onUploadedAnImage: callbacks?.onUploadedAnImage,
      onFailedToUploadAnImage: callbacks?.onFailedToUploadAnImage,
    };

    const { pdfTasks, nonPdfTasks } = files.reduce<{
      pdfTasks: UploadTask[];
      nonPdfTasks: UploadTask[];
    }>(
      (acc, file) =>
        file.type === "application/pdf"
          ? {
              ...acc,
              pdfTasks: [...acc.pdfTasks, { file, ...commonProperties }],
            }
          : {
              ...acc,
              nonPdfTasks: [...acc.nonPdfTasks, { file, ...commonProperties }],
            },
      { pdfTasks: [], nonPdfTasks: [] }
    );

    this.enqueueTask(this.uploadPdfTaskQueue, pdfTasks);
    this.enqueueTask(this.uploadImageTaskQueue, nonPdfTasks);
  }

  deleteImages(
    customModelId: string,
    customModelImageIds: string[],
    shouldDeleteGroup?: boolean
  ) {
    const enqueuedAt = new Date().getTime();
    const tasks = customModelImageIds.map(customModelImageId => ({
      enqueuedAt,
      customModelImageId,
      customModelId,
      shouldDeleteGroup,
    }));

    this.enqueueTask(this.deleteTaskQueue, tasks);
  }

  onImageUploaded(handler: ImageUploadedHandler) {
    this.imageUploadedHandler = handler;
  }

  onImageUploadFailed(handler: ImageUploadFailedHandler) {
    this.imageUploadFailedHandler = handler;
  }

  onImageDeleted(handler: ImageDeletedHandler) {
    this.imageDeletedHandler = handler;
  }

  onImageDeleteFailed(handler: ImageDeleteFailedHandler) {
    this.imageDeleteFailedHandler = handler;
  }

  onAdditionalImagedAdded(handler: AdditionalImagedAddedHandler) {
    this.additionalImagedAddedHandler = handler;
  }

  async cancelPendingOperations(): Promise<void> {
    this.taskValidAfter = new Date().getTime();
    return new Promise(resolve => {
      const timerId = window.setInterval(() => {
        if (
          this.uploadPdfTaskQueue.queue.length === 0 &&
          this.uploadImageTaskQueue.queue.length === 0 &&
          this.deleteTaskQueue.queue.length === 0
        ) {
          window.clearInterval(timerId);
          resolve();
        } else {
          console.log(
            "waiting for task get cancelled",
            this.uploadPdfTaskQueue.queue.length,
            this.uploadImageTaskQueue.queue.length,
            this.deleteTaskQueue.queue.length
          );
        }
      }, 1000);
    });
  }

  private getPdfJsLib = async (): Promise<PdfJsLib> => {
    if (this.pdfJsLib !== undefined) {
      return Promise.resolve(this.pdfJsLib);
    }

    const pdfJsLib = await import("pdfjs-dist");

    pdfJsLib.GlobalWorkerOptions.workerSrc = getWorkerSrc();

    this.pdfJsLib = pdfJsLib;

    return pdfJsLib;
  };

  private splitAndUploadPdfDocument = async (
    task: UploadTask
  ): Promise<void> => {
    const { customModelId, file, enqueuedAt, info } = task;

    const fileNamePrefix = file.name.replace(/\.pdf$/i, "");

    let pdf = null;
    const groupId = uuidv4();
    try {
      const pdfJsLib = await this.getPdfJsLib();
      pdf = await pdfJsLib.getDocument({
        data: await this.readFileAsArrayBuffer(file),
        cMapUrl: getCMapUrl(),
        cMapPacked: true,
      }).promise;

      if (this.additionalImagedAddedHandler && pdf.numPages > 1) {
        this.additionalImagedAddedHandler(customModelId, pdf.numPages - 1);
      }

      for (let i = 1; i <= pdf.numPages; i++) {
        const pageFileName =
          pdf.numPages > 1
            ? `${fileNamePrefix}-${i - 1}.jpg`
            : `${fileNamePrefix}.jpg`;

        const imageMime = "image/jpeg";

        if (!this.isTaskValid(task)) {
          break;
        }
        const updatedInfo = {
          ...(info ?? {}),
          groupId: groupId,
          groupItemIndex: i - 1,
        };

        try {
          const validatedFile = await this.renderPdfPage(
            pdf,
            i,
            pageFileName,
            imageMime,
            customModelId,
            enqueuedAt,
            updatedInfo,
            task?.onUploadedAnImage,
            task?.onFailedToUploadAnImage
          );
          if (!this.isTaskValid(task)) {
            break;
          }
          await this.doUploadImage(validatedFile);
        } catch (e) {
          if (this.imageUploadFailedHandler && this.isTaskValid(task)) {
            this.imageUploadFailedHandler(
              customModelId,
              new File([], pageFileName, { type: imageMime }),
              errors.CannotRenderImage
            );
          }
          if (task.onFailedToUploadAnImage && this.isTaskValid(task)) {
            task.onFailedToUploadAnImage(errors.CannotRenderImage);
          }
          const shouldSkipFurtherUpload =
            updatedInfo.groupItemIndex === 0 &&
            (updatedInfo?.fslInput !== undefined ||
              updatedInfo?.fslOutput !== undefined);

          if (shouldSkipFurtherUpload) {
            break;
          }
        }
      }
    } catch (e) {
      if (this.imageUploadFailedHandler && this.isTaskValid(task)) {
        this.imageUploadFailedHandler(
          customModelId,
          file,
          errors.CannotRenderImage
        );
      }
      if (task.onFailedToUploadAnImage && this.isTaskValid(task)) {
        task.onFailedToUploadAnImage(errors.CannotRenderImage);
      }
    } finally {
      if (pdf) {
        pdf.cleanup(false);
      }
    }
  };

  private renderPdfPage = async (
    pdf: PDFDocumentProxy,
    pageNumber: number,
    filename: string,
    imageMime: string,
    customModelId: string,
    enqueuedAt: number,
    info?: CustomModelImageExtraInfo,
    onUploadedAnImage?: UploadImagesCallbacks["onUploadedAnImage"],
    onFailedToUploadAnImage?: UploadImagesCallbacks["onFailedToUploadAnImage"]
  ): Promise<ValidatedUploadTask> => {
    let page = null;

    try {
      if (this.renderingContext === null) {
        this.canvas = document.createElement("canvas");
        this.renderingContext = this.canvas.getContext("2d");
        throw errors.CannotRenderImage;
      }

      page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 2 });

      this.canvas.width = viewport.width;
      this.canvas.height = viewport.height;
      this.renderingContext.clearRect(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );

      await page.render({
        canvasContext: this.renderingContext,
        viewport,
      }).promise;

      return {
        file: await new Promise((resolve, reject) => {
          this.canvas.toBlob(
            blob =>
              blob
                ? resolve(new File([blob], filename, { type: imageMime }))
                : reject(errors.CannotRenderImage),
            imageMime
          );
        }),
        customModelId,
        enqueuedAt,
        dimension: {
          width: this.canvas.width,
          height: this.canvas.height,
        },
        info,
        onUploadedAnImage,
        onFailedToUploadAnImage,
      };
    } finally {
      if (page) {
        page.cleanup();
      }
    }
  };

  private validateAndUploadImage = async (task: UploadTask): Promise<void> => {
    const { file, customModelId } = task;
    try {
      const validatedFile = await this.validateImageFile(task);
      if (!this.isTaskValid(task)) {
        return;
      }

      await this.doUploadImage(validatedFile);
    } catch (e) {
      if (this.imageUploadFailedHandler && this.isTaskValid(task)) {
        this.imageUploadFailedHandler(customModelId, file, ensureFOCRError(e));
      }
      if (task.onFailedToUploadAnImage && this.isTaskValid(task)) {
        task.onFailedToUploadAnImage(ensureFOCRError(e));
      }
    }
  };

  private validateImageFile = async (
    task: UploadTask
  ): Promise<ValidatedUploadTask> => {
    const { file } = task;
    return await new Promise(async (resolve, reject) => {
      const image = new Image();
      const content = await this.readFileAsDataUri(file);

      image.addEventListener("load", async () => {
        const { width, height } =
          file.type === "image/jpeg"
            ? this.getJPEGDimension(content) || image
            : image;

        resolve({
          ...task,
          dimension: {
            width,
            height,
          },
        });
      });

      image.addEventListener("error", () => {
        reject(
          new FOCRError("error.sample_image_is_corrupted", {
            filename: file.name,
          })
        );
      });

      image.src = content;
    });
  };

  private readFileAsDataUri = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          resolve(reader.result as string);
        },
        false
      );
      reader.addEventListener("error", () => {
        reject(
          new FOCRError("error.sample_image_is_corrupted", {
            filename: file.name,
          })
        );
      });

      reader.readAsDataURL(file);
    });
  };

  private readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          resolve(reader.result as ArrayBuffer);
        },
        false
      );
      reader.addEventListener("error", () => {
        reject(
          new FOCRError("error.sample_image_is_corrupted", {
            filename: file.name,
          })
        );
      });

      reader.readAsArrayBuffer(file);
    });
  };

  private getJPEGDimension = (dataURI: string) => {
    const data = window.atob(dataURI.slice(dataURI.indexOf(",") + 1));
    const bufferSize = data.length;
    const startOfFrameMarkers = [
      0xc0, 0xc1, 0xc2, 0xc3, 0xc5, 0xc6, 0xc7, 0xc9, 0xca, 0xc8, 0xcd, 0xce,
      0xcf,
    ];

    const uint16At = (offset: number) =>
      (data.charCodeAt(offset) << 8) + data.charCodeAt(offset + 1);

    if (!(data.charCodeAt(0) === 0xff && data.charCodeAt(1) === 0xd8)) {
      return null;
    }

    let i = 2;
    while (i < bufferSize - 2) {
      if (data.charCodeAt(i) !== 0xff) {
        return null;
      }
      const marker = data.charCodeAt(i + 1);

      if (startOfFrameMarkers.includes(marker)) {
        return {
          height: uint16At(i + 5),
          width: uint16At(i + 7),
        };
      } else {
        const blockLength =
          marker >= 0xd0 && marker <= 0xd9
            ? 0
            : marker === 0xdd
            ? 4
            : uint16At(i + 2);

        i += blockLength + 2;
      }
    }

    return null;
  };

  private doUploadImage = async (
    task: ValidatedUploadTask
  ): Promise<CustomModelImage | null> => {
    const { file, customModelId, dimension, info } = task;
    if (!this.isTaskValid(task)) {
      return Promise.resolve(null);
    }

    try {
      const assetId = await this.apiClient.uploadFileForCustomModel(
        undefined,
        customModelId,
        file,
        dimension?.width,
        dimension?.height
      );

      const uploadInfo = {
        filename: file.name,
        content_type: file.type,
      } as Record<string, any>;

      if (info?.fslInput !== undefined && info?.fslOutput !== undefined) {
        if (info?.groupItemIndex === undefined || info?.groupItemIndex === 0) {
          uploadInfo["fsl_input"] = info?.fslInput;
          uploadInfo["fsl_output"] = info?.fslOutput;
        } else {
          uploadInfo["is_fsl_image"] = true;
        }
      }

      if (info?.groupId !== undefined && info?.groupItemIndex !== undefined) {
        uploadInfo["group_id"] = info?.groupId;
        uploadInfo["group_item_index"] = info?.groupItemIndex;
      }

      if (info?.isExtractionDisabled !== undefined) {
        uploadInfo["is_extraction_disabled"] = info?.isExtractionDisabled;
      }

      const customModelImage = await this.apiClient.createCustomModelImage(
        customModelId,
        assetId,
        uploadInfo
      );

      if (info?.fslOutput !== undefined) {
        customModelImage.info = {
          ...customModelImage.info,
          ...Object.fromEntries(
            Object.entries(uploadInfo).filter(kv =>
              [
                "fsl_output",
                "is_fsl_image",
                "group_id",
                "group_item_index",
              ].includes(kv[0])
            )
          ),
        };
      }

      if (this.imageUploadedHandler && this.isTaskValid(task)) {
        this.imageUploadedHandler(customModelId, customModelImage);
      }
      if (task.onUploadedAnImage && this.isTaskValid(task)) {
        task.onUploadedAnImage(customModelImage);
      }

      return customModelImage;
    } catch (e) {
      if (this.imageUploadFailedHandler && this.isTaskValid(task)) {
        this.imageUploadFailedHandler(customModelId, file, ensureFOCRError(e));
      }
      if (task.onFailedToUploadAnImage && this.isTaskValid(task)) {
        task.onFailedToUploadAnImage(ensureFOCRError(e));
      }
      return null;
    }
  };

  private doDeleteImage = async (task: DeleteTask) => {
    const { customModelId, customModelImageId } = task;
    if (!this.isTaskValid(task)) {
      return Promise.resolve();
    }

    try {
      await this.apiClient.deleteCustomModelImage(
        customModelImageId,
        task.shouldDeleteGroup
      );
      if (this.imageDeletedHandler && this.isTaskValid(task)) {
        this.imageDeletedHandler(customModelId, customModelImageId);
      }
    } catch (e) {
      if (this.imageDeleteFailedHandler && this.isTaskValid(task)) {
        this.imageDeleteFailedHandler(
          customModelId,
          customModelImageId,
          ensureFOCRError(e)
        );
      }
    }
  };

  private runTaskSequentially = async <A extends Task>(
    taskQueue: TaskQueue<A>
  ): Promise<void> => {
    taskQueue.isRunning = true;
    while (true) {
      const task = taskQueue.queue.shift();
      if (task === undefined) {
        break;
      }

      if (!this.isTaskValid(task)) {
        if (task.onProcessed) {
          task.onProcessed();
        }
        continue;
      }

      try {
        await taskQueue.runner(task);
      } catch (e) {
        console.error(e);
      } finally {
        if (task.onProcessed) {
          task.onProcessed();
        }
      }
    }
    taskQueue.isRunning = false;
  };

  private enqueueTask = <A extends Task>(
    taskQueue: TaskQueue<A>,
    tasks: A[]
  ): void => {
    taskQueue.queue.push(...tasks);

    if (taskQueue.isRunning) {
      return;
    }

    this.runTaskSequentially(taskQueue).catch(console.error);
  };

  private isTaskValid = (task: Task) => task.enqueuedAt > this.taskValidAfter;

  private createCounter = (limit: number, onReachLimit: () => void) => {
    let currentCount = 0;
    let _resolve = () => {};

    const countUp = () => {
      currentCount += 1;
      if (currentCount === limit) {
        if (_resolve) {
          _resolve();
        }
      }
    };
    new Promise<void>(resolve => {
      _resolve = resolve;
    }).then(onReachLimit);

    return countUp;
  };
}

export function createCustomModelImageService(
  apiClient: ApiClient
): CustomModelImageService {
  return new CustomModelImageServiceImpl(apiClient);
}
