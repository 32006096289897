import {
  GoogleSheetFieldMapping,
  WorkspaceGoogleSheetExport,
} from "./googleSheet";

export const allWorkspaceIntegrationImportOptionType = [
  "import_sharepoint",
  "import_email",
  "import_googleDrive",
  "import_dropbox",
] as const;

export const allWorkspaceIntegrationExportOptionType = [
  "export_webhook",
  "export_googleSheets",
] as const;

export const allWorkspaceIntegration3rdPartyOptionType = [
  "export_zapier",
] as const;

export type WorkspaceIntegrationOptionType =
  | (typeof allWorkspaceIntegrationImportOptionType)[number]
  | (typeof allWorkspaceIntegrationExportOptionType)[number]
  | (typeof allWorkspaceIntegration3rdPartyOptionType)[number];

export interface WorkspaceIntegrationConfigurationBase {
  // The id of the configuration.
  // If it is not created on the server side, it will be a temporary id.
  // It is needed for the React to render list of configurations.
  id: string;
}

// TODO: Update after handle get current SharePoint subscription #2227
export interface WorkspaceSharePointIntegrationConfiguration
  extends WorkspaceIntegrationConfigurationBase {
  optionType: "import_sharepoint";
  siteUrlSubdomain: string;
  sharePointSubscription: unknown | null;
}

const DEFAULT_ID = "temp:default";

const initialSharePointIntegrationConfiguration: WorkspaceSharePointIntegrationConfiguration =
  {
    optionType: "import_sharepoint",
    siteUrlSubdomain: "",
    sharePointSubscription: null,
    id: DEFAULT_ID,
  } as const;

// TODO: Update after implementing google sheets export
export interface WorkspaceExportGoogleSheetsIntegrationConfiguration
  extends WorkspaceIntegrationConfigurationBase {
  optionType: "export_googleSheets";
  mappings: GoogleSheetFieldMapping[];
  oauthCredentialId: string | null;
  spreadsheetId: string | null;
  spreadsheetName: string | null;
  sheetId: string | null;
  sheetName: string | null;
}

export class WorkspaceExportGoogleSheetsIntegrationConfigurationAccessor {
  data: WorkspaceExportGoogleSheetsIntegrationConfiguration;

  constructor(data: WorkspaceExportGoogleSheetsIntegrationConfiguration) {
    this.data = data;
  }

  static createFromWorkspaceGoogleSheetExport(
    input: WorkspaceGoogleSheetExport
  ): WorkspaceExportGoogleSheetsIntegrationConfigurationAccessor {
    return new WorkspaceExportGoogleSheetsIntegrationConfigurationAccessor({
      optionType: "export_googleSheets",
      id: input.id,
      mappings: input.config.mappings,
      oauthCredentialId: input.oauthCredentialId,
      sheetId: input.googleSheetId,
      spreadsheetId: input.googleSpreadsheetId,
      spreadsheetName: input.googleSpreadsheetName,
      sheetName: input.googleSheetName,
    });
  }
}

const initialExportToGoogleSheetsIntegrationConfiguration: WorkspaceExportGoogleSheetsIntegrationConfiguration =
  {
    optionType: "export_googleSheets",
    mappings: [],
    oauthCredentialId: null,
    sheetId: null,
    spreadsheetId: null,
    spreadsheetName: null,
    sheetName: null,
    id: DEFAULT_ID,
  };

// TODO: Update after handle webhook export #2203
export interface WorkspaceWebhookIntegrationConfiguration
  extends WorkspaceIntegrationConfigurationBase {
  optionType: "export_webhook";
}

export interface WorkspaceZapierIntegrationConfiguration
  extends WorkspaceIntegrationConfigurationBase {
  optionType: "export_zapier";
  zapId: string;
  webhookId: string;
}

const initialWebhookIntegrationConfiguration: WorkspaceWebhookIntegrationConfiguration =
  {
    id: DEFAULT_ID,
    optionType: "export_webhook",
  } as const;

// NOTE: Placeholder for coming soon options
export interface WorkspaceUnknownIntegrationConfiguration
  extends WorkspaceIntegrationConfigurationBase {
  optionType: "import_email" | "import_googleDrive" | "import_dropbox";
}

export type WorkspaceIntegrationConfiguration =
  | WorkspaceExportGoogleSheetsIntegrationConfiguration
  | WorkspaceSharePointIntegrationConfiguration
  | WorkspaceWebhookIntegrationConfiguration
  | WorkspaceUnknownIntegrationConfiguration
  | WorkspaceZapierIntegrationConfiguration;

export const initialWorkspaceIntegrationConfiguration: Record<
  Exclude<WorkspaceIntegrationOptionType, "export_zapier">,
  WorkspaceIntegrationConfiguration
> = {
  import_sharepoint: initialSharePointIntegrationConfiguration,
  import_email: { id: DEFAULT_ID, optionType: "import_email" },
  import_googleDrive: { id: DEFAULT_ID, optionType: "import_googleDrive" },
  import_dropbox: { id: DEFAULT_ID, optionType: "import_dropbox" },
  export_googleSheets: initialExportToGoogleSheetsIntegrationConfiguration,
  export_webhook: initialWebhookIntegrationConfiguration,
} as const;

// SharePoint Integration

export interface FilePickerSharePointFile {
  id: string;
  parentReference: {
    driveId: string;
    sharepointIds: {
      listId: string;
      webId: string;
      siteId: string;
    };
  };
  sharepointIds: {
    listItemUniqueId: string;
    listItemId: string;
    listId: string;
    webId: string;
    siteId: string;
  };
  "@sharePoint.embedUrl": string;
  "@sharePoint.endpoint": string;
}

export interface PickCommandPayloadData {
  command: "pick";
  items: FilePickerSharePointFile[];
  keepSharing: boolean;
}
