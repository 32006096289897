import { IButtonProps, PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, { useCallback } from "react";

import { PLAN_NAMES } from "../../constants";
import { useGtm } from "../../hooks/gtm";

export interface PlanCardProps {
  className?: string;
  currentPlanName: string;
  currentPlanIsPaid: boolean;
  onAction?: () => void;
  onContactUs?: () => void;
}

function isSwitchPlanDisabled(planName: string, planIsPaid: boolean) {
  return planIsPaid || planName === PLAN_NAMES.enterprise;
}

function CardContainer(props: React.PropsWithChildren<object>) {
  return (
    <div className="relative h-full p-6 bg-white text-gray-800 border border-solid border-gray-200 shadow">
      {props.children}
    </div>
  );
}

function CardTitle(props: React.PropsWithChildren<object>) {
  return <p className="text-lg font-semibold mb-3">{props.children}</p>;
}

function CardPrice(props: React.PropsWithChildren<object>) {
  return (
    <div className="flex min-h-10 items-end">
      <p>{props.children}</p>
    </div>
  );
}

function CardDescription(props: React.PropsWithChildren<object>) {
  return (
    <p className="text-base font-normal my-4 min-h-11">{props.children}</p>
  );
}

function CardButton(props: IButtonProps) {
  const { className, ...rest } = props;
  return (
    <PrimaryButton
      className={cn(
        className,
        "font-semibold disabled:border-gray-600 disabled:text-gray-900 w-full"
      )}
      {...rest}
    />
  );
}

function CardBulletItemList(props: React.PropsWithChildren<object>) {
  return (
    <ul className="pl-5 mt-5 text-base font-normal list-outside space-y-4">
      {props.children}
    </ul>
  );
}

export function PlanCardFree(props: PlanCardProps) {
  const { className, onAction } = props;

  return (
    <div className={className}>
      <CardContainer>
        <CardTitle>
          <FormattedMessage id="payment.plan.free.title" />
        </CardTitle>
        <CardPrice>
          <span className="text-3xl font-semibold">
            <FormattedMessage id="payment.plan.free.price" />
          </span>
        </CardPrice>
        <CardDescription>
          <FormattedMessage id="payment.plan.free.description" />
        </CardDescription>
        <CardButton disabled={true} onClick={onAction}>
          <FormattedMessage id="payment.plan.free.action" />
        </CardButton>
        <CardBulletItemList>
          <li>
            <FormattedMessage id="payment.plan.free.details.item1" />
          </li>
        </CardBulletItemList>
      </CardContainer>
    </div>
  );
}

export function PlanCardPayAsYouGo(props: PlanCardProps) {
  const {
    className,
    currentPlanName,
    currentPlanIsPaid,
    onAction,
    onContactUs,
  } = props;

  const {
    pushClickedContactUsEvent,
    pushClickedSupportEvent,
    pushClickedUpgradeToPayAsYouGo,
  } = useGtm();

  const needContactUs =
    currentPlanIsPaid ||
    isSwitchPlanDisabled(currentPlanName, currentPlanIsPaid);
  const isCurrent = currentPlanName === PLAN_NAMES.payAsYouGo;

  const handleOnClick = useCallback(() => {
    if (needContactUs) {
      onContactUs?.();
      pushClickedContactUsEvent("plan_billing_pay_as_you_go");
    } else {
      onAction?.();
      pushClickedUpgradeToPayAsYouGo();
    }
  }, [
    needContactUs,
    onContactUs,
    pushClickedContactUsEvent,
    onAction,
    pushClickedUpgradeToPayAsYouGo,
  ]);

  const handleRootOnClick = useCallback(
    (e: React.MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        e.target.classList.contains("formx-support")
      ) {
        pushClickedSupportEvent("plan_billing_pay_as_you_go");
      }
    },
    [pushClickedSupportEvent]
  );

  return (
    <div className={className} onClick={handleRootOnClick}>
      <CardContainer>
        <CardTitle>
          <FormattedMessage id="payment.plan.pay_as_you_go.title" />
        </CardTitle>
        <CardPrice>
          <span className="text-3xl font-semibold mr-2">
            <FormattedMessage id="payment.plan.pay_as_you_go.price.amount" />
          </span>
          <span className="text-xl font-semibold">
            <FormattedMessage id="payment.plan.pay_as_you_go.price.unit" />
          </span>
        </CardPrice>
        <CardDescription>
          <FormattedMessage id="payment.plan.pay_as_you_go.description" />
        </CardDescription>
        <CardButton disabled={isCurrent} onClick={handleOnClick}>
          {isCurrent ? (
            <FormattedMessage id="payment.plan.pay_as_you_go.action.current" />
          ) : needContactUs ? (
            <FormattedMessage id="payment.plan.pay_as_you_go.action.contact_us" />
          ) : (
            <FormattedMessage id="payment.plan.pay_as_you_go.action.enabled" />
          )}
        </CardButton>
        <CardBulletItemList>
          <li>
            <FormattedMessage id="payment.plan.pay_as_you_go.details.item1" />
          </li>
          <li>
            <FormattedMessage id="payment.plan.pay_as_you_go.details.item2" />
          </li>
        </CardBulletItemList>
      </CardContainer>
    </div>
  );
}

export function PlanCardStarter(props: PlanCardProps) {
  const {
    className,
    currentPlanName,
    currentPlanIsPaid,
    onAction,
    onContactUs,
  } = props;

  const {
    pushClickedContactUsEvent,
    pushClickedSupportEvent,
    pushClickedUpgradeToStarter,
  } = useGtm();

  const needContactUs =
    currentPlanIsPaid ||
    isSwitchPlanDisabled(currentPlanName, currentPlanIsPaid);
  const isCurrent = currentPlanName === PLAN_NAMES.starter;

  const handleOnClick = useCallback(() => {
    if (needContactUs) {
      onContactUs?.();
      pushClickedContactUsEvent("plan_billing_starter");
    } else {
      onAction?.();
      pushClickedUpgradeToStarter();
    }
  }, [
    needContactUs,
    onContactUs,
    pushClickedContactUsEvent,
    onAction,
    pushClickedUpgradeToStarter,
  ]);

  const handleRootOnClick = useCallback(
    (e: React.MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        e.target.classList.contains("formx-support")
      ) {
        pushClickedSupportEvent("plan_billing_starter");
      }
    },
    [pushClickedSupportEvent]
  );

  return (
    <div className={className} onClick={handleRootOnClick}>
      <CardContainer>
        <CardTitle>
          <FormattedMessage id="payment.plan.starter.title" />
        </CardTitle>
        <p
          className={cn(
            "absolute top-6 right-0 w-[76px] h-[32px]",
            "rounded-l shadow-sm",
            "flex items-center justify-center text-center",
            "bg-yellow-400 text-gray-900",
            "text-xs font-normal"
          )}
        >
          <span>
            {" "}
            <FormattedMessage id="payment.plan.starter.tag" />
          </span>
        </p>

        <CardPrice>
          <span className="text-3xl font-semibold mr-2">
            <FormattedMessage id="payment.plan.starter.price.amount" />
          </span>
          <span className="text-xl font-semibold">
            <FormattedMessage id="payment.plan.starter.price.unit" />
          </span>
        </CardPrice>
        <CardDescription>
          <FormattedMessage id="payment.plan.starter.description" />
        </CardDescription>
        <CardButton disabled={isCurrent} onClick={handleOnClick}>
          {isCurrent ? (
            <FormattedMessage id="payment.plan.starter.action.current" />
          ) : needContactUs ? (
            <FormattedMessage id="payment.plan.starter.action.contact_us" />
          ) : (
            <FormattedMessage id="payment.plan.starter.action.enabled" />
          )}
        </CardButton>
        <CardBulletItemList>
          <li>
            <FormattedMessage id="payment.plan.starter.details.item1" />
          </li>
          <li>
            <FormattedMessage id="payment.plan.starter.details.item2" />
          </li>
          <li>
            <FormattedMessage id="payment.plan.starter.details.item3" />
          </li>
        </CardBulletItemList>
      </CardContainer>
    </div>
  );
}

export function PlanCardEnterprise(props: PlanCardProps) {
  const { className, currentPlanName, onContactUs } = props;

  const { pushClickedContactUsEvent } = useGtm();

  const isCurrent = currentPlanName === PLAN_NAMES.enterprise;

  const handleOnClick = useCallback(() => {
    onContactUs?.();
    pushClickedContactUsEvent("plan_billing_custom_pricing");
  }, [onContactUs, pushClickedContactUsEvent]);

  return (
    <div className={className}>
      <CardContainer>
        <CardTitle>
          <FormattedMessage id="payment.plan.enterprise.title" />
        </CardTitle>
        <CardPrice>
          <span className="text-xl font-semibold">
            <FormattedMessage id="payment.plan.enterprise.price" />
          </span>
        </CardPrice>
        <CardDescription>
          <FormattedMessage id="payment.plan.enterprise.description" />
        </CardDescription>
        <CardButton disabled={isCurrent} onClick={handleOnClick}>
          {isCurrent ? (
            <FormattedMessage id="payment.plan.enterprise.action.current" />
          ) : (
            <FormattedMessage id="payment.plan.enterprise.action.contact_us" />
          )}
        </CardButton>
        <CardBulletItemList>
          <li>
            <FormattedMessage id="payment.plan.enterprise.details.item1" />
          </li>
          <li>
            <FormattedMessage id="payment.plan.enterprise.details.item2" />
          </li>
          <li>
            <FormattedMessage id="payment.plan.enterprise.details.item3" />
          </li>
          <li>
            <FormattedMessage id="payment.plan.enterprise.details.item4" />
          </li>
          <li>
            <FormattedMessage id="payment.plan.enterprise.details.item5" />
          </li>
          <li>
            <FormattedMessage id="payment.plan.enterprise.details.item6" />
          </li>
        </CardBulletItemList>
      </CardContainer>
    </div>
  );
}
