import { ExtractV2TableValue } from "../models";
import {
  ExtractedContentSchema,
  ExtractedContentSchemaAccessor,
} from "./extractedContentSchema";

export type ExtractImageListData = {
  imageUrl: string;
  boundingBox?: number[];
  value: string;
  onToggleBoundingBox?: () => void;
  isToggleBoundingBoxOn?: boolean;
}[];

export type ExtractCurrencyData = {
  currency: string;
  value?: number | string;
};

export type ExtractCheckboxData = {
  label: string;
  checked: boolean;
};

export enum ExtractDataFieldType {
  Basic,
  List,
  ListTable,
  HTMLTable,
  ListImage,
  Currency,
  Checkbox,
}

type ExtractHTMLTableCell = {
  text: string;
  rowStart: number;
  rowEnd: number;
  colStart: number;
  colEnd: number;
} | null;

export type ExtractHTMLTableData = ExtractHTMLTableCell[][];

export class ExtractHTMLTableDataAccessor {
  static fromExtractAPIV2ResponseValue(
    value: ExtractV2TableValue,
    fillEmptyCells: boolean = false
  ) {
    let maxRow = 0;
    let maxCol = 0;
    const cells = value.cells.map(row => {
      return row.map(cell => {
        if (cell === null) return null;
        const rowEnd = cell.end_row + 2;
        const colEnd = cell.end_col + 2;
        maxRow = Math.max(maxRow, rowEnd);
        maxCol = Math.max(maxCol, colEnd);
        return {
          text: cell.text,
          rowStart: cell.start_row + 1,
          rowEnd,
          colStart: cell.start_col + 1,
          colEnd,
        };
      });
    });

    if (fillEmptyCells) {
      const grid = Array(maxRow)
        .fill([])
        .map(() => Array(maxCol).fill(false));

      cells.forEach(rows => {
        rows.forEach(cell => {
          if (cell == null) return;
          for (let row = cell.rowStart; row < cell.rowEnd; row++) {
            for (let col = cell.colStart; col < cell.colEnd; col++) {
              grid[row][col] = true;
            }
          }
        });
      });

      for (let row = 1; row < maxRow; row++) {
        for (let col = 1; col < maxCol; col++) {
          if (grid[row][col] === false) {
            cells[row - 1].push({
              text: " ",
              rowStart: row,
              rowEnd: row + 1,
              colStart: col,
              colEnd: col + 1,
            });
          }
        }
      }
    }
    return cells;
  }
}

export type ExtractListTableRowData = Record<string, any> | null | undefined;

export type ExtractListTableData = {
  headers: string[];
  rows: ExtractListTableRowData[];
  types?: string[];
};

export class ExtractListTableDataAccessor {
  data: ExtractListTableData;
  constructor(data: ExtractListTableData) {
    this.data = data;
  }

  static createDefault() {
    return new ExtractListTableDataAccessor({
      headers: [],
      rows: [],
    });
  }

  static createFromExtractedContentSchemaPayloadItem(
    schema: ExtractedContentSchema,
    payloadIndex: number,
    rows?: ExtractListTableRowData[]
  ) {
    const accessor = new ExtractedContentSchemaAccessor(schema);
    const headers = accessor.getPayloadSubFieldNormalizedNames(payloadIndex);
    const types = accessor.getPayloadSubFieldTypes(payloadIndex);

    return new ExtractListTableDataAccessor({
      headers,
      types,
      rows: rows ?? [],
    });
  }

  static createFromRows(
    rows: (Record<string, string> | null | undefined)[] | null | undefined
  ) {
    if (rows == null) {
      return new ExtractListTableDataAccessor({
        headers: [],
        rows: [],
      });
    }
    const table = {} as Record<string, boolean>;
    rows.forEach(row => {
      if (row === null || row === undefined) return;
      Object.keys(row).forEach(key => {
        table[key] = true;
      });
    });

    const headers = Object.keys(table);
    return new ExtractListTableDataAccessor({
      headers,
      rows,
    });
  }
}
