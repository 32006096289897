import {
  DefaultButton,
  type IComboBoxOption,
  type IDropdownOption,
  Icon,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";

import { CancelError } from "../../actions/googleAuth";
import type { OAuthError } from "../../actions/oauth";
import { useLocale } from "../../contexts/locale";
import { useWorkspaceIntegration } from "../../contexts/workspaceIntegration";
import { FOCRError } from "../../errors";
import { useOnload } from "../../hooks/asyncguard/asyncguard";
import type {
  GoogleSheet,
  GoogleSheetFieldMapping,
  GoogleSpreadsheet,
} from "../../types/googleSheet";
import type { OAuthCredential } from "../../types/oauth";
import { WorkspaceExportGoogleSheetsIntegrationConfiguration } from "../../types/workspaceIntegration";
import { GoogleSheetFieldMappingValidationResult } from "../../validators/googleSheet";
import { GoogleSheetFieldMappingValidator } from "../../validators/googleSheet";
import { AddableDropdown } from "../AddableDropdown";
import { GroupHeader } from "../GroupHeader";
import { PrimaryLoadingButton } from "../LoadingButton";
import ShortSpinner from "../ShortSpinner";
import { TextButton } from "../TextButton";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import { ConfigurationCardLayout } from "./ConfigurationCardLayout";
import { FieldMapping } from "./FieldMapping";

export interface AuthSectionProps {
  accounts: OAuthCredential[];
  errorMessage: string | null;
  isAuthorizing: boolean;
  isFetching: boolean;
  selectedAccountId: string | null;
  onAccountChange: (key: number | string | null) => void;
  onAuthClick: () => void;
  isCreated: boolean;
}

export function AuthSection(props: AuthSectionProps) {
  const {
    accounts,
    errorMessage,
    isAuthorizing,
    isFetching,
    selectedAccountId,
    onAccountChange,
    onAuthClick,
    isCreated,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleToggle = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  return (
    <div className="px-5 pt-[10px] pb-[24px]">
      <GroupHeader size="s" isExpanded={isExpanded} onToggle={handleToggle}>
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.connectedAccounts.title" />
      </GroupHeader>
      {isExpanded && (
        <div className="px-[28px]">
          <div className="text-xs mb-2 text-gray-700">
            <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.connectedAccounts.description" />
          </div>
          {errorMessage !== null ? (
            <div className="text-[#D05C25] text-xs mb-3">{errorMessage}</div>
          ) : null}
          {!isFetching ? (
            accounts.length > 0 ? (
              <>
                <AddableDropdown
                  className="mb-3"
                  disabled={accounts.length === 0 || isCreated}
                  onSelected={onAccountChange}
                  options={accounts.map(({ id, email }) => ({
                    key: id,
                    text: email,
                  }))}
                  placeholderMessageId="dropdown.placeholder"
                  selectedKey={selectedAccountId}
                  addMessageId="workspace.integrations.configuration.export_googleSheets.connectedAccounts.new"
                  onAdded={onAuthClick}
                />
              </>
            ) : (
              <GoogleAuthButton
                onClick={onAuthClick}
                isLoading={isAuthorizing}
              />
            )
          ) : (
            <ShortSpinner />
          )}
        </div>
      )}
    </div>
  );
}

interface SpreadsheetSelectionProps {
  isFetching: boolean;
  items: GoogleSpreadsheet[];
  selectedKey: string | null;
  onChange: (key: string | number | null) => void;
  onAddSpreadsheet: () => void;
  isCreated: boolean;
  isCreatingSpreadsheet: boolean;
  errorMessageId?: string;
}

function SpreadsheetSelection(props: SpreadsheetSelectionProps) {
  const {
    isFetching,
    items,
    selectedKey,
    onChange,
    isCreated,
    errorMessageId,
    isCreatingSpreadsheet,
  } = props;

  const spreadsheetOptions = useMemo<IComboBoxOption[]>(
    () => [
      ...items.map(({ id, name }) => ({
        key: id,
        text: name,
      })),
    ],
    [items]
  );

  const handleViewSpreadsheet = useCallback(() => {
    if (selectedKey == null) {
      return;
    }

    window.open(
      `https://docs.google.com/spreadsheets/d/${selectedKey}`,
      "_blank"
    );
  }, [selectedKey]);

  return (
    <div className="mt-3">
      <div className="text-xs">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.selectSpreadsheet" />
      </div>
      {!isFetching ? (
        <>
          <AddableDropdown
            className="mt-1"
            disabled={isCreated}
            options={spreadsheetOptions}
            placeholderMessageId="dropdown.placeholder"
            selectedKey={selectedKey}
            onSelected={onChange}
            addMessageId="workspace.integrations.configuration.export_googleSheets.createNewSpreadsheet"
            onAdded={props.onAddSpreadsheet}
            errorMessageId={errorMessageId}
            isLoading={isCreatingSpreadsheet}
            loadingMessageId="common.creating"
            shouldWaitForAdded={true}
          />
          {selectedKey != null && (
            <div className="flex flex-row items-end justify-end w-100">
              <TextButton
                className="mt-1"
                iconName="forward"
                reverseIconPosition={true}
                onClick={handleViewSpreadsheet}
              >
                <div className="font-normal">
                  <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.view_spreadsheet_button" />
                </div>
              </TextButton>
            </div>
          )}
        </>
      ) : (
        <ShortSpinner />
      )}
    </div>
  );
}

interface SheetSelectionProps {
  isFetching: boolean;
  items: GoogleSheet[];
  selectedKey: string | null;
  onChange: (key: string | number | null) => void;
  isCreated?: boolean;
  errorMessageId: string | undefined;
}

function SheetSelection(props: SheetSelectionProps) {
  const {
    isFetching,
    items,
    selectedKey,
    onChange,
    isCreated,
    errorMessageId,
  } = props;

  const sheetOptions = useMemo<IComboBoxOption[]>(
    () =>
      items.map(({ id, name }) => ({
        key: id,
        text: name,
      })),
    [items]
  );

  return (
    <div className="mt-3">
      <div className="text-xs">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.selectSheet" />
      </div>
      {!isFetching ? (
        <AddableDropdown
          className="mt-1"
          disabled={items.length === 0 || isCreated}
          options={sheetOptions}
          placeholderMessageId="dropdown.placeholder"
          selectedKey={selectedKey}
          onSelected={onChange}
          errorMessageId={errorMessageId}
        />
      ) : (
        <ShortSpinner />
      )}
    </div>
  );
}

interface ConfigurationSectionProps {
  fields: string[];
  isFetchingWorkSheets: boolean;
  isFetchingSpreadsheets: boolean;
  isSelectedSheet: boolean;
  isSelectedSpreadsheet: boolean;
  mappings: GoogleSheetFieldMapping[];
  selectedSheetId: string | null;
  selectedSpreadsheetId: string | null;
  sheets: GoogleSheet[];
  spreadsheets: GoogleSpreadsheet[];
  onMappingChange: (
    columnIndex: number,
    event: React.FormEvent<HTMLElement>,
    option?: IDropdownOption
  ) => void;
  onRefreshClick: () => void;
  onSheetChange: (key: string | number | null) => void;
  onSpreadsheetChange: (key: string | number | null) => void;
  lastRefreshed: Date | null;
  onAddSpreadsheet: () => void;
  isCreated: boolean;
  validationResult: GoogleSheetFieldMappingValidationResult;
  sheetErrorId?: string;
  spreadsheetErrorId?: string;
  isFetchingFieldMapping: boolean;
  isCreatingSpreadsheet: boolean;
}

function ConfigurationSection(props: ConfigurationSectionProps) {
  const {
    fields,
    isFetchingWorkSheets,
    isFetchingSpreadsheets,
    isSelectedSheet,
    isSelectedSpreadsheet,
    mappings,
    selectedSheetId,
    selectedSpreadsheetId,
    sheets,
    spreadsheets,
    onMappingChange,
    onRefreshClick,
    onSheetChange,
    onSpreadsheetChange,
    onAddSpreadsheet,
    isCreated,
    validationResult,
    sheetErrorId,
    spreadsheetErrorId,
    isFetchingFieldMapping,
    isCreatingSpreadsheet,
  } = props;

  const [isMappingExpanded, setIsMappingExpanded] = useState<boolean>(true);

  const handleMappingToggle = useCallback(() => {
    setIsMappingExpanded(prev => !prev);
  }, []);

  return (
    <>
      <div className="border border-t border-b-0 border-x-0 border-solid border-gray-200 px-5 pt-[10px] pb-[24px]">
        <GroupHeader
          size="s"
          isExpanded={isMappingExpanded}
          onToggle={handleMappingToggle}
        >
          <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.title" />
        </GroupHeader>
        {isMappingExpanded && (
          <div className="px-[28px]">
            <SpreadsheetSelection
              isFetching={isFetchingSpreadsheets}
              items={spreadsheets}
              selectedKey={selectedSpreadsheetId}
              onChange={onSpreadsheetChange}
              onAddSpreadsheet={onAddSpreadsheet}
              isCreated={isCreated}
              isCreatingSpreadsheet={isCreatingSpreadsheet}
              errorMessageId={spreadsheetErrorId}
            />

            {isFetchingWorkSheets ? (
              <div className="h-[100px]">
                <ShortSpinner
                  labelId="workspace.integrations.configuration.export_googleSheets.mapping"
                  labelPosition="bottom"
                />
              </div>
            ) : (
              <>
                {isSelectedSpreadsheet ? (
                  <SheetSelection
                    isFetching={isFetchingWorkSheets}
                    items={sheets}
                    selectedKey={selectedSheetId}
                    onChange={onSheetChange}
                    isCreated={isCreated}
                    errorMessageId={sheetErrorId}
                  />
                ) : null}
                {isSelectedSheet ? (
                  isFetchingFieldMapping ? (
                    <div className="h-[100px]">
                      <ShortSpinner
                        labelId="workspace.integrations.configuration.export_googleSheets.updating"
                        labelPosition="bottom"
                      />
                    </div>
                  ) : (
                    <FieldMapping
                      fields={fields}
                      mappings={mappings}
                      onChange={onMappingChange}
                      validationResult={validationResult}
                    />
                  )
                ) : null}
                <div className="flex flex-row items-center justify-end h-[36px]">
                  {props.lastRefreshed != null && (
                    <div className="text-sm text-type-secondary">
                      <FormattedMessage
                        id="workspace.integrations.configuration.export_googleSheets.last_refreshed"
                        values={{
                          date: DateTime.fromJSDate(
                            props.lastRefreshed
                          ).toFormat("yyyy-MM-dd HH:mm"),
                        }}
                      />
                    </div>
                  )}
                  <ActionButton
                    iconProps={{ iconName: "Refresh" }}
                    textId="common.refresh"
                    onClick={onRefreshClick}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export interface GoogleAuthButtonProps {
  isLoading?: boolean;
  onClick: () => void;
}

export function GoogleAuthButton(props: GoogleAuthButtonProps) {
  const { onClick, isLoading = false } = props;

  return (
    <DefaultButton
      className="flex font-semibold color-black bg-[#f2f2f2] rounded border-0 h-10 px-3 py-2.5"
      disabled={isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <>
          <div className="w-5 h-5 mr-2.5">
            <Spinner size={SpinnerSize.medium} />
          </div>
          <span>
            <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.login_button.loading" />
          </span>
        </>
      ) : (
        <>
          <Icon iconName="IconGoogle" className="w-5 h-5 mr-2.5" />
          <span>
            <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.login_button" />
          </span>
        </>
      )}
    </DefaultButton>
  );
}

interface ExportGoogleSheetsConfigurationCardProps {
  configuration: WorkspaceExportGoogleSheetsIntegrationConfiguration & {
    isCreated?: boolean;
  };
  extractorId: string | null;
  workspaceId: string;
  oauthCredentials: OAuthCredential[];
  onConfigurationRemoved: (
    configuration: WorkspaceExportGoogleSheetsIntegrationConfiguration
  ) => void;
  onConfigurationSaved: (
    id: string,
    oauthCredentialId: string,
    spreadsheetId: string,
    spreadsheetName: string,
    sheetId: string,
    sheetName: string,
    mappings: GoogleSheetFieldMapping[],
    configuration: WorkspaceExportGoogleSheetsIntegrationConfiguration
  ) => Promise<void>;
  isCreated: boolean;
  onChanged: () => void;
}

export function useExportGoogleSheetsConfigurationCardState(
  props: ExportGoogleSheetsConfigurationCardProps
) {
  const {
    googleSheetIntegrationProps,
    extractorSchemaFields,
    listGoogleSheetColumns,
    googleAuth,
    createNewGoogleSheet,
    listGoogleSpreadsheets,
  } = useWorkspaceIntegration();

  const {
    configuration,
    oauthCredentials,
    onConfigurationRemoved,
    onConfigurationSaved,
    isCreated,
    onChanged,
  } = props;

  const { localized } = useLocale();
  const {
    isGoogleAuthorized: isAuthorized,
    isGoogleAuthorizing: isAuthorizing,
    listGoogleSheets,
  } = googleSheetIntegrationProps;

  const [isCreatingSpreadsheet, setIsCreatingSpreadsheet] = useState(false);

  const [isFetchingWorkSheets, setIsFetchingWorkSheets] =
    useState<boolean>(false);
  const [isFetchingSpreadsheets, setIsFetchingSpreadsheets] =
    useState<boolean>(false);

  const [sheetErrorId, setSheetErrorId] = useState<string | undefined>();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [lastRefreshed, setLastRefreshed] = useState<Date | null>(null);

  const [sheets, setSheets] = useState<GoogleSheet[]>([]);
  const [spreadsheets, setSpreadsheets] = useState<GoogleSpreadsheet[]>([]);

  const [mappings, setMappings] = useState<GoogleSheetFieldMapping[]>(
    configuration.mappings
  );
  const [selectedOAuthCredential, setSelectedOAuthCredential] = useState<
    string | null
  >(configuration.oauthCredentialId);
  const [selectedSheetId, setSelectedSheetId] = useState<string | null>(
    configuration.sheetId
  );
  const [selectedSpreadsheetId, setSelectedSpreadsheetId] = useState<
    string | null
  >(configuration.spreadsheetId);

  const [validationResult, setValidationResult] =
    useState<GoogleSheetFieldMappingValidationResult>({
      hasError: false,
      missingFieldAssignedColumnIndices: [],
    });

  const [spreadsheetErrorId, setSpreadsheetErrorId] = useState<
    string | undefined
  >();

  const [isFetchingFieldMapping, setIsFetchingFieldMapping] =
    useState<boolean>(false);

  const [hasDataChanged, setHasDataChanged] = useState<boolean>(false);

  const [oauthError, setOAuthError] = useState<
    OAuthError | FOCRError | CancelError | Error | null
  >(null);

  const authErrorMessage = useMemo<string | null>(() => {
    if (oauthError == null) {
      return null;
    }
    console.log(oauthError);
    if (oauthError instanceof FOCRError) {
      return localized(oauthError.messageId);
    }

    if (oauthError instanceof CancelError) {
      return localized("error.google_auth.user_cancelled");
    }

    if (oauthError instanceof Error) {
      return oauthError.message;
    }

    if ("oauthErrorId" in oauthError) {
      const { oauthErrorId } = oauthError as OAuthError;

      switch (oauthErrorId) {
        case "invalid_access_token":
          return localized("error.google_auth.invalid_access_token");
        case "invalid_state":
          return localized("error.google_auth.invalid_state");
        case "missing_code":
          return localized("error.google_auth.missing_code");
        case "missing_state_key":
          return localized("error.google_auth.missing_state_key");
        case "nonce_mismatch":
          return localized("error.google_auth.nonce_mismatch");
        case "unexpected_state_key":
          return localized("error.google_auth.unexpected_state_key");
        case "unknown":
          return localized("error.google_auth.unknown");
        case "user_cancelled":
          return localized("error.google_auth.user_cancelled");
        // Google OAuth errors
        case "access_denied":
          return localized("error.google_auth.access_denied");
        case "invalid_request":
          return localized("error.google_auth.invalid_request");
        case "invalid_scope":
          return localized("error.google_auth.invalid_scope");
        case "server_error":
          return localized("error.google_auth.server_error");
        case "temporarily_unavailable":
          return localized("error.google_auth.temporarily_unavailable");
        case "unauthorized_client":
          return localized("error.google_auth.unauthorized_client");
        case "unsupported_response_type":
          return localized("error.google_auth.unsupported_response_type");
        // Google OAuth errors (when exchanging tokens)
        case "invalid_grant":
          return localized("error.google_auth.invalid_grant");
      }
    }
    return localized("error.google_auth.unknown");
  }, [localized, oauthError]);

  const clearErrors = useCallback(() => {
    setOAuthError(null);
    setSheetErrorId(undefined);
    setSpreadsheetErrorId(undefined);
  }, []);

  const isSavable = useMemo(() => {
    return (
      selectedOAuthCredential !== null &&
      selectedSpreadsheetId != null &&
      selectedSheetId != null &&
      !validationResult.hasError &&
      hasDataChanged
    );
  }, [
    selectedOAuthCredential,
    selectedSpreadsheetId,
    selectedSheetId,
    validationResult.hasError,
    hasDataChanged,
  ]);

  const setMappingFromColumns = useCallback((columns: string[]) => {
    setMappings([
      ...columns.map((columnName, columnIndex) => ({
        columnIndex,
        columnName,
        fieldName: columnName,
      })),
    ]);
  }, []);

  const refreshSheetColumnList = useCallback(
    async (
      oauthCredential: string,
      spreadsheetId: string,
      worksheetId: string
    ) => {
      try {
        setIsFetchingFieldMapping(true);
        const columns = await listGoogleSheetColumns(
          oauthCredential,
          spreadsheetId,
          worksheetId
        );
        setMappingFromColumns(columns);
      } finally {
        setIsFetchingFieldMapping(false);
      }
    },
    [listGoogleSheetColumns, setMappingFromColumns]
  );

  const refreshWorksheetList = useCallback(
    async (oauthCredential: string, spreadsheetId: string) => {
      try {
        setIsFetchingWorkSheets(true);
        const sheets = await listGoogleSheets(oauthCredential, spreadsheetId);
        setSheets(sheets);
      } catch (error) {
        setSheetErrorId(
          error && error instanceof FOCRError
            ? error.messageId
            : typeof error === "string"
            ? error
            : "workspace.integrations.list_google_sheets_failed"
        );
        setSheets([]);
      } finally {
        setIsFetchingWorkSheets(false);
      }
    },
    [listGoogleSheets]
  );

  const refreshSpreadsheetList = useCallback(
    async (oauthCredential: string) => {
      try {
        setIsFetchingSpreadsheets(true);
        const spreadsheets = await listGoogleSpreadsheets(
          oauthCredential,
          null
        );
        setSpreadsheets(spreadsheets);
        setSpreadsheetErrorId(undefined);
      } catch (error) {
        setSpreadsheetErrorId(
          error && error instanceof FOCRError
            ? error.messageId
            : typeof error === "string"
            ? error
            : "workspace.integrations.list_google_spreadsheets_failed"
        );
        setSpreadsheets([]);
      } finally {
        setIsFetchingSpreadsheets(false);
      }
    },
    [listGoogleSpreadsheets]
  );

  const onAddSpreadsheet = useCallback(async () => {
    try {
      setIsCreatingSpreadsheet(true);
      const spreadsheet = await createNewGoogleSheet(
        selectedOAuthCredential ?? ""
      );
      setSelectedSpreadsheetId(spreadsheet.id);
      setSpreadsheets(prev => [spreadsheet, ...prev]);
      if (spreadsheet.sheets.length > 0) {
        setSelectedSheetId(spreadsheet.sheets[0].id);
        setSheets([
          {
            id: spreadsheet.sheets[0].id,
            name: spreadsheet.sheets[0].name,
          },
        ]);
        setMappingFromColumns(spreadsheet.sheets[0].columns);
      }
    } catch (error) {
      setSpreadsheetErrorId(
        error && error instanceof FOCRError
          ? error.messageId
          : typeof error === "string"
          ? error
          : "workspace.integrations.list_google_sheets_failed"
      );
    } finally {
      setIsCreatingSpreadsheet(false);
    }
  }, [createNewGoogleSheet, selectedOAuthCredential, setMappingFromColumns]);

  const onAuthClick = useCallback(async () => {
    try {
      setOAuthError(null);
      const credential = await googleAuth();
      if (credential != null) {
        setSelectedOAuthCredential(credential.id);
      }
    } catch (error) {
      // Need to handle OAuthError here. Otherwise, it won't
      // be able to clear the error message when the user
      // clicks the login button again.
      setOAuthError(error as OAuthError | FOCRError | CancelError | Error);
    }
  }, [googleAuth]);

  const onChangeOAuthCredential = useCallback(
    (key: string | number | null) => {
      setSelectedOAuthCredential(key as string | null);
      setSelectedSpreadsheetId(null);
      setSelectedSheetId(null);
      if (key !== null) {
        refreshSpreadsheetList(key as string);
        clearErrors();
      } else {
        setSpreadsheets([]);
      }
      setHasDataChanged(true);
      onChanged();
    },
    [refreshSpreadsheetList, onChanged, clearErrors]
  );

  const onChangeMapping = useCallback(
    (
      columnIndex: number,
      _event: React.FormEvent<HTMLElement>,
      option?: IDropdownOption
    ) => {
      const fieldName = option?.key.toString() ?? "";
      setMappings(mappings => {
        const newMappings = mappings.map(mapping => {
          if (columnIndex === mapping.columnIndex) {
            mapping.fieldName = fieldName;
          }
          return mapping;
        });

        if (validationResult.hasError) {
          // Don't run validation if there has no error
          // Prevent to show "empty" error message in the
          // setup phase
          const validator = new GoogleSheetFieldMappingValidator();
          const result = validator.validate(newMappings);
          setValidationResult(result);
        }
        setHasDataChanged(true);
        onChanged();
        return newMappings;
      });
    },
    [validationResult.hasError, onChanged]
  );

  const onChangeWorkSheet = useCallback(
    (key: string | number | null) => {
      setSelectedSheetId(key as string | null);

      if (key != null) {
        setMappings([]);
        refreshSheetColumnList(
          selectedOAuthCredential ?? "",
          selectedSpreadsheetId ?? "",
          key as string
        );
        clearErrors();
      }
      setLastRefreshed(null);
      setHasDataChanged(true);
      onChanged();
    },
    [
      refreshSheetColumnList,
      selectedOAuthCredential,
      selectedSpreadsheetId,
      onChanged,
      clearErrors,
    ]
  );

  const onChangeSpreadsheet = useCallback(
    (key: string | number | null) => {
      setMappings([]);
      setSheets([]);
      setSelectedSheetId(null);
      clearErrors();
      if (key === null) {
        setSelectedSpreadsheetId(null);
      } else {
        setSelectedSpreadsheetId(key as string | null);
        refreshWorksheetList(selectedOAuthCredential ?? "", key as string);
      }
      setLastRefreshed(null);
      setHasDataChanged(true);
      onChanged();
    },
    [refreshWorksheetList, selectedOAuthCredential, onChanged, clearErrors]
  );

  const onRefreshClick = useCallback(async () => {
    if (selectedSpreadsheetId == null) {
      await refreshSpreadsheetList(selectedOAuthCredential ?? "");
    } else if (selectedSheetId == null) {
      await refreshWorksheetList(
        selectedOAuthCredential ?? "",
        selectedSpreadsheetId
      );
    } else {
      await refreshSheetColumnList(
        selectedOAuthCredential ?? "",
        selectedSpreadsheetId,
        selectedSheetId
      );
      setLastRefreshed(new Date());
    }
  }, [
    refreshSpreadsheetList,
    refreshWorksheetList,
    refreshSheetColumnList,
    selectedOAuthCredential,
    selectedSpreadsheetId,
    selectedSheetId,
  ]);

  const onRemoveClick = useCallback(() => {
    onConfigurationRemoved(configuration);
  }, [configuration, onConfigurationRemoved]);

  const onSaveClick = useCallback(async () => {
    if (selectedOAuthCredential === null || selectedSpreadsheetId === null) {
      return;
    }

    const validator = new GoogleSheetFieldMappingValidator();
    const result = validator.validate(mappings);
    setValidationResult(result);
    if (result.hasError) {
      return;
    }

    const mappingsToBeSaved = isCreated ? mappings : [...mappings];

    const spreadsheet = spreadsheets.find(
      spreadsheet => spreadsheet.id === selectedSpreadsheetId
    );
    const sheet = sheets.find(sheet => sheet.id === selectedSheetId);
    if (spreadsheet == null || sheet == null) {
      return;
    }

    try {
      setIsSaving(true);
      const spreadsheetName = spreadsheet?.name ?? "";
      const sheetName = sheet?.name ?? "";
      await onConfigurationSaved(
        configuration.id,
        selectedOAuthCredential,
        selectedSpreadsheetId,
        spreadsheetName,
        selectedSheetId ?? "",
        sheetName,
        mappingsToBeSaved,
        configuration
      );
      setHasDataChanged(false);

      setSpreadsheets([spreadsheet]);
      setSheets([sheet]);
    } finally {
      setIsSaving(false);
    }
  }, [
    mappings,
    onConfigurationSaved,
    selectedOAuthCredential,
    selectedSheetId,
    selectedSpreadsheetId,
    configuration,
    isCreated,
    spreadsheets,
    sheets,
  ]);

  useOnload(() => {
    if (!isCreated) {
      return;
    }

    setSelectedSpreadsheetId(configuration.spreadsheetId);
    setSpreadsheets([
      {
        id: configuration.spreadsheetId ?? "",
        name: configuration.spreadsheetName ?? "",
      },
    ]);
    setSelectedSheetId(configuration.sheetId);
    setSheets([
      {
        id: configuration.sheetId ?? "",
        name: configuration.sheetName ?? "",
      },
    ]);
  });

  return React.useMemo(
    () => ({
      authErrorMessage,
      isAuthorizing,
      isFetchingOAuthCredentials: false,
      isFetchingWorkSheets,
      isFetchingSpreadsheets,
      isSaving,
      isSelectedAccount: selectedOAuthCredential !== null,
      isSelectedSheet: selectedSheetId !== null,
      isSelectedSpreadsheet:
        selectedSpreadsheetId !== null && selectedSpreadsheetId !== "",
      mappings,
      oauthCredentials,
      selectedOAuthCredential,
      selectedSheetId,
      selectedSpreadsheetId,
      sheets,
      spreadsheets,
      onAuthClick,
      onChangeMapping,
      onChangeOAuthCredential,
      onChangeWorkSheet,
      onChangeSpreadsheet,
      onRefreshClick,
      onRemoveClick,
      onSaveClick,
      lastRefreshed,
      isAuthorized,
      onAddSpreadsheet,
      isCreated,
      extractorSchemaFields,
      validationResult,
      isSavable,
      sheetErrorId,
      spreadsheetErrorId,
      isFetchingFieldMapping,
      hasDataChanged,
      isCreatingSpreadsheet,
    }),
    [
      authErrorMessage,
      isAuthorizing,
      isFetchingSpreadsheets,
      isFetchingWorkSheets,
      isSaving,
      mappings,
      oauthCredentials,
      onAuthClick,
      onChangeMapping,
      onChangeOAuthCredential,
      onChangeWorkSheet,
      onChangeSpreadsheet,
      onRefreshClick,
      onRemoveClick,
      onSaveClick,
      selectedOAuthCredential,
      selectedSheetId,
      selectedSpreadsheetId,
      sheets,
      spreadsheets,
      isAuthorized,
      lastRefreshed,
      onAddSpreadsheet,
      isCreated,
      extractorSchemaFields,
      validationResult,
      isSavable,
      sheetErrorId,
      spreadsheetErrorId,
      isFetchingFieldMapping,
      hasDataChanged,
      isCreatingSpreadsheet,
    ]
  );
}

export function ExportGoogleSheetsConfigurationCardImpl(
  props: ReturnType<typeof useExportGoogleSheetsConfigurationCardState>
) {
  const {
    authErrorMessage,
    isAuthorizing,
    isFetchingOAuthCredentials,
    isFetchingSpreadsheets,
    isFetchingWorkSheets,
    isSelectedAccount,
    isSelectedSheet,
    isSelectedSpreadsheet,
    mappings,
    oauthCredentials,
    onAuthClick,
    onChangeMapping,
    onChangeOAuthCredential,
    onChangeWorkSheet,
    onChangeSpreadsheet,
    onRefreshClick,
    onRemoveClick,
    onSaveClick,
    selectedOAuthCredential,
    selectedSheetId,
    selectedSpreadsheetId,
    sheets,
    spreadsheets,
    lastRefreshed,
    isCreated,
    extractorSchemaFields,
    validationResult,
    isSavable,
    isSaving,
    sheetErrorId,
    spreadsheetErrorId,
    isFetchingFieldMapping,
    isCreatingSpreadsheet,
  } = props;

  return (
    <ConfigurationCardLayout
      title={
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.title" />
      }
      onRemoveClick={onRemoveClick}
      defaultCollapsed={isCreated}
    >
      <AuthSection
        accounts={oauthCredentials}
        errorMessage={authErrorMessage}
        isAuthorizing={isAuthorizing}
        isFetching={isFetchingOAuthCredentials}
        onAccountChange={onChangeOAuthCredential}
        onAuthClick={onAuthClick}
        selectedAccountId={selectedOAuthCredential}
        isCreated={props?.isCreated}
      />
      {isSelectedAccount ? (
        <ConfigurationSection
          fields={extractorSchemaFields}
          isFetchingWorkSheets={isFetchingWorkSheets}
          isFetchingSpreadsheets={isFetchingSpreadsheets}
          isSelectedSheet={isSelectedSheet}
          isSelectedSpreadsheet={isSelectedSpreadsheet}
          mappings={mappings}
          onMappingChange={onChangeMapping}
          onRefreshClick={onRefreshClick}
          onSheetChange={onChangeWorkSheet}
          onSpreadsheetChange={onChangeSpreadsheet}
          selectedSheetId={selectedSheetId}
          selectedSpreadsheetId={selectedSpreadsheetId}
          sheets={sheets}
          spreadsheets={spreadsheets}
          lastRefreshed={lastRefreshed}
          onAddSpreadsheet={props.onAddSpreadsheet}
          isCreated={props?.isCreated ?? false}
          validationResult={validationResult}
          sheetErrorId={sheetErrorId}
          spreadsheetErrorId={spreadsheetErrorId}
          isFetchingFieldMapping={isFetchingFieldMapping}
          isCreatingSpreadsheet={isCreatingSpreadsheet}
        />
      ) : null}
      {isSelectedAccount ? (
        <div className="pt-[16px] border-solid border-gray-200 border-t border-x-0 border-b-0">
          <PrimaryLoadingButton
            className="block ml-auto"
            disabled={!isSavable}
            isLoading={isSaving}
            onClick={onSaveClick}
          >
            <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.save" />
          </PrimaryLoadingButton>
        </div>
      ) : null}
    </ConfigurationCardLayout>
  );
}

export function ExportGoogleSheetsConfigurationCard(
  props: ExportGoogleSheetsConfigurationCardProps
) {
  const states = useExportGoogleSheetsConfigurationCardState(props);
  return <ExportGoogleSheetsConfigurationCardImpl {...states} />;
}
