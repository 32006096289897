import { Checkbox, TextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import * as React from "react";

import { JSON_INDENTATION } from "../../constants/format";
import {
  ExtractCheckboxData,
  ExtractCurrencyData,
  ExtractDataFieldType,
  ExtractImageListData,
} from "../../types/extractDataField";
import { CroppedImage } from "../CroppedImage";
import {
  ExtractTableDataField,
  ExtractTableDataType,
} from "../ExtractTableDataField";
import { SmallIconButton } from "../SmallIconButton";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  label: string;
  value: any;
  type: ExtractDataFieldType;
  isEditable?: boolean;
  isMultiline?: boolean;
  isList?: boolean;
  actions?: React.ReactNode;
  onChange?: (value: any) => void;
}

function RawValue({ value }: { value: any }) {
  if (value === null) {
    return <></>;
  }

  return (
    <pre className={styles["raw-json-viewer"]}>
      {JSON.stringify(value, null, JSON_INDENTATION)}
    </pre>
  );
}

function ExtractBasicDataField(props: Props) {
  const { label, value, onChange, actions } = props;
  const isEditable = props.isEditable ?? false;
  const isMultiline = props.isMultiline ?? false;

  const _onChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      onChange?.(newValue);
    },
    [onChange]
  );

  return (
    <div className={styles["data-item"]}>
      <div className={styles["data-item-label"]}>{label}</div>
      {isEditable ? (
        <TextField value={value} onChange={_onChange} multiline={isMultiline} />
      ) : (
        <div className={styles["data-item-value-container"]}>
          <div className={styles["data-item-value"]}>
            {typeof value === "string" ? value : <RawValue value={value} />}
          </div>
          <div className={styles["data-item-value-actions"]}>{actions}</div>
        </div>
      )}
    </div>
  );
}

function ExtractListDataField(props: Props) {
  const { label, value, onChange, actions } = props;
  const isMultiline = props.isMultiline ?? false;

  const listValue = React.useMemo(() => {
    return Array.isArray(value) ? value : [value];
  }, [value]);

  const addNewItem = React.useCallback(() => {
    const newValue = Array.isArray(listValue)
      ? [...listValue, ""]
      : [listValue, ""];
    onChange?.(newValue);
  }, [listValue, onChange]);

  const removeItem = React.useCallback(
    (index: number) => {
      const newValue = [...listValue];
      newValue.splice(index, 1);
      onChange?.(newValue);
    },
    [listValue, onChange]
  );

  const updateItem = React.useCallback(
    (index: number, newValue: string) => {
      const newValueList = [...listValue];
      newValueList[index] = newValue;
      onChange?.(newValueList);
    },
    [listValue, onChange]
  );

  const isEditable = props.isEditable ?? false;
  const content = isEditable ? (
    <div className={styles["data-item-editing-list-value"]}>
      {listValue.map((item, index) => {
        return (
          <div
            className={styles["data-item-editing-list-value-item"]}
            key={`list-value/${index}`}
          >
            <TextField
              value={item}
              multiline={isMultiline}
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                updateItem(index, newValue ?? "");
              }}
            />
            <SmallIconButton
              iconName="IconTrash"
              color="#C4141F"
              onClick={() => {
                removeItem(index);
              }}
            />
          </div>
        );
      })}
      <ActionButton
        className={styles["content-list-value-field-add-button"]}
        iconName="Add"
        textId="extract_data_field.new_list_item"
        onClick={addNewItem}
      />
    </div>
  ) : (
    <div className={styles["data-item-list-value"]}>
      {listValue.map((item, index) => {
        return (
          <React.Fragment key={`list-value/${index}`}>
            <div className={styles["data-item-list-value-row-bullet"]}></div>
            <div className={styles["data-item-list-value-row-content"]}>
              {typeof item === "string" ? item : <RawValue value={item} />}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );

  return (
    <div className={styles["data-item"]}>
      <div className={styles["data-item-label"]}>{label}</div>
      <div className={styles["data-item-list-value-container"]}>
        {content}
        <div className={styles["data-item-list-value-actions"]}>{actions}</div>
      </div>
    </div>
  );
}

function ExtractImageListDataField(props: Props) {
  const { label, value } = props;

  const listImage = value as ExtractImageListData;

  let content = null;

  try {
    content = listImage.map((item, index) => {
      const {
        imageUrl,
        boundingBox,
        value,
        onToggleBoundingBox,
        isToggleBoundingBoxOn,
      } = item;

      return (
        <div
          key={`list-image/${index}`}
          className={styles["data-item-value-image-row"]}
        >
          <div className={styles["data-item-value-image"]}>
            <CroppedImage src={imageUrl} boundingBox={boundingBox} />
          </div>
          <div className={styles["data-item-value"]}>
            {typeof value === "string" ? value : <RawValue value={value} />}
          </div>
          {onToggleBoundingBox && (
            <div>
              <SmallIconButton
                iconName={isToggleBoundingBoxOn ? "View" : "Hide"}
                onClick={onToggleBoundingBox}
              />
            </div>
          )}
        </div>
      );
    });
  } catch (e) {}

  return (
    <div className={styles["data-item"]}>
      <div className={styles["data-item-label"]}>{label}</div>
      <div className={styles["data-item-value-container"]}>
        <div className={styles["data-item-value-image-list"]}>{content}</div>
      </div>
    </div>
  );
}

function ExtractCurrencyDataField(props: Props) {
  const { label, value, onChange, actions, isList } = props;
  const isEditable = props.isEditable ?? false;

  const isEmpty = React.useMemo(() => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }

    if (
      (value.currency == null || value.currency === "") &&
      (value.value == null || value.value === "")
    ) {
      return true;
    }

    return false;
  }, [value]);

  const items = React.useMemo(() => {
    if (isEmpty && !isList) {
      return [
        {
          currency: "",
          value: undefined,
        },
      ];
    }

    if (Array.isArray(value)) {
      if (isList) {
        return value;
      }
      return value.slice(0, 1);
    }
    return [value];
  }, [value, isList, isEmpty]);

  const viewableItems = React.useMemo<ExtractCurrencyData[]>(() => {
    if (isEmpty) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }
    return [value];
  }, [value, isEmpty]);

  const removeItem = React.useCallback(
    (index: number) => {
      if (isList) {
        const newValue = [...items];
        newValue.splice(index, 1);
        onChange?.(newValue);
      }
    },
    [items, onChange, isList]
  );

  const updateCurrency = React.useCallback(
    (index: number, newValue: string | undefined) => {
      if (newValue !== undefined) {
        if (isList) {
          const newItems = [...items];
          newItems[index].currency = newValue;
          onChange?.(newItems);
        } else {
          const newItem = { ...items[0] };
          newItem.currency = newValue;
          onChange?.(newItem);
        }
      }
    },
    [items, onChange, isList]
  );

  const addNewItem = React.useCallback(() => {
    if (isList) {
      const newItems = [...items, { currency: "" }];
      onChange?.(newItems);
    }
  }, [isList, onChange, items]);

  const updateValue = React.useCallback(
    (index: number, newValue: string | undefined) => {
      if (newValue !== undefined) {
        const parsedValue = isNaN(parseFloat(newValue))
          ? newValue
          : parseFloat(newValue);
        if (isList) {
          const newItems = [...items];
          newItems[index].value = parsedValue;
          onChange?.(newItems);
        } else {
          const newItem = { ...items[0] };
          newItem.value = parsedValue;
          onChange?.(newItem);
        }
      }
    },
    [items, onChange, isList]
  );

  const headerClasses = cntl`
  min-w-[100px] 
  text-[#323130] 
  opacity-50 
  `;

  return (
    <div className={styles["data-item"]}>
      <div className={styles["data-item-label"]}>{label}</div>
      <div className={styles["data-item-value-container"]}>
        <div className={styles["data-item-value"]}>
          <div
            className={cntl`grid nowrap gap-[10px_8px] grid-cols-[min-content_1fr] mb-[8px]`}
          >
            {(isEditable || !isEmpty) && (
              <>
                <div className={headerClasses}>
                  <FormattedMessage id="extract_data_field.currency" />
                </div>
                <div className={headerClasses}>
                  <FormattedMessage id="extract_data_field.amount" />
                </div>
              </>
            )}
            {isEditable && (
              <>
                {items.map((item: ExtractCurrencyData, index: number) => (
                  <React.Fragment key={`currency/${index}`}>
                    <div>
                      <TextField
                        value={item.currency ?? ""}
                        onChange={(_event, newValue) => {
                          updateCurrency(index, newValue);
                        }}
                      />
                    </div>
                    <div className="flex">
                      <TextField
                        value={item.value?.toString() ?? ""}
                        onChange={(_event, newValue) => {
                          updateValue(index, newValue);
                        }}
                      />
                      {isList && (
                        <SmallIconButton
                          iconName="IconTrash"
                          color="#C4141F"
                          onClick={() => {
                            removeItem(index);
                          }}
                        />
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </>
            )}
            {!isEmpty && !isEditable && (
              <>
                {viewableItems.map(
                  (item: ExtractCurrencyData, index: number) => (
                    <React.Fragment key={`currency/${index}`}>
                      <div>{item.currency}</div>
                      <div>{item.value}</div>
                    </React.Fragment>
                  )
                )}
              </>
            )}
          </div>

          {isList && isEditable && (
            <ActionButton
              className={styles["content-list-value-field-add-button"]}
              iconName="Add"
              textId="extract_data_field.new_list_item"
              onClick={addNewItem}
            />
          )}
        </div>
        <div className={styles["data-item-value-actions"]}>{actions}</div>
      </div>
    </div>
  );
}

function ExtractCheckboxDataField(props: Props) {
  const { label, onChange, actions } = props;
  const isEditable = props.isEditable ?? false;

  const value = React.useMemo<ExtractCheckboxData[]>(() => {
    if (Array.isArray(props.value)) {
      return props.value;
    }
    return [];
  }, [props.value]);

  const addNewItem = React.useCallback(() => {
    const newItems = [...value, { label: "", checked: false }];
    onChange?.(newItems);
  }, [value, onChange]);

  const updateLabel = React.useCallback(
    (index: number, newValue: string | undefined) => {
      if (newValue !== undefined) {
        const newItems = [...value];
        newItems[index].label = newValue;
        onChange?.(newItems);
      }
    },
    [value, onChange]
  );

  const removeItem = React.useCallback(
    (index: number) => {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange?.(newValue);
    },
    [value, onChange]
  );

  const updateChecked = React.useCallback(
    (index: number, newValue: boolean | undefined) => {
      if (newValue !== undefined) {
        const newItems = [...value];
        newItems[index].checked = newValue;
        onChange?.(newItems);
      }
    },
    [value, onChange]
  );

  return (
    <div className={styles["data-item"]}>
      <div className={styles["data-item-label"]}>{label}</div>
      <div className={styles["data-item-value-container"]}>
        <div className={styles["data-item-value"]}>
          {isEditable ? (
            <React.Fragment>
              <div className={cntl`mb-[8px] text-[#323130] opacity-50`}>
                <FormattedMessage id="extract_data_field.checkbox.label" />
              </div>
              {value.map((item, index) => (
                <div key={`checkbox/${index}`}>
                  <div className="flex mb-2 items-center">
                    <TextField
                      value={item.label}
                      onChange={(_event, newValue) => {
                        updateLabel(index, newValue);
                      }}
                    />
                    <Checkbox
                      className="ml-2"
                      checked={item.checked}
                      onChange={(_event, newValue) => {
                        updateChecked(index, newValue);
                      }}
                    />
                    <SmallIconButton
                      iconName="IconTrash"
                      color="#C4141F"
                      onClick={() => {
                        removeItem(index);
                      }}
                    />
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : (
            <div
              className={cntl`grid nowrap gap-[10px_8px] grid-cols-[2fr_1fr] mb-[8px] gap-x-8`}
            >
              <div className={cntl`text-[#323130] opacity-50`}>
                <FormattedMessage id="extract_data_field.checkbox.label" />
              </div>
              <div>
                <div className={cntl`w-[60px] text-[#323130] opacity-50`}>
                  <FormattedMessage id="extract_data_field.checkbox.checked" />
                </div>
              </div>
              {value.map((item, index) => (
                <React.Fragment key={`checkbox/${index}`}>
                  <div className={cntl`break-keep`}>{item.label}</div>
                  <div>
                    <div className={cntl`w-[60px] flex justify-center`}>
                      <Checkbox
                        checked={item.checked}
                        className="mb-2"
                        styles={{
                          text: { color: "#201f1e" },
                        }}
                        disabled
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
          {isEditable && (
            <ActionButton
              className={styles["content-list-value-field-add-button"]}
              iconName="Add"
              textId="extract_data_field.new_list_item"
              onClick={addNewItem}
            />
          )}
        </div>
        <div className={styles["data-item-value-actions"]}>{actions}</div>
      </div>
    </div>
  );
}

export function ExtractDataField(props: Props) {
  const { type } = props;

  switch (type) {
    case ExtractDataFieldType.Basic:
      return <ExtractBasicDataField {...props} />;
    case ExtractDataFieldType.List:
      return <ExtractListDataField {...props} />;
    case ExtractDataFieldType.ListTable:
      return (
        <ExtractTableDataField
          {...props}
          type={ExtractTableDataType.ListTable}
        />
      );
    case ExtractDataFieldType.HTMLTable:
      return (
        <ExtractTableDataField
          {...props}
          type={ExtractTableDataType.HTMLTable}
        />
      );
    case ExtractDataFieldType.ListImage:
      return <ExtractImageListDataField {...props} />;
    case ExtractDataFieldType.Currency:
      return <ExtractCurrencyDataField {...props} />;
    case ExtractDataFieldType.Checkbox:
      return <ExtractCheckboxDataField {...props} />;
    default:
      return <ExtractBasicDataField {...props} />;
  }
}
