import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useMicrosoftAuthActionCreator } from "../actions/microsoftAuth";
import { useWorkspaceIntegrationActionCreator } from "../actions/workspaceIntegration";
import { apiClient } from "../apiClient";
import errors, { FOCRError } from "../errors";
import { RootState } from "../redux/types";
import {
  DetailedGoogleSpreadsheet,
  GoogleSheet,
  GoogleSheetCreateParams,
  type GoogleSheetFieldMapping,
  type WorkspaceGoogleSheetExport,
  WorkspaceGoogleSheetExportMapper,
} from "../types/googleSheet";
import {
  FilePickerSharePointFile,
  WorkspaceSharePointIntegrationConfiguration,
} from "../types/workspaceIntegration";
import { toast } from "../utils/toast";
import { useGoogleSheet } from "./googleSheet";
import { useGoogleAuth, type useGoogleAuthReturnValues } from "./google_auth";
import { useToast } from "./toast";

export interface useGoogleSheetIntegrationReturnValues
  extends useGoogleAuthReturnValues {
  isFetchingGoogleWorkSheets: boolean;
  createNewGoogleSheet: (
    oauthCredentialId: string,
    spreadsheetName: string,
    sheets: GoogleSheetCreateParams[]
  ) => Promise<DetailedGoogleSpreadsheet>;
  listGoogleSheets: (
    oauthCredentialId: string,
    spreadsheetId: string
  ) => Promise<GoogleSheet[]>;
}

export function useGoogleSheetIntegration(): useGoogleSheetIntegrationReturnValues {
  const {
    googleAuthError,
    isGoogleAuthorized,
    isGoogleAuthorizing,
    googleAuth,
    resetGoogleAuth,
  } = useGoogleAuth();

  const { createNewGoogleSheet, listGoogleSheets } = useGoogleSheet();

  return React.useMemo(
    () => ({
      googleAuthError,
      isFetchingGoogleWorkSheets: false,
      isGoogleAuthorized,
      isGoogleAuthorizing,
      googleAuth,
      resetGoogleAuth,
      createNewGoogleSheet,
      listGoogleSheets,
    }),
    [
      googleAuthError,
      isGoogleAuthorized,
      isGoogleAuthorizing,
      googleAuth,
      resetGoogleAuth,
      createNewGoogleSheet,
      listGoogleSheets,
    ]
  );
}

export function useGoogleSheetExportListResource() {
  const [googleSheetExportList, setGoogleSheetExportList] = React.useState<
    WorkspaceGoogleSheetExport[]
  >([]);

  const queryGoogleSheetExportList = React.useCallback(
    async (workspaceId: string): Promise<WorkspaceGoogleSheetExport[]> => {
      try {
        const result = await apiClient.listWorkspaceGoogleSheetExports(
          workspaceId
        );
        setGoogleSheetExportList(result);
        return result;
      } catch (error) {
        toast.error(
          "workspace.integrations.toast.unable_to_read_google_sheets_export"
        );
        setGoogleSheetExportList([]);
        return [];
      }
    },
    []
  );

  const createWorkspaceGoogleSheetExport = React.useCallback(
    async (
      worksapceId: string,
      oauthCredentialId: string,
      spreadsheetId: string,
      spreadsheetName: string,
      sheetId: string,
      sheetName: string,
      mappings: GoogleSheetFieldMapping[]
    ): Promise<WorkspaceGoogleSheetExport> => {
      const response = await apiClient.createWorkspaceGoogleSheetExport(
        worksapceId,
        oauthCredentialId,
        spreadsheetId,
        spreadsheetName,
        sheetId,
        sheetName,
        {
          mappings,
        }
      );
      return response;
    },
    []
  );

  const deleteGoogleSheetExport = React.useCallback(
    async (workspaceGoogleSheetExportId: string) => {
      await apiClient.deleteWorkspaceGoogleSheetExport(
        workspaceGoogleSheetExportId
      );
      setGoogleSheetExportList(prev =>
        prev.filter(
          exportItem => exportItem.id !== workspaceGoogleSheetExportId
        )
      );
    },
    []
  );

  const updateGoogleSheetExport = React.useCallback(
    async (
      workspaceGoogleSheetExportId: string,
      mappings: GoogleSheetFieldMapping[]
    ): Promise<WorkspaceGoogleSheetExport> => {
      const result = await apiClient.updateWorkspaceGoogleSheetExport(
        workspaceGoogleSheetExportId,
        mappings
      );
      return WorkspaceGoogleSheetExportMapper.fromStorage(result);
    },
    []
  );

  return React.useMemo(
    () => ({
      googleSheetExportList,
      queryGoogleSheetExportList,
      deleteGoogleSheetExport,
      createWorkspaceGoogleSheetExport,
      updateGoogleSheetExport,
    }),
    [
      googleSheetExportList,
      queryGoogleSheetExportList,
      deleteGoogleSheetExport,
      createWorkspaceGoogleSheetExport,
      updateGoogleSheetExport,
    ]
  );
}

export interface useSharePointIntegrationReturnValues {
  isLoggingInToSharePoint: boolean;
  isLoggedInToSharePoint: boolean;
  onLoginSharePoint: (siteUrl: string) => Promise<void>;
  onSharePointSubscriptionSave: (
    workspaceId: string,
    siteUrl: string,
    selectedFolder: FilePickerSharePointFile
  ) => Promise<void>;
  onSharePointSubscriptionDisconnect: () => void;
  onSharePointIntegrationRemove: () => void;
  integrationConfigs: WorkspaceSharePointIntegrationConfiguration;
}

export function useSharePointIntegration(): useSharePointIntegrationReturnValues {
  const { login } = useMicrosoftAuthActionCreator();
  const { isLoggingIn, tokens } = useSelector(
    (state: RootState) => state.microsoftAuth
  );
  const { createWorkspaceSharePointSubscription } =
    useWorkspaceIntegrationActionCreator();
  const toast = useToast();

  // TODO - replaced by real integration configs
  const [dummyIntegrationConfigs] =
    React.useState<WorkspaceSharePointIntegrationConfiguration>({
      id: "default-id", // TODO: Update the ID after created the record on the server side
      optionType: "import_sharepoint",
      sharePointSubscription: false,
      siteUrlSubdomain: "",
    });

  // TODO: Move create subscription to redux action
  const onSharePointSubscriptionSave: useSharePointIntegrationReturnValues["onSharePointSubscriptionSave"] =
    React.useCallback(
      async (workspaceId, siteUrl, selectedFolder) => {
        try {
          if (tokens?.refreshToken == null) {
            throw new Error("Failed to get refresh token");
          }
          await createWorkspaceSharePointSubscription({
            workspaceId,
            refreshToken: tokens.refreshToken,
            siteUrl,
            listId: selectedFolder.sharepointIds.listId,
            listItemId: selectedFolder.sharepointIds.listItemId,
          });
        } catch (e: unknown) {
          if (e instanceof FOCRError && e !== errors.UnknownError) {
            toast.error(e.messageId);
          } else {
            toast.error(
              "error.create_workspace_sharepoint_subscription.failed"
            );
          }
        }
      },
      [createWorkspaceSharePointSubscription, toast, tokens?.refreshToken]
    );

  const integrationConfigs = React.useMemo(
    () => dummyIntegrationConfigs,
    [dummyIntegrationConfigs]
  );

  const onSharePointSubscriptionDisconnect = useCallback(() => {
    alert("not implemented");
  }, []);

  const onSharePointIntegrationRemove = useCallback(() => {
    alert("not implemented");
  }, []);

  return React.useMemo(
    () => ({
      isLoggingInToSharePoint: isLoggingIn,
      isLoggedInToSharePoint: tokens != null,
      onLoginSharePoint: login,
      onSharePointSubscriptionSave,
      onSharePointSubscriptionDisconnect,
      onSharePointIntegrationRemove,
      integrationConfigs,
    }),
    [
      isLoggingIn,
      tokens,
      login,
      onSharePointSubscriptionSave,
      onSharePointSubscriptionDisconnect,
      onSharePointIntegrationRemove,
      integrationConfigs,
    ]
  );
}
