import {
  IButtonProps,
  ITeachingBubbleStyles,
  TeachingBubbleContent,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { v4 as uuid } from "uuid";

import {
  FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT,
  UserFeatureFlag,
} from "../../constants";
import { TemplatedInstantModelExtractorDefinition } from "../../constants/templatedInstantModelExtractor";
import { useLocale } from "../../contexts/locale";
import { useExtractTest, useExtractTestV2 } from "../../hooks/extract_test";
import { useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/types";
import { UploadFileKeeperService } from "../../services/uploadFileKeeper";
import { CustomModel } from "../../types/customModel";
import { ProcessingMode } from "../../types/processingMode";
import { imageURLToFile } from "../../utils/image";
import { ExtractorTestPanel } from "../ExtractorTestPanel";
import { FSLActiveModelPicker } from "../FSLActiveModelPicker";
import { FSLSchemaNotSetWarning } from "../FSLSchemaNotSetWarning";
import { FSLSplitView } from "../FSLSplitView";
import LoadingModal from "../LoadingModal";
import OCRTest from "../OCRTest";
import styles from "./styles.module.scss";

// FIXME: remove this flag for new onboarding flow design. ref FORMX-3605
const shouldShowGuidance = false;

export enum State {
  SchemaNotSetState,
  ReadyToTestState,
}

export function useFSLOCRTest(customModel: CustomModel, useV1API?: boolean) {
  const state =
    customModel.config.extractedContentSchema !== undefined
      ? State.ReadyToTestState
      : State.SchemaNotSetState;

  const isFeatureEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()
  );

  const { currentForm: underlyingForm } = useSelector(
    (state: RootState) => state.form
  );

  const hasTransformResponseScript =
    customModel?.formID === underlyingForm?.id
      ? !!underlyingForm.config.transform_response_script?.trim()
      : false;

  const multipleDocumentsPerPage =
    customModel?.formID === underlyingForm?.id
      ? underlyingForm.config.processing_mode ===
        ProcessingMode.MultipleDocumentsPerPage
      : false;

  const useCurrentPageAsSample =
    customModel?.formID === underlyingForm?.id
      ? underlyingForm.config.processing_mode !== ProcessingMode.PerFile
      : false;

  const v2Handle = useExtractTestV2({
    extractorId: customModel?.formID ?? undefined,
    options: {
      shouldOutputLLMPrompt: isFeatureEnabled(UserFeatureFlag.InspectPrompt),
      shouldOutputOcr: true,
      shouldOutputOrientation: true,
    },
    hasTransformResponseScript,
    multipleDocumentsPerPage,
  });

  const v1Handle = useExtractTest(customModel?.formID ?? undefined);

  const { extractIfQueryKeySet } = v2Handle;

  const documentTypeForSample = customModel.config.documentTypeForSample;

  const sampleImages = React.useMemo(() => {
    return (
      TemplatedInstantModelExtractorDefinition[
        documentTypeForSample as keyof typeof TemplatedInstantModelExtractorDefinition
      ]?.sampleImages ?? []
    );
  }, [documentTypeForSample]);

  React.useEffect(() => {
    if (!useV1API) {
      extractIfQueryKeySet({
        extractorId: customModel.formID ?? customModel.id,
        extractorType: "custom",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOnboarding = React.useMemo(
    () =>
      customModel.noOfSampleImages < FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT &&
      !useV1API,
    [customModel.noOfSampleImages, useV1API]
  );

  return React.useMemo(
    () => ({
      v1Handle,
      v2Handle,
      customModel,
      state,
      useV1API: useV1API ?? false,
      hasTransformResponseScript,
      useCurrentPageAsSample,
      sampleImages,
      isOnboarding,
    }),
    [
      v1Handle,
      v2Handle,
      customModel,
      state,
      useV1API,
      hasTransformResponseScript,
      useCurrentPageAsSample,
      sampleImages,
      isOnboarding,
    ]
  );
}

interface ReviewResultGuidanceBubbleProps {
  customModel: CustomModel;
  useCurrentPageAsSample: boolean;
  lastUploadedFileRef: React.RefObject<File | null>;
  currentImageUri: string | undefined;
}

function ReviewResultGuidanceBubble(props: ReviewResultGuidanceBubbleProps) {
  const {
    customModel,
    useCurrentPageAsSample,
    lastUploadedFileRef,
    currentImageUri,
  } = props;
  const { localized } = useLocale();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = React.useState(true);

  const addLastUploadedFileAsSample = React.useCallback(async () => {
    if (lastUploadedFileRef.current == null || currentImageUri == null) {
      return;
    }

    let file = lastUploadedFileRef.current;
    if (useCurrentPageAsSample) {
      const dotIndex = file.name.lastIndexOf(".");
      const basename = file.name.slice(
        0,
        dotIndex === -1 ? undefined : dotIndex
      );
      file = await imageURLToFile(currentImageUri, basename);
    }

    const key = uuid().slice(0, 8);
    UploadFileKeeperService.getInstance().setFile(key, file);
    const queryItems = { file: key };
    const queryString = new URLSearchParams(queryItems).toString();

    navigate(`/custom-model/${customModel.id}/instant-model?${queryString}`);
  }, [
    lastUploadedFileRef,
    currentImageUri,
    useCurrentPageAsSample,
    navigate,
    customModel.id,
  ]);

  const primaryButtonProps = React.useMemo<IButtonProps>(
    () => ({
      children: (
        <FormattedMessage id="fsl_custom_model.test_extractor.guidance.review_result.add_as_sample" />
      ),
      onClick: () => addLastUploadedFileAsSample(),
    }),
    [addLastUploadedFileAsSample]
  );

  const secondaryButtonProps = React.useMemo<IButtonProps>(
    () => ({
      children: (
        <FormattedMessage id="fsl_custom_model.test_extractor.guidance.review_result.dismiss" />
      ),
      onClick: () => setIsVisible(false),
    }),
    []
  );

  const styles = React.useMemo<Partial<ITeachingBubbleStyles>>(
    () => ({
      bodyContent: "bg-primary-500 shadow-elevation16",
      secondaryButton: "text-white hover:text-white active:text-white",
    }),
    []
  );

  return isVisible ? (
    <div className="fixed right-6 bottom-6">
      <TeachingBubbleContent
        styles={styles}
        headline={localized(
          "fsl_custom_model.test_extractor.guidance.review_result.headline"
        )}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonProps={secondaryButtonProps}
      >
        <FormattedMessage id="fsl_custom_model.test_extractor.guidance.review_result.body" />
      </TeachingBubbleContent>
    </div>
  ) : null;
}

export function FSLOCRTestImpl(props: ReturnType<typeof useFSLOCRTest>) {
  const {
    v1Handle,
    v2Handle,
    useCurrentPageAsSample,
    useV1API,
    state,
    customModel,
    sampleImages,
    isOnboarding,
  } = props;

  const isRecognizing = useV1API
    ? v1Handle.isRecognizing
    : v2Handle.isRecognizing;
  const isUploading = useV1API ? v1Handle.isUploading : v2Handle.isUploading;
  const isStandardModelAvailable =
    state !== State.SchemaNotSetState && customModel.modelVersions.length > 0;
  const isExtracted =
    !isUploading &&
    !isRecognizing &&
    (useV1API ? v1Handle.testReport != null : v2Handle.extractResult != null);

  return (
    <>
      <FSLSplitView
        left={
          <>
            {state === State.SchemaNotSetState ? (
              <div className={styles["schema-not-set-warning"]}>
                <FSLSchemaNotSetWarning />{" "}
              </div>
            ) : (
              <div className={styles["ocrtest"]}>
                {useV1API ? (
                  <OCRTest
                    onSelectImage={v1Handle.extractWithFile}
                    onSelectSampleImage={v1Handle.extractWithURL}
                    extractionMode={v1Handle.extractionMode}
                    onChangeExtractionModeClicked={() => {}}
                    changeExtractionModeVisible={false}
                    ocrTestReport={v1Handle.testReport}
                    resourceName={customModel?.name ?? ""}
                  />
                ) : (
                  <ExtractorTestPanel
                    onSelectImage={v2Handle.extractWithFile}
                    onSelectSampleImage={v2Handle.extractWithURL}
                    extractResult={v2Handle.extractResult}
                    extractImageSource={v2Handle.extractImageSource}
                    resourceName={customModel?.name ?? ""}
                    disableExtractTableDataBottomSheet={true}
                    hasTransformResponseScript={
                      props.hasTransformResponseScript
                    }
                    samples={sampleImages}
                    selectedResultIndex={v2Handle.selectedResultIndex}
                    setSelectedResultIndex={v2Handle.setSelectedResultIndex}
                    // Disable the bottom sheet and hold it inside the FSLCustomModelTest container
                  />
                )}
              </div>
            )}
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
            {shouldShowGuidance && isOnboarding && isExtracted ? (
              <ReviewResultGuidanceBubble
                customModel={customModel}
                useCurrentPageAsSample={useCurrentPageAsSample}
                lastUploadedFileRef={v2Handle.lastUploadedFileRef}
                currentImageUri={
                  v2Handle.extractImageSource.uris[v2Handle.selectedResultIndex]
                }
              />
            ) : null}
          </>
        }
        right={isStandardModelAvailable && <FSLActiveModelPicker />}
        rightVisible={isStandardModelAvailable}
        isDividerVisible={true}
        rightMaxWidth={284}
        rightMinWidth={284}
      />
    </>
  );
}

interface FSLOCRTestProps {
  customModel: CustomModel;
  useV1API?: boolean;
}

export function FSLOCRTest(props: FSLOCRTestProps) {
  const { customModel, useV1API } = props;
  const states = useFSLOCRTest(customModel, useV1API);
  return <FSLOCRTestImpl {...states} />;
}
