import * as yup from "yup";

import { ResourceOwnerSetting, resourceOwnerSchema } from "./resourceOwner";
import { briefTeamSchema, teamPermissionSchema } from "./team";

export const userSchema = yup
  .object({
    id: yup.string().required(),
    username: yup.string().defined().nullable(),
    email: yup.string().required(),
    teams: yup.array(briefTeamSchema).defined(),
    permissions: yup.array(teamPermissionSchema).defined(),
    resourceOwner: resourceOwnerSchema.nullable(),
    isAdmin: yup.boolean().defined(),
  })
  .camelCase();

export type User = yup.InferType<typeof userSchema>;
export type UserSetting = ResourceOwnerSetting;
