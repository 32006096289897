import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { ExtractorTestPanel } from "../components/ExtractorTestPanel";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { UserFeatureFlag } from "../constants";
import {
  PrebuiltExtractorDefinition,
  isPrebuiltExtractors,
} from "../constants/prebuiltExtractor";
import HeaderContainer from "../containers/Header";
import { useOnload } from "../hooks/asyncguard/asyncguard";
import { useExtractTestV2 } from "../hooks/extract_test";
import { useCommonFormContainerState } from "../hooks/form";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { AutoExtractionItem } from "../types/formConfig";
import { ProcessingMode } from "../types/processingMode";
import { FormNavBarLayout, FormNavTabKey } from "./FormNavBarLayout";

type PathParam = {
  formId: string;
};

export const FormTestContainer = React.memo(() => {
  const { formId } = useUnsafeParams<PathParam>();
  const isFeatureEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()
  );

  const containerState = useCommonFormContainerState(formId);
  const prebuiltExtractorDefinition =
    containerState.state === "success" &&
    containerState.form.config.prebuilt_extractor &&
    isPrebuiltExtractors(containerState.form.config.prebuilt_extractor)
      ? PrebuiltExtractorDefinition[
          containerState.form.config.prebuilt_extractor
        ]
      : undefined;

  const hasTransformResponseScript =
    containerState.state === "success"
      ? !!containerState.form.config.transform_response_script?.trim()
      : false;

  const multipleDocumentsPerPage =
    containerState.state === "success"
      ? containerState.form.config.processing_mode ===
        ProcessingMode.MultipleDocumentsPerPage
      : false;

  const {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    extractResult,
    setExtractResult,
    extractImageSource,
    extractIfFileKeySet,
    selectedResultIndex,
    setSelectedResultIndex,
  } = useExtractTestV2(
    containerState.state === "success"
      ? {
          extractorId: containerState.form.id,
          options: {
            shouldOutputLLMPrompt:
              isFeatureEnabled(UserFeatureFlag.InspectPrompt) &&
              (prebuiltExtractorDefinition?.isUsingLLM ||
                (["llm_completion", "checkbox"] as AutoExtractionItem[]).some(
                  item =>
                    containerState.form.config.auto_extraction_items?.includes(
                      item
                    )
                )),
            shouldOutputOcr: true,
            shouldOutputOrientation: true,
          },
          hasTransformResponseScript,
          multipleDocumentsPerPage,
        }
      : {
          extractorId: undefined,
          hasTransformResponseScript,
          multipleDocumentsPerPage,
        }
  );

  useOnload(() => {
    extractIfFileKeySet();
  });

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form" />
        ) : containerState.state === "success" ? (
          <>
            <FormNavBarLayout
              selectedTab={FormNavTabKey.TestExtractor}
              form={containerState.form}
            >
              <ExtractorTestPanel
                resourceName={containerState.form.name}
                samples={
                  prebuiltExtractorDefinition &&
                  prebuiltExtractorDefinition.sampleImages
                }
                onSelectImage={extractWithFile}
                onSelectSampleImage={extractWithURL}
                extractImageSource={extractImageSource}
                extractResult={extractResult}
                setExtractResult={setExtractResult}
                shouldDetectExtractItemImage={true}
                hasTransformResponseScript={hasTransformResponseScript}
                selectedResultIndex={selectedResultIndex}
                setSelectedResultIndex={setSelectedResultIndex}
              />
            </FormNavBarLayout>
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        ) : null}
      </Main>
    </Layout>
  );
});

export default FormTestContainer;
