import { Dropdown, IDropdownOption } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";
import { useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import { GoogleSheetFieldMapping } from "../../types/googleSheet";
import { GoogleSheetFieldMappingValidationResult } from "../../validators/googleSheet";

interface FieldMappingProps {
  fields: string[];
  mappings: GoogleSheetFieldMapping[];
  onChange: (
    columnIndex: number,
    event: React.FormEvent<HTMLElement>,
    option?: IDropdownOption
  ) => void;
  validationResult: GoogleSheetFieldMappingValidationResult;
}

export function FieldMapping(props: FieldMappingProps) {
  const { fields, mappings, onChange, validationResult } = props;

  const { localized } = useLocale();

  const fieldOptions = useMemo<IDropdownOption[]>(() => {
    return [
      {
        key: "",
        text: localized(
          "workspace.integrations.configuration.export_googleSheets.dropdown.clear"
        ),
      },
      ...fields.map(field => ({
        key: field,
        text: field,
      })),
    ];
  }, [fields, localized]);

  const errorMessages = useMemo(() => {
    return mappings.map(mapping => {
      if (
        validationResult.missingFieldAssignedColumnIndices.includes(
          mapping.columnIndex
        )
      ) {
        return localized(
          "workspace.integrations.configuration.export_googleSheets.empty_field_error"
        );
      }
      return undefined;
    });
  }, [validationResult, localized, mappings]);

  return (
    <div
      className={cn(
        "grid grid-cols-[1fr_auto_1fr] gap-3 border border-solid",
        "border-gray-200 bg-gray-50 p-3 mt-3 items-start"
      )}
    >
      <div className="text-xs">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.fields" />
      </div>
      <div></div>
      <div className="text-xs">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.formx_field" />
      </div>
      {mappings.length === 0 ? (
        <div className="text-sm text-type-700 bg-gray-100 w-100 p-[8px] col-span-3">
          <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.placeholder" />
        </div>
      ) : (
        <>
          {mappings.map(({ columnName, columnIndex, fieldName }, index) => (
            <React.Fragment key={`${columnName}-${columnIndex}`}>
              <div className="overflow-hidden">
                <Dropdown
                  className="mt-1"
                  disabled={true}
                  options={[
                    {
                      key: columnIndex,
                      text: columnName,
                    },
                  ]}
                  placeholder={localized("dropdown.placeholder")}
                  selectedKey={columnIndex}
                />
              </div>
              <div className="flex flex-col justify-center items-center h-[32px]">
                <Icon iconName="IconFieldMapping" />
              </div>
              <div className="overflow-hidden">
                <Dropdown
                  className="mt-1"
                  disabled={fields.length === 0}
                  options={fieldOptions}
                  placeholder={localized("dropdown.placeholder")}
                  selectedKey={fieldName}
                  onChange={onChange.bind(null, columnIndex)}
                  errorMessage={errorMessages[index]}
                />
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
}
