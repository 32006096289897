import {
  CreatedGoogleSheet,
  type DetailedGoogleSheetList,
  GoogleSheetCreateParams,
  GoogleSheetCreateResponse,
  GoogleSheetListResp,
  type GoogleSpreadsheetList,
  detailedGoogleSheetListSchema,
  googleSheetCreateResponseSchema,
  googleSpreadsheetListSchema,
  googleWorksheetListRespSchema,
} from "../../types/googleSheet";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface GoogleSheetApiClient {
  listGoogleSheetColumns: (
    oauthCredentialId: string,
    spreadsheetId: string,
    sheetId?: string | null
  ) => Promise<DetailedGoogleSheetList>;
  listGoogleSpreadsheets: (
    oauthCredentialId: string,
    query: string | null
  ) => Promise<GoogleSpreadsheetList>;
  createGoogleSpreadsheet: (
    oauthCredentialId: string,
    spreadsheetName: string,
    sheets: GoogleSheetCreateParams[]
  ) => Promise<GoogleSheetCreateResponse>;
  listGoogleSheets: (
    oauthCredentialId: string,
    spreadsheetId: string
  ) => Promise<GoogleSheetListResp>;
}

export function withGoogleSheetApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listGoogleSheetColumns(
      oauthCredentialId: string,
      spreadsheetId: string,
      worksheetId?: string | null
    ): Promise<DetailedGoogleSheetList> {
      return this.lambda(
        "google_sheet:list-columns",
        {
          oauth_credential_id: oauthCredentialId,
          spreadsheet_id: spreadsheetId,
          sheet_id: worksheetId,
        },
        detailedGoogleSheetListSchema,
        null
      );
    }

    async listGoogleSpreadsheets(
      oauthCredentialId: string,
      query: string | null = null
    ): Promise<GoogleSpreadsheetList> {
      return this.lambda(
        "google_sheet:list",
        { oauth_credential_id: oauthCredentialId, query },
        googleSpreadsheetListSchema,
        null
      );
    }

    async createGoogleSpreadsheet(
      oauthCredentialId: string,
      spreadsheetName: string,
      sheets: GoogleSheetCreateParams[]
    ): Promise<CreatedGoogleSheet> {
      return this.lambda(
        "google_sheet:create",
        {
          oauth_credential_id: oauthCredentialId,
          spreadsheet_name: spreadsheetName,
          sheets,
        },
        googleSheetCreateResponseSchema,
        null
      );
    }

    async listGoogleSheets(
      oauthCredentialId: string,
      spreadsheetId: string
    ): Promise<GoogleSheetListResp> {
      return this.lambda(
        "google_sheet:list-sheets",
        {
          oauth_credential_id: oauthCredentialId,
          spreadsheet_id: spreadsheetId,
        },
        googleWorksheetListRespSchema,
        null
      );
    }
  };
}
