import * as yup from "yup";

import { boundingBoxSchema } from "./boundingBox";
import {
  KeywordsExtractionParams,
  KeywordsExtractionParamsResp,
  keywordsExtractionParamsSchema,
} from "./keywordsExtraction";
import { LLMCompletion, llmCompletionSchema } from "./llmCompletion";
import {
  Abbyy,
  AzureComputerVision,
  AzureV4ComputerVision,
  GoogleCloudVision,
  Inherit,
  Tesseract,
} from "./ocrConfig";

export const detectionRegionFieldParamsSchema = yup.object({
  engine: yup
    .mixed((_input): _input is DetectionRegionFieldEngine => true)
    .optional(),
  api: yup.string().optional(),
  code: yup.string().optional(),
  languages: yup.array(yup.string().defined()).optional(),
  keywords: yup
    .mixed((_input): _input is KeywordsExtractionParams => true)
    .test((_value, context) => {
      if (context.originalValue === undefined) {
        return true;
      }
      keywordsExtractionParamsSchema.validateSync(context.originalValue);
      return true;
    })
    .optional(),
  llm_completion: yup
    .mixed((_input): _input is LLMCompletion => true)
    .test((_value, context) => {
      if (context.originalValue === undefined) {
        return true;
      }
      llmCompletionSchema.validateSync(context.originalValue);
      return true;
    })
    .optional(),
});

export type DetectionRegionFieldExtra = {
  image_processing?: {
    threshold_trunc?: "auto";
  };
};

const detectionRegionFieldSchema = yup.object({
  label: yup.string().defined(),
  type: yup
    .mixed((_input): _input is DetectionRegionFieldType => true)
    .defined(),
  extras: yup
    .mixed((_input): _input is DetectionRegionFieldExtra => true)
    .optional(),
  params: yup
    .mixed((_input): _input is DetectionRegionFieldParams => true)
    .test((_value, context) => {
      if (context.originalValue === undefined) {
        return true;
      }
      detectionRegionFieldParamsSchema.validateSync(context.originalValue);
      return true;
    })
    .optional(),
});

const detectionRegionConfigSchema = yup.object({
  version: yup.number().defined(),
  fields: yup.array(detectionRegionFieldSchema).defined(),
});

export const detectionRegionSchema = yup
  .object({
    id: yup.string().defined(),
    bbox: boundingBoxSchema.defined(),
    formId: yup.string().optional(),
    config: detectionRegionConfigSchema.defined(),
  })
  .camelCase();

export type DetectionRegionFieldParams = yup.InferType<
  typeof detectionRegionFieldParamsSchema
>;

export type DetectionRegionField = yup.InferType<
  typeof detectionRegionFieldSchema
>;

export type DetectionRegionFieldForUpdate = {
  type?: DetectionRegionFieldType;
  label?: string;
  extras?: DetectionRegionFieldExtra | null;
  params?: DetectionRegionFieldParams | null;
};

export type DetectionRegionConfig = yup.InferType<
  typeof detectionRegionConfigSchema
>;

export type DetectionRegion = yup.InferType<typeof detectionRegionSchema>;

export type DetectionRegionFieldParamsResp = Omit<
  DetectionRegionFieldParams,
  "keywords"
> & {
  keywords?: KeywordsExtractionParamsResp;
};

export type DetectionRegionFieldResp = Omit<DetectionRegionField, "params"> & {
  params?: DetectionRegionFieldParamsResp;
};

export type DetectionRegionConfigResp = Omit<
  DetectionRegionConfig,
  "fields"
> & {
  fields: DetectionRegionFieldResp[];
};

export type DetectionRegionResp = Omit<DetectionRegion, "formId" | "config"> & {
  form_id?: string;
  config: DetectionRegionConfigResp;
};

export const DetectionRegionFieldTypeValues = [
  "single-line-eng" as const,
  "single-line-chi" as const,
  "single-line-eng-chi" as const,
  "block-eng" as const,
  "block-chi" as const,
  "block-eng-chi" as const,
  "handwritten-eng" as const,
  "handwritten-chi" as const,
  "handwritten-eng-chi" as const,
  "barcode" as const,
  "checkbox" as const,
  "script" as const,
  "name" as const,
  "address" as const,
  "date" as const,
  "time" as const,
  "total-amount" as const,
  "text-in-paragraph" as const,
  // "number",
];

export type DetectionRegionFieldType =
  (typeof DetectionRegionFieldTypeValues)[number];

export type DetectionRegionFieldEngine =
  | typeof Tesseract
  | typeof GoogleCloudVision
  | typeof AzureComputerVision
  | typeof AzureV4ComputerVision
  | typeof Abbyy
  | typeof Inherit;

export const API = "api";

export type DetectionRegionFieldAdvancedSetting = typeof API;

export interface DetectionRegionFieldAdvancedSettingByEngine {
  [key: string]: DetectionRegionFieldAdvancedSetting[];
}
export interface DetectionRegionAdvancedSettingsVisibilities {
  [key: string]: boolean[];
}
