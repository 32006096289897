import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useConfirmModalActionCreator } from "../../actions/confirmModal";
import { useWebhookActionCreator } from "../../actions/webhook";
import { UserFeatureFlag } from "../../constants";
import { useWorkspaceIntegration } from "../../contexts/workspaceIntegration";
import type { useOAuthCredentialReturnValues } from "../../hooks/oauth";
import { useAppSelector } from "../../hooks/redux";
import { useToast } from "../../hooks/toast";
import {
  type useGoogleSheetIntegrationReturnValues,
  useSharePointIntegrationReturnValues,
} from "../../hooks/workspace_integration";
import { ConfirmModalType } from "../../types/confirmation";
import type { GoogleSheetFieldMapping } from "../../types/googleSheet";
import { OAuthCredential } from "../../types/oauth";
import { Workspace } from "../../types/workspace";
import {
  FilePickerSharePointFile,
  WorkspaceExportGoogleSheetsIntegrationConfiguration,
  WorkspaceExportGoogleSheetsIntegrationConfigurationAccessor,
  WorkspaceIntegrationConfiguration,
  WorkspaceIntegrationOptionType,
  WorkspaceWebhookIntegrationConfiguration,
  allWorkspaceIntegration3rdPartyOptionType,
  allWorkspaceIntegrationExportOptionType,
  allWorkspaceIntegrationImportOptionType,
  initialWorkspaceIntegrationConfiguration,
} from "../../types/workspaceIntegration";
import { WorkspaceWebhook } from "../../types/workspaceWebhook";
import DataNotSavedPrompt from "../DataNotSavedPrompt";
import { GroupHeader } from "../GroupHeader";
import { ExportGoogleSheetsConfigurationCard } from "../WorkspaceIntegrationConfigurationCard/ExportGoogleSheetsConfigurationCard";
import { SharePointConfigurationCard } from "../WorkspaceIntegrationConfigurationCard/SharePointConfigurationCard";
import { WebhookConfigurationCard } from "../WorkspaceIntegrationConfigurationCard/WebhookConfigurationCard";
import { WorkspaceIntegrationOptionGroup } from "../WorkspaceIntegrationOptionGroup";
import { WorkspaceWebhookList } from "../WorkspaceWebhookList/WorkspaceWebhookList";
import { ZapierModal, useZapierModalHandle } from "../ZapierModal";
import styles from "./styles.module.scss";

interface OptionListProps {
  selectedImportOptions: WorkspaceIntegrationOptionType[];
  selectedExportOptions: WorkspaceIntegrationOptionType[];
  onImportOptionClick: (op: WorkspaceIntegrationOptionType) => void;
  onExportOptionClick: (op: WorkspaceIntegrationOptionType) => void;
}

type ExportOption = {
  key: string;
  isCreated: boolean;
  configuration: WorkspaceIntegrationConfiguration;
};

function OptionList(props: OptionListProps) {
  const {
    selectedImportOptions,
    selectedExportOptions,
    onImportOptionClick,
    onExportOptionClick,
  } = props;

  const {
    isGoogleSheetsExportIntegrationEnabled,
    isSharePointIntegrationEnabled,
    isWebhookExportIntegrationEnabled,
  } = useAppSelector(state => ({
    isGoogleSheetsExportIntegrationEnabled:
      state.resourceOwner.isFeatureEnabled()(
        UserFeatureFlag.GoogleSheetsExportIntegration
      ),
    isSharePointIntegrationEnabled: state.resourceOwner.isFeatureEnabled()(
      UserFeatureFlag.SharePointIntegration
    ),
    isWebhookExportIntegrationEnabled: state.resourceOwner.isFeatureEnabled()(
      UserFeatureFlag.WebhookExportIntegration
    ),
  }));

  const selectedImportOptionSet = useMemo(() => {
    return new Set(selectedImportOptions);
  }, [selectedImportOptions]);

  const selectedExportOptionSet = useMemo(() => {
    return new Set(selectedExportOptions);
  }, [selectedExportOptions]);

  const enabledOptions = useMemo(() => {
    const options = new Set<WorkspaceIntegrationOptionType>();

    if (isGoogleSheetsExportIntegrationEnabled) {
      options.add("export_googleSheets");
    }
    options.add("export_zapier");

    if (isSharePointIntegrationEnabled) {
      options.add("import_sharepoint");
    }
    if (isWebhookExportIntegrationEnabled) {
      options.add("export_webhook");
    }

    return options;
  }, [
    isGoogleSheetsExportIntegrationEnabled,
    isSharePointIntegrationEnabled,
    isWebhookExportIntegrationEnabled,
  ]);

  return (
    <div className={styles["right-sidebar"]}>
      <section className={styles["option-list-section"]}>
        <WorkspaceIntegrationOptionGroup
          optionType="import"
          items={allWorkspaceIntegrationImportOptionType}
          selectedOptionSet={selectedImportOptionSet}
          enabledOptions={enabledOptions}
          onOptionClick={onImportOptionClick}
          defaultExpanded={true}
        />
      </section>
      <section className={styles["option-list-section"]}>
        <WorkspaceIntegrationOptionGroup
          optionType="export"
          items={allWorkspaceIntegrationExportOptionType}
          selectedOptionSet={selectedExportOptionSet}
          enabledOptions={enabledOptions}
          onOptionClick={onExportOptionClick}
          defaultExpanded={true}
        />
      </section>
      <section className={styles["option-list-section"]}>
        <WorkspaceIntegrationOptionGroup
          optionType="thirdParty"
          items={allWorkspaceIntegration3rdPartyOptionType}
          selectedOptionSet={selectedExportOptionSet}
          enabledOptions={enabledOptions}
          onOptionClick={onExportOptionClick}
          defaultExpanded={true}
        />
      </section>
    </div>
  );
}

type ExportConfigurationProps = {
  optionType: WorkspaceIntegrationOptionType;
  configuration: WorkspaceIntegrationConfiguration;
  isCreated: boolean;
  extractorId: string | null;
  workspaceId: string;
  onGoogleSheetExportConfigurationSaved: (
    id: string,
    oauthCredentialId: string,
    spreadsheetId: string,
    spreadsheetName: string,
    sheetId: string,
    sheetName: string,
    mappings: GoogleSheetFieldMapping[],
    configuration: WorkspaceExportGoogleSheetsIntegrationConfiguration
  ) => Promise<void>;
  onExportConfigurationRemoved: (
    configuration: WorkspaceIntegrationConfiguration
  ) => void;
  onExportConfigurationChanged: () => void;
  oauthCredentials: OAuthCredential[];
};

function ExportConfiguration(props: ExportConfigurationProps) {
  const {
    optionType,
    configuration,
    isCreated,
    extractorId,
    workspaceId,
    oauthCredentials,
    onGoogleSheetExportConfigurationSaved,
    onExportConfigurationRemoved,
    onExportConfigurationChanged,
  } = props;

  switch (optionType) {
    case "export_googleSheets":
      return (
        <ExportGoogleSheetsConfigurationCard
          configuration={
            configuration as WorkspaceExportGoogleSheetsIntegrationConfiguration
          }
          extractorId={extractorId}
          workspaceId={workspaceId}
          oauthCredentials={oauthCredentials}
          onConfigurationRemoved={onExportConfigurationRemoved}
          onConfigurationSaved={onGoogleSheetExportConfigurationSaved}
          isCreated={isCreated}
          onChanged={onExportConfigurationChanged}
        />
      );
    case "export_webhook":
      return (
        <WebhookConfigurationCard
          configuration={
            configuration as WorkspaceWebhookIntegrationConfiguration
          }
          workspaceId={workspaceId}
          onConfigurationRemoved={onExportConfigurationRemoved}
        />
      );
    default:
      return null;
  }
}

export interface WorkspaceIntegrationSectionProps {
  googleSheetIntegrationProps: useGoogleSheetIntegrationReturnValues;
  oauthProps: useOAuthCredentialReturnValues;
  sharePointIntegrationProps: useSharePointIntegrationReturnValues;
  workspace: Workspace;
  workspaceWebhooks: WorkspaceWebhook[];
  googleAuth: () => Promise<OAuthCredential | undefined>;
}

export function useWorkspaceIntegrationSectionState(
  props: WorkspaceIntegrationSectionProps
) {
  const {
    googleSheetIntegrationProps,
    oauthProps,
    sharePointIntegrationProps,
    workspace,
    workspaceWebhooks,
    ...rest
  } = props;

  const { googleSheetExportList } = useWorkspaceIntegration();

  const workspaceIntegration = useWorkspaceIntegration();
  const { deleteGoogleSheetExport } = workspaceIntegration;

  const {
    onSharePointSubscriptionSave,
    onSharePointSubscriptionDisconnect,
    onSharePointIntegrationRemove,
    onLoginSharePoint,
    isLoggedInToSharePoint,
    isLoggingInToSharePoint,
    integrationConfigs,
  } = sharePointIntegrationProps;

  const { removeWebhook } = useWebhookActionCreator();
  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const toast = useToast();
  const zapierModalHandle = useZapierModalHandle(
    workspace.id,
    workspace.extractor?.associatedExtractorId ?? workspace.extractor?.id ?? ""
  );

  const { oauthCredentials } = oauthProps;

  // TODO: Set initial state by retrieved workspace object
  const [selectedImportOptions, setSelectedImportOptions] = useState<
    WorkspaceIntegrationConfiguration[]
  >([]);

  const [creatingExportOptions, setCreatingExportOptions] = useState<
    ExportOption[]
  >([]);

  const exportOptions: ExportOption[] = useMemo(() => {
    return [
      ...creatingExportOptions,
      ...googleSheetExportList.map(exportConfig => {
        const configuration =
          WorkspaceExportGoogleSheetsIntegrationConfigurationAccessor.createFromWorkspaceGoogleSheetExport(
            exportConfig
          ).data;

        return {
          key: exportConfig.id,
          isCreated: true,
          configuration,
        };
      }),
    ];
  }, [googleSheetExportList, creatingExportOptions]);

  const thirdPartyAutoToolConfigurations = useMemo<
    WorkspaceIntegrationConfiguration[]
  >(() => {
    return workspaceWebhooks
      .filter(webhook => webhook.type === "zapier")
      .map(webhook => {
        return {
          id: webhook.webhookId,
          optionType: "export_zapier",
          zapId: webhook.config.zap_id,
          webhookId: webhook.webhookId,
        };
      });
  }, [workspaceWebhooks]);

  const connectedGoogleOAuthCredentials = useMemo(() => {
    return oauthCredentials.oauthCredentials.filter(
      ({ resourceServer }) => resourceServer === "google"
    );
  }, [oauthCredentials]);

  const selectedImportOptionTypes = useMemo(() => {
    return selectedImportOptions.map(op => op.optionType);
  }, [selectedImportOptions]);
  const selectedExportOptionTypes = useMemo(() => {
    return creatingExportOptions
      .filter(
        // Export to google sheets allow to have multiple export
        op => op.configuration.optionType !== "export_googleSheets"
      )
      .map(op => op.configuration.optionType);
  }, [creatingExportOptions]);

  const onImportOptionClick = useCallback(
    (selectedOptionType: WorkspaceIntegrationOptionType) => {
      if (selectedOptionType !== "export_zapier") {
        setSelectedImportOptions(prev => {
          if (prev.findIndex(op => op.optionType === selectedOptionType) >= 0) {
            return prev;
          }
          const newOption =
            initialWorkspaceIntegrationConfiguration[selectedOptionType];
          return [...prev, newOption];
        });
      }
    },
    []
  );

  const onExportOptionClick = useCallback(
    (selectedOptionType: WorkspaceIntegrationOptionType) => {
      if (selectedOptionType === "export_zapier") {
        zapierModalHandle.methods.open();
      } else {
        setCreatingExportOptions(prev => {
          if (
            // Export to google sheets allow to have multiple export
            selectedOptionType !== "export_googleSheets" &&
            prev.findIndex(
              op => op.configuration.optionType === selectedOptionType
            ) >= 0
          ) {
            return prev;
          }
          const id = `temp:${uuidv4()}`;
          const newOption = {
            key: id,
            isCreated: false,
            configuration: {
              ...initialWorkspaceIntegrationConfiguration[selectedOptionType],
              id,
            },
          };
          return [newOption, ...prev];
        });
      }
    },
    [zapierModalHandle]
  );

  const onImportConfigurationRemoved = useCallback(
    (_optionToBeRemoved: WorkspaceIntegrationConfiguration) => {
      onSharePointIntegrationRemove();
      // TODO - remove integration from selectedImportOptions and disconnect from sharepoint
    },
    [onSharePointIntegrationRemove]
  );

  const removeGoogleSheetExport = useCallback(
    async (
      optionToBeRemoved: WorkspaceExportGoogleSheetsIntegrationConfiguration
    ) => {
      const workspaceGoogleSheetConfiguration =
        optionToBeRemoved as WorkspaceExportGoogleSheetsIntegrationConfiguration;
      const exportOption = exportOptions.find(
        op => op.configuration.id === optionToBeRemoved.id
      );
      if (!exportOption) {
        return;
      }
      if (
        !exportOption.isCreated ||
        workspaceGoogleSheetConfiguration.id == null
      ) {
        const confirmed = await requestUserConfirmation(
          {
            type: ConfirmModalType.Destory,
            titleId: "workspace.integrations.model.remove_unsaved_export.title",
            messageId:
              "workspace.integrations.model.remove_unsaved_export.description",
            actionId: "common.discard",
            hideCancel: false,
          },
          false
        );

        if (confirmed) {
          setCreatingExportOptions(prev =>
            prev.filter(op => op.configuration.id !== optionToBeRemoved.id)
          );
          toast.success("common.removed");
        }
      } else {
        const confirmed = await requestUserConfirmation(
          {
            type: ConfirmModalType.Destory,
            titleId: "workspace.integrations.confirmRemoveModal.title",
            messageId:
              "workspace.integrations.google_sheet_export_confirmRemoveModal.description",
            actionId: "workspace.integrations.confirmRemoveModal.action.remove",
            hideCancel: false,
          },
          false
        );

        if (confirmed) {
          try {
            await deleteGoogleSheetExport(workspaceGoogleSheetConfiguration.id);
            toast.success("workspace.integrations.toast.remove_success");
          } catch (e) {
            console.error("Failed to remove Google Sheets export: ", e);
            toast.error("workspace.integrations.toast.remove_failed");
          }
        }
      }
    },
    [deleteGoogleSheetExport, toast, requestUserConfirmation, exportOptions]
  );

  const createWorkspaceGoogleSheetExport = useCallback(
    async (
      id: string,
      oauthCredentialId: string,
      spreadsheetId: string,
      spreadsheetName: string,
      sheetId: string,
      sheetName: string,
      mappings: GoogleSheetFieldMapping[]
    ) => {
      try {
        const response =
          await workspaceIntegration.createWorkspaceGoogleSheetExport(
            workspace.id,
            oauthCredentialId,
            spreadsheetId,
            spreadsheetName,
            sheetId,
            sheetName,
            mappings
          );
        toast.success("workspace.integrations.toast.save_success");

        setCreatingExportOptions(prev =>
          prev.map(op => {
            if (op.key === id) {
              return {
                ...op,
                isCreated: true,
                configuration: {
                  ...op.configuration,
                  id: response.id,
                  spreadsheetId,
                  spreadsheetName,
                  sheetId,
                  sheetName,
                },
              };
            }
            return op;
          })
        );

        setExportOptionChangedTable(prev => {
          const next = { ...prev };
          delete next[id];
          return next;
        });
      } catch (e) {
        console.error(e);
        toast.error(
          "workspace.integrations.toast.unable_to_create_google_sheets_export"
        );
      }
    },
    [workspace.id, toast, workspaceIntegration]
  );

  const updateWorkspaceGoogleSheetExport = useCallback(
    async (
      workspaceGoogleSheetExportId: string,
      mappings: GoogleSheetFieldMapping[]
    ) => {
      try {
        await workspaceIntegration.updateGoogleSheetExport(
          workspaceGoogleSheetExportId,
          mappings
        );
        toast.success("workspace.integrations.toast.save_success");

        setExportOptionChangedTable(prev => {
          const next = { ...prev };
          delete next[workspaceGoogleSheetExportId];
          return next;
        });
      } catch (e) {
        console.error(e);
        toast.error(
          "workspace.integrations.toast.unable_to_update_google_sheets_export"
        );
      }
    },
    [workspaceIntegration, toast]
  );

  const [exportOptionChangedTable, setExportOptionChangedTable] = useState<
    Record<string, boolean>
  >({});

  const onExportConfigurationChanged = useCallback((key: string) => {
    setExportOptionChangedTable(prev => ({
      ...prev,
      [key]: true,
    }));
  }, []);

  const onGoogleSheetExportConfigurationSaved = useCallback(
    async (
      id: string,
      oauthCredentialId: string,
      spreadsheetId: string,
      spreadsheetName: string,
      sheetId: string,
      sheetName: string,
      mappings: GoogleSheetFieldMapping[]
    ) => {
      const exportOption = exportOptions.find(op => op.configuration.id === id);
      if (!exportOption) {
        return;
      }

      if (exportOption.isCreated) {
        await updateWorkspaceGoogleSheetExport(id, mappings);
      } else {
        await createWorkspaceGoogleSheetExport(
          id,
          oauthCredentialId,
          spreadsheetId,
          spreadsheetName,
          sheetId,
          sheetName,
          mappings
        );
      }
    },
    [
      createWorkspaceGoogleSheetExport,
      updateWorkspaceGoogleSheetExport,
      exportOptions,
    ]
  );

  const [sharePointFolder, setSharePointFolder] =
    useState<FilePickerSharePointFile | null>(null);

  const removeZapier = useCallback(
    async (configuration: WorkspaceIntegrationConfiguration) => {
      if (configuration.optionType !== "export_zapier") {
        return;
      }

      try {
        await removeWebhook(configuration.webhookId);
        toast.success("workspace.integrations.toast.remove_success");
      } catch (e) {
        console.error("Failed to remove webhook: ", e);
        toast.error("workspace.integrations.toast.remove_failed");
      }
    },
    [removeWebhook, toast]
  );

  const onZapierConfigurationRemoved = useCallback(
    async (configuration: WorkspaceIntegrationConfiguration) => {
      if (configuration.optionType !== "export_zapier") {
        return;
      }

      try {
        await requestUserConfirmation(
          {
            type: ConfirmModalType.Destory,
            titleId: "workspace.integrations.confirmRemoveModal.title",
            messageId: "workspace.integrations.confirmRemoveModal.description",
            messageValues: {
              connectionId:
                configuration?.optionType === "export_zapier"
                  ? configuration.zapId
                  : "",
            },
            actionId: "workspace.integrations.confirmRemoveModal.action.remove",
            hideCancel: true,
          },
          false
        );
        removeZapier(configuration);
      } catch (e) {
        console.error("Failed to show request user confirmation modal: ", e);
      }
    },
    [removeZapier, requestUserConfirmation]
  );

  const onExportConfigurationRemoved = useCallback(
    async (optionToBeRemoved: WorkspaceIntegrationConfiguration) => {
      if (optionToBeRemoved.optionType === "export_googleSheets") {
        removeGoogleSheetExport(
          optionToBeRemoved as WorkspaceExportGoogleSheetsIntegrationConfiguration
        );
      } else if (optionToBeRemoved.optionType === "export_zapier") {
        onZapierConfigurationRemoved(optionToBeRemoved);
      } else if (optionToBeRemoved.optionType === "export_webhook") {
        setCreatingExportOptions(prev =>
          prev.filter(op => op.key !== optionToBeRemoved.id)
        );
      }
    },
    [
      onZapierConfigurationRemoved,
      setCreatingExportOptions,
      removeGoogleSheetExport,
    ]
  );

  const [thirdPatySectionExpanded, setThirdPatySectionExpanded] =
    useState(true);

  const handleThirdPartySectionToggle = React.useCallback(() => {
    setThirdPatySectionExpanded(!thirdPatySectionExpanded);
  }, [thirdPatySectionExpanded]);

  const shouldShowExportEmptyState = exportOptions.length === 0;

  const hasDataChanged = Object.values(exportOptionChangedTable).some(
    changed => changed
  );

  return React.useMemo(
    () => ({
      ...rest,
      googleSheetExportList,
      selectedImportOptions,
      workspace,
      integrationConfigs,
      thirdPartyAutoToolConfigurations,
      isLoggedInToSharePoint,
      isLoggingInToSharePoint,
      sharePointFolder,
      onSharePointSubscriptionSave,
      onSharePointSubscriptionDisconnect,
      onLoginSharePoint,
      shouldShowExportEmptyState,
      onImportOptionClick,
      onExportOptionClick,
      onImportConfigurationRemoved,
      onExportConfigurationRemoved,
      onZapierConfigurationRemoved,
      handleThirdPartySectionToggle,
      onGoogleSheetExportConfigurationSaved,
      setSharePointFolder,
      connectedGoogleOAuthCredentials,
      exportOptions,
      selectedImportOptionTypes,
      selectedExportOptionTypes,
      thirdPatySectionExpanded,
      zapierModalHandle,
      onExportConfigurationChanged,
      hasDataChanged,
    }),
    [
      rest,
      googleSheetExportList,
      selectedImportOptions,
      workspace,
      integrationConfigs,
      thirdPartyAutoToolConfigurations,
      isLoggedInToSharePoint,
      isLoggingInToSharePoint,
      sharePointFolder,
      onSharePointSubscriptionSave,
      onSharePointSubscriptionDisconnect,
      onLoginSharePoint,
      shouldShowExportEmptyState,
      onImportOptionClick,
      onExportOptionClick,
      onImportConfigurationRemoved,
      onExportConfigurationRemoved,
      onZapierConfigurationRemoved,
      handleThirdPartySectionToggle,
      onGoogleSheetExportConfigurationSaved,
      setSharePointFolder,
      connectedGoogleOAuthCredentials,
      exportOptions,
      selectedImportOptionTypes,
      selectedExportOptionTypes,
      thirdPatySectionExpanded,
      zapierModalHandle,
      onExportConfigurationChanged,
      hasDataChanged,
    ]
  );
}

export function WorkspaceIntegrationSectionImpl(
  props: ReturnType<typeof useWorkspaceIntegrationSectionState>
) {
  const {
    selectedImportOptions,
    workspace,
    integrationConfigs,
    thirdPartyAutoToolConfigurations,
    isLoggedInToSharePoint,
    isLoggingInToSharePoint,
    onImportConfigurationRemoved,
    onSharePointSubscriptionSave,
    onSharePointSubscriptionDisconnect,
    onLoginSharePoint,
    setSharePointFolder,
    sharePointFolder,
    exportOptions,
    onGoogleSheetExportConfigurationSaved,
    onExportConfigurationRemoved,
    onZapierConfigurationRemoved,
    handleThirdPartySectionToggle,
    onImportOptionClick,
    onExportOptionClick,
    shouldShowExportEmptyState,
    selectedImportOptionTypes,
    selectedExportOptionTypes,
    thirdPatySectionExpanded,
    connectedGoogleOAuthCredentials,
    zapierModalHandle,
    hasDataChanged,
    onExportConfigurationChanged,
  } = props;

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <section className={styles["section"]}>
          <h4 className={styles["section-header"]}>
            <FormattedMessage id="workspace.integrations.option_setting.import.title" />
          </h4>
          {selectedImportOptions.length > 0 ? (
            selectedImportOptions.map(op => {
              switch (op.optionType) {
                case "import_sharepoint":
                  return (
                    <SharePointConfigurationCard
                      key={op.optionType}
                      workspaceId={workspace.id}
                      configuration={integrationConfigs}
                      onConfigurationRemoved={onImportConfigurationRemoved}
                      onSave={onSharePointSubscriptionSave}
                      onDisconnect={onSharePointSubscriptionDisconnect}
                      onLogin={onLoginSharePoint}
                      onFolderSelect={setSharePointFolder}
                      selectedFolder={sharePointFolder}
                      isLoggedIn={isLoggedInToSharePoint}
                      isLoggingIn={isLoggingInToSharePoint}
                    />
                  );
                default:
                  return null;
              }
            })
          ) : (
            <div className={styles["section-empty-state-container"]}>
              <FormattedMessage id="workspace.integrations.option_setting.import.empty_state" />
            </div>
          )}
        </section>
        <section className={styles["section"]}>
          <h4 className={styles["section-header"]}>
            <FormattedMessage id="workspace.integrations.option_setting.export.title" />
          </h4>
          <div className="flex flex-col gap-[8px]">
            {exportOptions.map(op => {
              return (
                <ExportConfiguration
                  key={op.key}
                  optionType={op.configuration.optionType}
                  configuration={op.configuration}
                  isCreated={op.isCreated}
                  extractorId={workspace.extractor?.id ?? null}
                  workspaceId={workspace.id}
                  oauthCredentials={connectedGoogleOAuthCredentials}
                  onGoogleSheetExportConfigurationSaved={
                    onGoogleSheetExportConfigurationSaved
                  }
                  onExportConfigurationRemoved={onExportConfigurationRemoved}
                  onExportConfigurationChanged={() => {
                    onExportConfigurationChanged(op.key);
                  }}
                />
              );
            })}
          </div>
          {shouldShowExportEmptyState ? (
            <div className={styles["section-empty-state-container"]}>
              <FormattedMessage id="workspace.integrations.option_setting.export.empty_state" />
            </div>
          ) : null}
        </section>
        <section className={styles["section"]}>
          {thirdPartyAutoToolConfigurations.length > 0 ? (
            <>
              <h4 className={styles["section-header"]}>
                <FormattedMessage id="workspace.integrations.option_setting.3rdparty.title" />
              </h4>
              <div className="bg-white border border-solid border-gray-200">
                <GroupHeader
                  isExpanded={thirdPatySectionExpanded}
                  onToggle={handleThirdPartySectionToggle}
                  className="my-3 px-4"
                >
                  <div className="flex flex-row items-center">
                    <Icon
                      className="mr-2"
                      styles={{
                        root: {
                          width: 40,
                          height: 40,
                        },
                      }}
                      iconName="IconZapier"
                    />
                    <span className="text-[#201F1E] text-base">
                      <FormattedMessage id="workspace.integrations.configuration_list.section.title.zapier" />
                    </span>
                  </div>
                </GroupHeader>

                {thirdPatySectionExpanded && (
                  <div className="px-4 pb-4">
                    <WorkspaceWebhookList
                      configurations={thirdPartyAutoToolConfigurations}
                      onConfigurationRemoved={onZapierConfigurationRemoved}
                    />
                  </div>
                )}
              </div>
            </>
          ) : null}
        </section>
      </div>
      <OptionList
        selectedImportOptions={selectedImportOptionTypes}
        selectedExportOptions={selectedExportOptionTypes}
        onImportOptionClick={onImportOptionClick}
        onExportOptionClick={onExportOptionClick}
      />
      <ZapierModal {...zapierModalHandle.props} />
      <DataNotSavedPrompt
        isDataChanged={hasDataChanged}
        titleTextId="workspace.integrations.not_saved_prompt.title"
        messageTextId="workspace.integrations.not_saved_prompt.message"
        backTextId="common.cancel"
        continueTextId="workspace.integrations.not_saved_prompt.confirm"
      />
    </div>
  );
}

export function WorkspaceIntegrationSection(
  props: WorkspaceIntegrationSectionProps
) {
  const states = useWorkspaceIntegrationSectionState(props);

  return <WorkspaceIntegrationSectionImpl {...states} />;
}
