import * as React from "react";

import { useExtractorActionCreator } from "../actions/extractor";
import { ExtractorField, ExtractorFieldSchemaTable } from "../types/extractor";
import { useAppSelector } from "./redux";

export function useExtractorFieldSchema() {
  const { getExtractorFieldSchemaTable } = useExtractorActionCreator();
  const {
    extractorFieldSchemaTables,
    isGettingExtractorFieldSchemaTables,
    getExtractorFieldSchemaErrors,
  } = useAppSelector(state => state.extractor);

  const isGettingExtractorFieldSchemaRef = React.useRef(
    isGettingExtractorFieldSchemaTables
  );
  isGettingExtractorFieldSchemaRef.current =
    isGettingExtractorFieldSchemaTables;

  const getExtractorFieldSchema = React.useCallback(
    async (extractorId: string): Promise<string[]> => {
      if (
        extractorFieldSchemaTables[extractorId] === undefined &&
        !isGettingExtractorFieldSchemaRef.current[extractorId]
      ) {
        const table = await getExtractorFieldSchemaTable(extractorId);
        const fields: string[] = [];
        Object.keys(table).forEach(key => {
          fields.push(...table[key].map(({ name }) => name));
        });
        return fields;
      }
      return [];
    },
    [extractorFieldSchemaTables, getExtractorFieldSchemaTable]
  );

  const getExtractorFieldSchemaTableByExtractorId = React.useCallback(
    (extractorId?: string): ExtractorFieldSchemaTable | undefined => {
      if (extractorId !== undefined) {
        return extractorFieldSchemaTables[extractorId];
      }
      return undefined;
    },
    [extractorFieldSchemaTables]
  );

  const isFailedToGetExtractorFieldSchema = React.useCallback(
    (extractorId?: string) => {
      return (
        extractorId !== undefined &&
        getExtractorFieldSchemaErrors[extractorId] !== undefined
      );
    },
    [getExtractorFieldSchemaErrors]
  );

  const cachedFields = React.useRef<Record<string, Promise<ExtractorField[]>>>(
    {}
  );

  const queryExtractorFieldSchemaFields = React.useCallback(
    async (extractorId: string): Promise<ExtractorField[]> => {
      const concat = (table: ExtractorFieldSchemaTable): ExtractorField[] => {
        const fields: ExtractorField[] = [];
        Object.keys(table).forEach(key => {
          fields.push(...table[key]);
        });
        return fields;
      };

      if (extractorFieldSchemaTables[extractorId] != null) {
        return concat(extractorFieldSchemaTables[extractorId]);
      }
      if (cachedFields.current[extractorId] != null) {
        return cachedFields.current[extractorId];
      }

      const promise = new Promise<ExtractorField[]>(async (resolve, reject) => {
        try {
          const fields = await getExtractorFieldSchemaTable(extractorId);
          resolve(concat(fields));
        } catch (error) {
          reject(error);
        } finally {
          delete cachedFields.current[extractorId];
        }
      });
      cachedFields.current[extractorId] = promise;
      return promise;
    },
    [getExtractorFieldSchemaTable, extractorFieldSchemaTables]
  );

  return React.useMemo(
    () => ({
      extractorFieldSchemaTables,
      getExtractorFieldSchema,
      isFailedToGetExtractorFieldSchema,
      getExtractorFieldSchemaTableByExtractorId,
      queryExtractorFieldSchemaFields,
    }),
    [
      extractorFieldSchemaTables,
      getExtractorFieldSchema,
      isFailedToGetExtractorFieldSchema,
      getExtractorFieldSchemaTableByExtractorId,
      queryExtractorFieldSchemaFields,
    ]
  );
}
