import { Icon, Link, TooltipHost } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import cntl from "cntl";
import produce from "immer";
import * as React from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { ButtonId } from "../../constants/buttonids";
import { JSON_INDENTATION } from "../../constants/format";
import { UNSUPPORTED_PREVIEW_MIME } from "../../constants/support";
import { useImageExists } from "../../hooks/common";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import { ExtractImageSource } from "../../hooks/extract_test";
import { useGtm } from "../../hooks/gtm";
import {
  ExtractAPIV2SuccessResponse,
  HiddenBoundingBoxIndices,
  accessExtractAPIV2Document,
  accessExtractAPIV2Response,
  accessHiddenBoundingBoxIndices,
} from "../../models";
import { SampleImage } from "../../types/extractor";
import { chooseFile } from "../../utils/file";
import { downloadJsonStringFile } from "../../utils/json";
import { CopyButton } from "../CopyButton";
import { ExternalServicesAlertBoxForForm } from "../ExternalServiceAlertBox";
import { ExtractAPIV2ResponseViewer } from "../ExtractAPIV2ResponseViewer";
import { ExtractTableDataBottomSheet } from "../ExtractTableDataBottomSheet";
import { HSplitView } from "../HSplitView";
import { ImageViewer } from "../ImageViewer";
import {
  JsonRawViewer,
  insertHighlightSeparatorToList,
} from "../JsonRawViewer";
import { OCRTestPlaceholder } from "../OCRTestPlaceholder";
import { OverlayPaginator } from "../OverlayPaginator";
import {
  ActionButton,
  ActionButtonProps,
  PrimaryButton,
} from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

export enum ViewMode {
  KeyValue,
  Json,
  LLM,
  OCR,
  ImageQuality,
}

export function TextAreaCopyBlock(props: { content: string | string[] }) {
  const text = React.useMemo(() => {
    return Array.isArray(props.content)
      ? props.content.join("\n")
      : props.content;
  }, [props.content]);

  return (
    <pre
      className={cntl`p-[20px] 
          bg-[#faf9f8]
          relative
          whitespace-pre-wrap	
          `}
    >
      <CopyButton
        className={cntl`top-0 right-0 absolute`}
        text={text}
        showText={true}
      />
      {text}
    </pre>
  );
}

interface Props {
  resourceName: string;
  samples?: readonly SampleImage[];
  onSelectSampleImage?: (url: string) => void;
  onSelectImage: (file?: File) => void;
  selectedResultIndex: number;
  setSelectedResultIndex: React.Dispatch<React.SetStateAction<number>>;
  extractResult?: ExtractAPIV2SuccessResponse;
  setExtractResult?: (result?: ExtractAPIV2SuccessResponse) => void;
  extractImageSource?: ExtractImageSource;
  shouldDetectExtractItemImage?: boolean;
  disableExtractTableDataBottomSheet?: boolean;
  hasTransformResponseScript: boolean;
  showExtractorIdTooltip?: boolean;
}

export function useExtractorTestPanel(props: Props) {
  const {
    samples,
    onSelectSampleImage,
    extractResult,
    setExtractResult,
    extractImageSource,
    onSelectImage,
    selectedResultIndex,
    setSelectedResultIndex,
    resourceName,
    shouldDetectExtractItemImage,
    hasTransformResponseScript,
    showExtractorIdTooltip = false,
  } = props;

  const results = extractResult?.documents;
  const disableExtractTableDataBottomSheet =
    props.disableExtractTableDataBottomSheet ?? false;

  const [hiddenBoundingBoxIndices, setHiddenBoundingBoxIndices] =
    React.useState<Map<number, HiddenBoundingBoxIndices>>(new Map());

  const isPreviewUnsupported = UNSUPPORTED_PREVIEW_MIME.includes(
    extractImageSource?.mime ?? ""
  );

  // Mapping from extractImage to extractResult.document
  const imageDocumentTuples = React.useMemo(() => {
    if (!extractResult) {
      return [];
    }

    const responseAccessor = accessExtractAPIV2Response(extractResult);
    const imageCount = isPreviewUnsupported
      ? responseAccessor.findDocumentMaxPageNo()
      : extractImageSource?.uris.length ?? 0;
    return responseAccessor.findImageDocumentIndexTuples(imageCount);
  }, [extractResult, isPreviewUnsupported, extractImageSource]);

  const totalImageCount = imageDocumentTuples?.length ?? 0;

  useDragAndDropFiles(
    (files?: File[]) => props.onSelectImage(files && files[0]),
    SUPPORTED_EXTRACT_MIME
  );

  const rawJson = React.useMemo(() => {
    if (!extractResult) {
      return {};
    }

    const ret = JSON.parse(
      JSON.stringify(extractResult)
    ) as ExtractAPIV2SuccessResponse;

    try {
      ret.documents = ret.documents.map(doc => {
        return accessExtractAPIV2Document(doc)
          .removeImageField()
          .removeHiddenFields().data;
      });
    } catch (e) {
      if (!hasTransformResponseScript) {
        throw e;
      }
    }

    return ret;
  }, [extractResult, hasTransformResponseScript]);

  const highlightedJson = React.useMemo(() => {
    if (!extractResult) {
      return "";
    }

    const ret = JSON.parse(JSON.stringify(rawJson, null, JSON_INDENTATION));

    const documentIndex =
      imageDocumentTuples[selectedResultIndex]?.documentIndex ?? -1;

    try {
      ret.documents = insertHighlightSeparatorToList(
        ret.documents,
        documentIndex
      );
    } catch (e) {
      if (!hasTransformResponseScript) {
        throw e;
      }
    }

    return ret;
  }, [
    rawJson,
    extractResult,
    selectedResultIndex,
    hasTransformResponseScript,
    imageDocumentTuples,
  ]);

  React.useEffect(() => {
    setSelectedResultIndex(0);
    setViewMode(ViewMode.KeyValue);
    setHiddenBoundingBoxIndices(new Map());
  }, [results, setSelectedResultIndex]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.KeyValue);

  const selectedResult = React.useMemo(() => {
    try {
      const documentIndex =
        imageDocumentTuples[selectedResultIndex]?.documentIndex;
      return results?.[documentIndex];
    } catch {
      return undefined;
    }
  }, [selectedResultIndex, results, imageDocumentTuples]);

  const selectedResultHiddenBoundingBoxIndices = React.useMemo(() => {
    return hiddenBoundingBoxIndices.get(selectedResultIndex) ?? new Map();
  }, [hiddenBoundingBoxIndices, selectedResultIndex]);

  const onToggleBoundingBox = React.useCallback(
    (field: string, index: number) => {
      setHiddenBoundingBoxIndices(prev =>
        produce(prev, draft => {
          const accessor = accessHiddenBoundingBoxIndices(
            draft.get(selectedResultIndex)
          );
          accessor.toggleIndex(field, index);
          draft.set(selectedResultIndex, accessor.get());
        })
      );
    },
    [selectedResultIndex, setHiddenBoundingBoxIndices]
  );

  const selectedRenderDocument = React.useMemo(() => {
    if (!selectedResult) {
      return undefined;
    }

    const res = accessExtractAPIV2Document(
      selectedResult
    ).extractRenderDocument(
      selectedResultIndex,
      selectedResultHiddenBoundingBoxIndices
    );

    const imageIndex =
      imageDocumentTuples?.[selectedResultIndex]?.imageIndex ?? -1;
    if (imageIndex >= 0) {
      res.url = extractImageSource?.uris[imageIndex];
    }
    return res;
  }, [
    selectedResult,
    imageDocumentTuples,
    extractImageSource,
    selectedResultIndex,
    selectedResultHiddenBoundingBoxIndices,
  ]);

  const nextPage = React.useCallback(() => {
    if (totalImageCount > 0) {
      setSelectedResultIndex(prev => (prev + 1) % totalImageCount);
    }
  }, [totalImageCount, setSelectedResultIndex]);

  const prevPage = React.useCallback(() => {
    if (totalImageCount > 0) {
      setSelectedResultIndex(prev => {
        return (prev + totalImageCount - 1) % totalImageCount;
      });
    }
  }, [totalImageCount, setSelectedResultIndex]);

  const onFiles = React.useCallback(
    (files?: File[]) => onSelectImage(files && files[0]),
    [onSelectImage]
  );

  const askToUploadFile = React.useCallback(() => {
    setExtractResult?.(undefined);
    chooseFile(SUPPORTED_EXTRACT_MIME.join(","))
      .then(onFiles)
      .catch(() => {});
  }, [onFiles, setExtractResult]);

  const downloadJson = React.useCallback(() => {
    downloadJsonStringFile(
      JSON.stringify(rawJson, null, JSON_INDENTATION),
      "json-output-" + resourceName.replace(/\s+/g, "-").toLowerCase()
    );
  }, [rawJson, resourceName]);

  const isImageQualityExists = selectedResult?.metadata?.image_quality != null;

  return React.useMemo(
    () => ({
      samples,
      onSelectSampleImage,
      results,
      onSelectImage,
      selectedResultIndex,
      selectedResult,
      nextPage,
      prevPage,
      askToUploadFile,
      downloadJson,
      rawJson,
      highlightedJson,
      shouldDetectExtractItemImage,
      disableExtractTableDataBottomSheet,
      selectedRenderDocument,
      hasTransformResponseScript,
      extractResult,
      totalImageCount,
      selectedResultHiddenBoundingBoxIndices,
      onToggleBoundingBox,
      viewMode,
      setViewMode,
      isImageQualityExists,
      showExtractorIdTooltip,
      isPreviewUnsupported,
    }),
    [
      samples,
      onSelectSampleImage,
      results,
      onSelectImage,
      selectedResultIndex,
      selectedResult,
      nextPage,
      prevPage,
      askToUploadFile,
      downloadJson,
      rawJson,
      highlightedJson,
      shouldDetectExtractItemImage,
      disableExtractTableDataBottomSheet,
      selectedRenderDocument,
      hasTransformResponseScript,
      extractResult,
      totalImageCount,
      selectedResultHiddenBoundingBoxIndices,
      onToggleBoundingBox,
      viewMode,
      isImageQualityExists,
      showExtractorIdTooltip,
      isPreviewUnsupported,
    ]
  );
}

interface ActionButtonTabProps extends ActionButtonProps {
  viewMode: ViewMode;
}

interface ActionButtonTabBarProps {
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
  tabs: ActionButtonTabProps[];
}

function ActionButtonTabBar(props: ActionButtonTabBarProps) {
  const { tabs, setViewMode, viewMode: currentViewMode } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const dragStartPosition = React.useRef({ scrollLeft: 0, clientX: 0 });
  const isMouseMoved = React.useRef(false);

  const onClick = React.useCallback(
    viewMode => {
      return () => {
        if (!isMouseMoved.current) {
          setViewMode(viewMode);
        }
      };
    },
    [setViewMode]
  );

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    const element = ref.current;

    const onMouseMove = (e: MouseEvent) => {
      const dX = e.clientX - dragStartPosition.current.clientX;

      if (Math.abs(dX) > 10) {
        isMouseMoved.current = true;
      }
      element.scrollLeft = dragStartPosition.current.scrollLeft - dX;
    };

    const onDocumentMouseUp = (e: MouseEvent) => {
      if (isMouseMoved.current) {
        e.stopPropagation();
      }
      element.removeEventListener("mousemove", onMouseMove);
    };

    const onMouseDown = (e: MouseEvent) => {
      isMouseMoved.current = false;

      dragStartPosition.current = {
        clientX: e.clientX,
        scrollLeft: element.scrollLeft,
      };

      element.addEventListener("mousemove", onMouseMove);

      document.addEventListener("mouseup", onDocumentMouseUp);
    };

    element.addEventListener("mousedown", onMouseDown);

    return () => {
      element.removeEventListener("mousedown", onMouseDown);
      element.removeEventListener("mousemove", onMouseMove);

      document.removeEventListener("mouseup", onDocumentMouseUp);
    };
  }, []);
  return (
    <div
      className={cntl`grow relative
      after:content-[''] after:absolute after:top-0 after:right-0 after:bottom-0 after:w-[96px]
      after:bg-gradient-to-r after:from-transparent after:from-0% after:to-white after:to-100%
      after:pointer-events-none`}
    >
      <div
        ref={ref}
        className="absolute top-0 right-0 left-0 bottom-0 flex flex-col overflow-x-scroll no-scrollbar h-full w-full"
      >
        <div className="flex flex-row pr-2">
          {tabs.map(tab => {
            const { viewMode, ...buttonProps } = tab;
            return (
              <div key={viewMode} className="last:pr-[48px]">
                <ActionButton
                  {...buttonProps}
                  className="whitespace-nowrap"
                  onClick={onClick(viewMode)}
                />
                <hr
                  className={classnames("border-0 h-[2px] bg-primary-500", {
                    hidden: viewMode !== currentViewMode,
                  })}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export function ExtractorTestResultViewer(
  props: ReturnType<typeof useExtractorTestPanel>
) {
  const {
    selectedResult,
    selectedResultIndex,
    nextPage,
    prevPage,
    askToUploadFile,
    downloadJson,
    highlightedJson,
    shouldDetectExtractItemImage,
    selectedRenderDocument,
    hasTransformResponseScript,
    totalImageCount,
    selectedResultHiddenBoundingBoxIndices,
    onToggleBoundingBox,
    viewMode,
    setViewMode,
    isImageQualityExists,
    showExtractorIdTooltip,
    isPreviewUnsupported,
  } = props;

  const { pushTestTabReuploadEvent } = useGtm();

  const isLLMPromptExist = selectedResult?.llm_prompt !== undefined;
  const isOCRExists = selectedResult?.ocr != null;

  const imageViewerSrc = React.useMemo(() => {
    return selectedRenderDocument == null || isPreviewUnsupported
      ? ""
      : [selectedRenderDocument];
  }, [isPreviewUnsupported, selectedRenderDocument]);

  const isImageExist = useImageExists(
    selectedRenderDocument?.url == null || isPreviewUnsupported
      ? ""
      : selectedRenderDocument.url
  );

  const reupload = React.useCallback(() => {
    askToUploadFile();
    pushTestTabReuploadEvent();
  }, [askToUploadFile, pushTestTabReuploadEvent]);

  const tabs = React.useMemo(() => {
    const _tabs: ActionButtonTabProps[] = [];

    if (!hasTransformResponseScript) {
      _tabs.push({
        iconName: "Table",
        textId: "extractor.test.view_in_key_value",
        viewMode: ViewMode.KeyValue,
      });
    }

    _tabs.push({
      iconName: "Code",
      textId: "extractor.test.view_in_json",
      viewMode: ViewMode.Json,
    });

    if (isLLMPromptExist) {
      _tabs.push({
        iconName: "Message",
        textId: "extractor.test.view_llm_prompt",
        viewMode: ViewMode.LLM,
      });
    }

    if (isOCRExists) {
      _tabs.push({
        iconName: "RedEye",
        textId: "extractor.test.ocr",
        viewMode: ViewMode.OCR,
      });
    }

    if (isImageQualityExists) {
      _tabs.push({
        iconName: "Photo2",
        textId: "extractor.test.image_quality",
        viewMode: ViewMode.ImageQuality,
      });
    }

    return _tabs;
  }, [
    hasTransformResponseScript,
    isLLMPromptExist,
    isOCRExists,
    isImageQualityExists,
  ]);

  return (
    <HSplitView
      leftMinWidth={320}
      leftFlexGrow={1}
      left={
        <div className={styles["image-content"]}>
          {!isImageExist ? (
            <div className={styles["image-not-found"]}>
              <div>
                {isPreviewUnsupported ? (
                  <FormattedMessage id="extractor.test.unable_to_preview_word_excel_doc" />
                ) : (
                  <FormattedMessage id="extractor.test.unable_to_load_image" />
                )}
                <div className="absolute bottom-5 left-1/2 -translate-x-1/2">
                  <OverlayPaginator
                    currentPage={selectedResultIndex + 1}
                    totalPage={totalImageCount}
                    onClickPrevPage={prevPage}
                    onClickNextPage={nextPage}
                    pageNumberLabelId="extractor.test.pagination"
                  />
                </div>
              </div>
            </div>
          ) : (
            <ImageViewer
              src={imageViewerSrc}
              boundingBoxes={selectedRenderDocument?.boundingBoxes}
              zoomControlEnabled={true}
              bottomOverlay={
                <OverlayPaginator
                  currentPage={selectedResultIndex + 1}
                  totalPage={totalImageCount}
                  onClickPrevPage={prevPage}
                  onClickNextPage={nextPage}
                  pageNumberLabelId="extractor.test.pagination"
                />
              }
            />
          )}
        </div>
      }
      rightFlexGrow={1.5}
      rightMinWidth={546}
      right={
        <div className={styles["json-content"]}>
          <div className={styles["action-button-group"]}>
            <ActionButtonTabBar
              tabs={tabs}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
            <div className="flex flex-row gap-[4px] items-center mb-[2px]">
              {showExtractorIdTooltip &&
                selectedResult?.extractor_id !== undefined && (
                  <TooltipHost
                    content={
                      <p>
                        <FormattedMessage
                          id="extractor.test.extracted_by"
                          values={{
                            form_id: (
                              <span className="text-primary-500 underline">
                                <Link
                                  href={`/form/${selectedResult.extractor_id}`}
                                  target="_blank"
                                >
                                  {selectedResult.extractor_id}
                                </Link>
                              </span>
                            ),
                          }}
                        />
                      </p>
                    }
                    calloutProps={{
                      coverTarget: true,
                    }}
                  >
                    <Icon iconName="info" />
                  </TooltipHost>
                )}
              <ActionButton
                iconName="Download"
                textId="extractor.test.download_json"
                onClick={downloadJson}
              />
              <PrimaryButton
                id={ButtonId.ExtractorTestPanelReuploadButton}
                textId="extractor.test.reupload"
                onClick={reupload}
              />
            </div>
          </div>
          {viewMode === ViewMode.OCR ? (
            <div
              className={cntl`
                  overflow-y-auto
                  flex-1
                  flex flex-col
                `}
            >
              <TextAreaCopyBlock content={selectedResult?.ocr ?? ""} />
            </div>
          ) : viewMode === ViewMode.LLM ? (
            <div className={styles["llm-prompt-container"]}>
              {selectedResult?.llm_prompt?.map((prompt, index) => (
                <pre key={index}>
                  <CopyButton
                    className={styles["copy-button"]}
                    text={prompt.prompt}
                    showText={true}
                  />
                  <div className={styles["llm-prompt-id"]}>
                    <span>{prompt.id}</span>
                  </div>
                  {prompt.prompt}
                </pre>
              ))}
            </div>
          ) : viewMode === ViewMode.Json || hasTransformResponseScript ? (
            <div className={styles["raw-json-container"]}>
              <JsonRawViewer rawObject={highlightedJson} />
            </div>
          ) : viewMode === ViewMode.ImageQuality &&
            selectedResult?.metadata?.image_quality != null ? (
            <div className={styles["raw-json-container"]}>
              <JsonRawViewer
                rawObject={selectedResult?.metadata?.image_quality}
              />
            </div>
          ) : selectedResult?.data === undefined ? (
            <div
              className={cntl`
              bg-[#faf9f8] text-[#323130] flex flex-col 
              justify-center text-center text-[14px] font-normal p-[10px]
              min-h-[52px]
            `}
            >
              {selectedResult?.error?.message != null ? (
                <>{selectedResult.error.message}</>
              ) : (
                <FormattedMessage id="extractor.test.result_error" />
              )}
            </div>
          ) : (
            <div className={styles["key-value-json-container"]}>
              <ExtractAPIV2ResponseViewer
                response={selectedResult}
                sourceImage={selectedRenderDocument?.url}
                isItemImageVisible={shouldDetectExtractItemImage}
                hiddenBoundingBoxIndices={
                  selectedResultHiddenBoundingBoxIndices
                }
                onToggleBoundingBox={onToggleBoundingBox}
              />
            </div>
          )}
        </div>
      }
    />
  );
}

export function ExtractorTestPanelImpl(
  props: ReturnType<typeof useExtractorTestPanel>
) {
  const {
    samples,
    onSelectSampleImage,
    selectedResult,
    onSelectImage,
    highlightedJson,
    disableExtractTableDataBottomSheet,
    hasTransformResponseScript,
  } = props;

  return (
    <div className={styles["container"]}>
      <ExternalServicesAlertBoxForForm />
      {selectedResult !== undefined ||
      (hasTransformResponseScript && highlightedJson) ? (
        <ExtractorTestResultViewer {...props} />
      ) : (
        <div className={styles["placeholder"]}>
          <OCRTestPlaceholder
            onSelectImage={onSelectImage}
            samples={samples}
            onSelectSampleImage={onSelectSampleImage ?? ((_?: string) => {})}
          />
        </div>
      )}
      {!disableExtractTableDataBottomSheet && (
        <ExtractTableDataBottomSheet
          className={styles["table-value-bottom-sheet"]}
        />
      )}
    </div>
  );
}

export function ExtractorTestPanel(props: Props) {
  const states = useExtractorTestPanel(props);
  return <ExtractorTestPanelImpl {...states} />;
}
