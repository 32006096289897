import { createReducer } from "@reduxjs/toolkit";

import * as googleAuthAction from "../actions/googleAuth";
import type { OAuthError } from "../actions/oauth";

interface GoogleAuthState {
  authError: OAuthError | null;
  isAuthorized: boolean;
  isAuthorizing: boolean;
  nonce: string | null;
}

const defaultState: GoogleAuthState = {
  authError: null,
  isAuthorized: false,
  isAuthorizing: false,
  nonce: null,
};

export const googleAuthReducer = createReducer<GoogleAuthState>(
  defaultState,
  builder => {
    builder
      .addCase(googleAuthAction.GoogleAuthReset, state => {
        state.authError = null;
        state.isAuthorized = false;
        state.isAuthorizing = false;
        state.nonce = null;
      })
      .addCase(googleAuthAction.GoogleAuthLogin, (state, action) => {
        state.authError = null;
        state.isAuthorized = false;
        state.isAuthorizing = true;
        state.nonce = action.payload.nonce;
      })
      .addCase(googleAuthAction.GoogleAuthAuthenticated, state => {
        state.authError = null;
        state.isAuthorized = true;
        state.isAuthorizing = false;
        state.nonce = null;
      })
      .addCase(
        googleAuthAction.GoogleAuthAuthenticationFailed,
        (state, action) => {
          state.authError = {
            oauthErrorId: action.payload.error,
            oauthErrorDescription: action.payload.errorDescription,
          };
          state.isAuthorized = false;
          state.isAuthorizing = false;
          state.nonce = null;
        }
      );
  }
);
