import { Palette } from "./types/palette";

export type AuthGearConfig = {
  endpoint: string;
  clientID: string;
  redirectURI: string;
};

export type AuthConfig = {
  passwordMinLength: number;
};

export type GoogleAuthConfig = {
  clientId: string;
  oauthScopes: string[];
  sheetsExportScopes: string[];
};

export type MicrosoftAuthConfig = {
  azureAppClientId: string;
};

export type LeftBarConfig = {
  hideCustomModel: boolean;
  hideTemplate: boolean;
  hideIntegration: boolean;
};

export type AdminConfig = object;

export type GTMConfig = {
  id: string;
};

export type N8NConfig = {
  endpoint: string;
};

export const AppConfig: {
  environment: string;
  admin: AdminConfig;
  auth: AuthConfig;
  googleAuth?: GoogleAuthConfig;
  microsoftAuth?: MicrosoftAuthConfig;
  authGear: AuthGearConfig | null;
  api: { endpoint: string };
  worker: { endpoint: string; shouldAsyncDisabled: boolean };
  stripe?: { apiKey: string };
  sentry: {
    dsn: string | undefined;
    release: string | undefined;
  };
  desktopApp: {
    macOSUrl: string;
    windowsUrl: string;
  };
  helpPageUrl: string;
  zapier?: {
    createAIModelRequestUrl: string;
  };
  leftBar: LeftBarConfig;
  region: string;
  provider?: string;
  cookieDomain?: string;
  maxUploadImageSize: number | undefined;
  enableAbbyy?: boolean;
  hideTeamSelectionMenu?: boolean;
  shouldTawkDisabled?: boolean;
  theme?: Partial<Palette>;
  documentTitle?: string;
  usageDateRangeSelectorMinDate?: number;
  auditLogRetentionPeriod: number;
  labellerUrl: string;
  labellerAccessTokenCookieKey?: string;
  disabledOCREngines?: ("google" | "azure" | "azure-v4")[];
  disabledOCRAPI?: { google?: string[]; azure?: string[] };
  disableCustomModel?: boolean;
  useCustomEndpointForDesktopApp?: boolean;
  gtm?: GTMConfig;
  skipOnboardingSurvey?: boolean;
  n8n?: N8NConfig;
  testLab: {
    docPagesThresholdToEnableAsync: number;
    expectedDocCountPerPage: number;
  };
} = {
  region: "default",
  admin: {},
  auth: {
    passwordMinLength: 8,
  },
  leftBar: {
    hideCustomModel: false,
    hideTemplate: false,
    hideIntegration: false,
  },
  auditLogRetentionPeriod: 12,
  labellerUrl: window.location.origin,
  testLab: { docPagesThresholdToEnableAsync: 4, expectedDocCountPerPage: 4 },
  ...(window as any).appConfig,
};

export const RegionsConfig: {
  teamLookupRegion: string;
  endpoints: {
    [Key: string]: {
      api: string;
      portal: string;
      worker: string;
    };
  };
} = {
  teamLookupRegion: AppConfig.region,
  endpoints: {
    [AppConfig.region]: {
      api: AppConfig.api.endpoint,
      portal: window.location.origin,
      worker: AppConfig.worker.endpoint,
    },
  },
  ...((window as any)?.regionsConfig || {}),
};

export function getRegionKeys(): string[] {
  return Object.keys(RegionsConfig.endpoints);
}
