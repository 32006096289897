import { DateTime } from "luxon";
import * as React from "react";
import { useSelector } from "react-redux";

import { AppConfig } from "../config";
import {
  DEFAULT_GOOGLE_SHEET_NAME,
  DEFAULT_GOOGLE_SPREADSHEET_SHEET_NAME,
} from "../constants/googleSheet";
import { useExtractorFieldSchema } from "../hooks/extractorFieldSchema";
import { useGoogleSheet } from "../hooks/googleSheet";
import { useOAuthCredential } from "../hooks/oauth";
import { useUnsafeParams } from "../hooks/params";
import {
  useCommonWorkspaceContainerState,
  useWaitUntilWorkspaceLoaded,
} from "../hooks/workspace";
import { useWorkspaceWebhookResource } from "../hooks/workspaceWebhook";
import {
  useGoogleSheetExportListResource,
  useGoogleSheetIntegration,
} from "../hooks/workspace_integration";
import { PathParam } from "../models";
import { RootState } from "../redux/types";
import { useLocale } from "./locale";

const PREBUILD_FIELDS = [
  "$metadata/extraction_result_lookup_id",
  "$metadata/filename",
  "$metadata/uploaded_at",
];

function useMakeContext() {
  const googleSheetIntegrationProps = useGoogleSheetIntegration();
  const oauthProps = useOAuthCredential();

  const { listAllOAuthCredentials } = oauthProps;

  const { workspaceId } = useUnsafeParams<PathParam>();
  const { workspace } = useCommonWorkspaceContainerState(workspaceId);
  const { workspaceWebhooks } = useSelector((state: RootState) => {
    return {
      workspaceWebhooks: state.workspaceWebhook.workspaceWebhooks,
    };
  });

  const { queryWorkspaceWebhook } = useWorkspaceWebhookResource();

  const { listGoogleSheetColumns, listGoogleSpreadsheets } = useGoogleSheet();

  const {
    queryGoogleSheetExportList,
    googleSheetExportList,
    deleteGoogleSheetExport,
    createWorkspaceGoogleSheetExport,
    updateGoogleSheetExport,
  } = useGoogleSheetExportListResource();

  // Login to Google
  const googleAuth = React.useCallback(async () => {
    if (AppConfig.googleAuth === undefined) {
      throw new Error("AppConfig.googleAuth not configured");
    }

    // No need to set authorizing state. That was handled by useGoogleAuth
    const googleAuthData = await googleSheetIntegrationProps.googleAuth([]);

    const { oauthCredentials } = await listAllOAuthCredentials("google");
    const item = oauthCredentials.find(
      item => item.email === googleAuthData.email
    );

    return item;
  }, [googleSheetIntegrationProps, listAllOAuthCredentials]);

  const { queryExtractorFieldSchemaFields } = useExtractorFieldSchema();

  const [extractorSchemaFields, setExtractorSchemaFields] = React.useState<
    string[]
  >([]);

  // It is a dirty hack for an async function to wait until a
  // condition is met. It is not a good practice.
  // Function like useCommonWorkspaceContainerState should return
  // a load function for consumer to call it explicitly.
  const waitUntilWorkspaceLoaded = useWaitUntilWorkspaceLoaded(workspace);

  // Don't call network requests when mounting, that may cause unexpected behavior
  // in storybook. Instead, call it when the component is mounted in container.
  const load = React.useCallback(async () => {
    const workspace = await waitUntilWorkspaceLoaded();
    if (workspace.state !== "success") {
      return;
    }

    queryWorkspaceWebhook(workspaceId);

    const loadGoogleSheetExportList = async () => {
      const promises = [
        queryGoogleSheetExportList(workspaceId),
        listAllOAuthCredentials("google"),
      ];
      const fields = await queryExtractorFieldSchemaFields(
        workspace.data.extractor?.id ?? ""
      );
      const fieldsToUse = [...PREBUILD_FIELDS];
      fields.forEach(field => {
        if (field.properties) {
          fieldsToUse.push(
            ...field.properties.map(property => `${field.name}/${property}`)
          );
        } else {
          fieldsToUse.push(field.name);
        }
      });

      setExtractorSchemaFields(fieldsToUse);
      await Promise.all(promises);
    };
    await loadGoogleSheetExportList();
  }, [
    queryWorkspaceWebhook,
    queryGoogleSheetExportList,
    listAllOAuthCredentials,
    workspaceId,
    queryExtractorFieldSchemaFields,
    waitUntilWorkspaceLoaded,
  ]);

  const { localized } = useLocale();

  const generateGoogleSpreadsheetName = React.useCallback(() => {
    return workspace.state === "success"
      ? localized("workspace.integrations.default_spreadsheet_name", {
          workspace: workspace.data.config.name,
          date: DateTime.now().toFormat("yyyy-MM-dd"),
        })
      : DEFAULT_GOOGLE_SPREADSHEET_SHEET_NAME;
  }, [workspace, localized]);

  const createNewGoogleSheet = React.useCallback(
    async (oauthCredentialId: string) => {
      const spreadsheetName = generateGoogleSpreadsheetName();

      return await googleSheetIntegrationProps.createNewGoogleSheet(
        oauthCredentialId,
        spreadsheetName,
        [
          {
            name: DEFAULT_GOOGLE_SHEET_NAME,
            columns: [...extractorSchemaFields],
          },
        ]
      );
    },
    [
      googleSheetIntegrationProps,
      extractorSchemaFields,
      generateGoogleSpreadsheetName,
    ]
  );

  return React.useMemo(
    () => ({
      isLoading: workspace.state === "loading",
      workspace,
      workspaceWebhooks,
      googleAuth,
      googleSheetIntegrationProps,
      oauthProps,
      googleSheetExportList,
      load,
      deleteGoogleSheetExport,
      createWorkspaceGoogleSheetExport,
      updateGoogleSheetExport,
      extractorSchemaFields,
      listGoogleSheetColumns,
      listGoogleSpreadsheets,
      createNewGoogleSheet,
    }),
    [
      workspace,
      workspaceWebhooks,
      googleSheetIntegrationProps,
      oauthProps,
      googleAuth,
      googleSheetExportList,
      load,
      deleteGoogleSheetExport,
      createWorkspaceGoogleSheetExport,
      updateGoogleSheetExport,
      extractorSchemaFields,
      listGoogleSheetColumns,
      listGoogleSpreadsheets,
      createNewGoogleSheet,
    ]
  );
}

export type WorkspaceIntegrationContextValue = ReturnType<
  typeof useMakeContext
>;
const WorkspaceIntegrationContext = React.createContext<
  WorkspaceIntegrationContextValue | undefined
>(undefined);

interface Props {
  children: React.ReactNode;
}

export const WorkspaceIntegrationProvider = (props: Props) => {
  const value = useMakeContext();
  return <WorkspaceIntegrationContext.Provider {...props} value={value} />;
};

export function useWorkspaceIntegration() {
  const context = React.useContext(WorkspaceIntegrationContext);
  if (context === undefined) {
    throw new Error(
      "useWorkspaceIntegrationContainerContext must be used within a WorkspaceIntegrationContainerProvider"
    );
  }
  return context;
}
