import { GoogleSheetFieldMapping } from "../types/googleSheet";

export type GoogleSheetFieldMappingValidationResult = {
  hasError: boolean;
  // The column index without field name assigned
  missingFieldAssignedColumnIndices: number[];
};

export class GoogleSheetFieldMappingValidator {
  validate(mappings: GoogleSheetFieldMapping[]) {
    const result: GoogleSheetFieldMappingValidationResult = {
      hasError: false,
      missingFieldAssignedColumnIndices: [],
    };

    mappings.forEach(mapping => {
      if (mapping.fieldName == null || mapping.fieldName.trim() === "") {
        result.hasError = true;
        result.missingFieldAssignedColumnIndices.push(mapping.columnIndex);
      }
    });

    return result;
  }
}
