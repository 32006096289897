import { MessageBar, MessageBarType } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useSelector } from "react-redux";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { ExtractorTestPanel } from "../components/ExtractorTestPanel";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";
import { useExtractTestV2 } from "../hooks/extract_test";
import { useUnsafeParams } from "../hooks/params";
import { RootState } from "../redux/types";
import { ProcessingMode } from "../types/processingMode";
import {
  CustomModelNavBarLayout,
  CustomModelNavTabKey,
} from "./CustomModelNavBarLayout";

type PathParam = {
  customModelId: string;
};

function _CustomModelTestContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  const containerState = useCommonCustomModelContainerState(customModelId);

  const options = {
    shouldOutputOrientation: true,
  };

  const { currentForm: underlyingForm } = useSelector(
    (state: RootState) => state.form
  );

  const hasTransformResponseScript =
    containerState.state === "success" &&
    containerState.customModel?.formID === underlyingForm?.id
      ? !!underlyingForm.config.transform_response_script?.trim()
      : false;

  const multipleDocumentsPerPage =
    containerState.state === "success" &&
    containerState.customModel?.formID === underlyingForm?.id
      ? underlyingForm.config.processing_mode ===
        ProcessingMode.MultipleDocumentsPerPage
      : false;

  const {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    extractResult,
    extractImageSource,
    selectedResultIndex,
    setSelectedResultIndex,
  } = useExtractTestV2(
    containerState.state === "success" && containerState.customModel.formID
      ? {
          extractorId: containerState.customModel.formID,
          options,
          hasTransformResponseScript,
          multipleDocumentsPerPage,
        }
      : {
          extractorId: undefined,
          options,
          hasTransformResponseScript,
          multipleDocumentsPerPage,
        }
  );

  const hasDeployedModel =
    containerState.state === "success" &&
    containerState.customModel.modelVersions.find(x => x.isActive) !==
      undefined;

  const shouldShowWarning =
    containerState.state === "success" &&
    !containerState.isFSLModel &&
    (containerState.isDeploymentInProgress || !hasDeployedModel);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        ) : containerState.state === "success" ? (
          <>
            <CustomModelNavBarLayout
              selectedTab={CustomModelNavTabKey.TestExtractor}
              customModel={containerState.customModel}
            >
              {shouldShowWarning && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  <FormattedMessage
                    id={
                      containerState.isDeploymentInProgress
                        ? "custom_model_test.warn.deployment_in_progress"
                        : "custom_model_test.warn.no_deployed_model"
                    }
                  />
                </MessageBar>
              )}
              <ExtractorTestPanel
                resourceName={containerState.customModel.name}
                onSelectImage={extractWithFile}
                onSelectSampleImage={extractWithURL}
                extractResult={extractResult}
                extractImageSource={extractImageSource}
                hasTransformResponseScript={hasTransformResponseScript}
                selectedResultIndex={selectedResultIndex}
                setSelectedResultIndex={setSelectedResultIndex}
              />
            </CustomModelNavBarLayout>
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        ) : null}
      </Main>
    </Layout>
  );
}

export const CustomModelTestContainer = React.memo(_CustomModelTestContainer);
export default CustomModelTestContainer;
