export const SUPPORTED_IMAGE_MIME = ["image/x-png", "image/png", "image/jpeg"];

export const SUPPORTED_EXTRACT_MIME = [
  ...SUPPORTED_IMAGE_MIME,
  "application/pdf",
  "image/bmp",
  "image/tiff",
  "image/webp",
  "image/heif",
  "image/heic",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const UNSUPPORTED_PREVIEW_MIME = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const SUPPORTED_BROWSER_COMBINATIONS = [
  { browser: "Chrome", version: "76" },
  { browser: "Edge", version: "80" },
  { browser: "Firefox", version: "68" },
  { browser: "Safari", version: "13" },
];
export const MAX_RECEIPT_GROUPS = 100;
