import { FormConfig, FormSettings } from "../types/formConfig";
import { deepEqual } from "./deepEqual";

export function settingsToConfig(settings: FormSettings): FormConfig {
  return {
    detector: {
      type: "orb",
      number_of_source_feature: settings.numFormFeature,
      number_of_query_feature: settings.numQueryFeature,
    },
    index_param: {
      algorithm: 6,
      table_number: 6,
      key_size: 12,
      multi_probe_level: 1,
    },
    search_param: {
      checks: 50,
    },
    document_type: settings.documentType,
    auto_extraction_items: settings.autoExtractionItems,
    post_process_script: settings.postProcessScript,
    transform_response_script: settings.transformResponseScript,
    date_format: settings.dateFormat,
    date_input_format: settings.dateInputFormat,
    merchant_names: settings.merchantNames,
    formatter: settings.formatter,
    llm_completions: settings.llmCompletions,
    auto_extraction_item_config: settings.autoExtractionItemConfig,
  };
}

export function isDetectorSettingChanged(
  config: FormConfig,
  originalConfig: FormConfig
): boolean {
  return (
    !deepEqual(config.search_param, originalConfig.search_param) ||
    !deepEqual(config.index_param, originalConfig.index_param) ||
    !deepEqual(config.detector, originalConfig.detector)
  );
}
