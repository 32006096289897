import React from "react";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { CancelError } from "../actions/googleAuth";
import {
  ConnectedApp,
  ConnectedAppsSection,
} from "../components/ConnectedAppsSection";
import { Layout, Main, Top } from "../components/Layout";
import { FOCRError } from "../errors";
import {
  useListenWindowEvent,
  useOnload,
} from "../hooks/asyncguard/asyncguard";
import { useGoogleAuth } from "../hooks/google_auth";
import {
  DEFAULT_CONNECTED_APPS_PAGE_SIZE,
  useOAuthCredentialListResource,
} from "../hooks/oAuthCredentialListResource";
import { useOAuthCredential } from "../hooks/oauth";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";
import { ConfirmModalType } from "../types/confirmation";
import HeaderContainer from "./Header";

type ConnectAppResourceQueryParms = {
  page: number;
};

function getQueryParams(): ConnectAppResourceQueryParms {
  const url = new URL(document.location.href);
  const page = parseInt(url.searchParams.get(URLParamsKey.page) || "1") || 1;
  return { page };
}

function useConnectedAppsContainerState() {
  const oAuthCredentialListResource = useOAuthCredentialListResource();
  const { deleteOAuthCredential } = useOAuthCredential();

  const initialQueryParams = React.useMemo(() => getQueryParams(), []);
  const [currentPage, setCurrentPage] = React.useState(initialQueryParams.page);
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  const { setParam } = useSearchParamUtils();

  const connectedApps = React.useMemo(() => {
    return oAuthCredentialListResource.resource.oauthCredentials.map(
      credential => {
        return {
          id: credential.id,
          name: "Google",
          account: credential.email,
          status: credential.status,
        } as ConnectedApp;
      }
    );
  }, [oAuthCredentialListResource]);

  const pageCount = oAuthCredentialListResource.resource.pageInfo.totalCount;

  const refresh = React.useCallback(async () => {
    const currentPage = getQueryParams().page;
    await oAuthCredentialListResource.query(currentPage);
    setIsLoaded(true);
  }, [oAuthCredentialListResource]);

  useOnload(() => {
    refresh();
  });

  useListenWindowEvent("popstate", () => {
    refresh();
    const { page } = getQueryParams();
    setParam(URLParamsKey.page, page.toString(), false);
    setCurrentPage(page);
  });

  const navigateToPage = React.useCallback(
    (page: number) => {
      setParam(URLParamsKey.page, page.toString(), false);
      setCurrentPage(page);
      oAuthCredentialListResource.query(page);
    },
    [oAuthCredentialListResource, setParam]
  );

  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const toast = useToast();

  const requestToDeleteOAuthCredential = React.useCallback(
    async (id: string) => {
      const confirm = await requestUserConfirmation(
        {
          titleId: "connect_apps_table.remove_dialog.title",
          messageId: "connect_apps_table.remove_dialog.message",
          actionId: "connect_apps_table.remove_dialog.action",
          type: ConfirmModalType.Destory,
        },
        false
      );
      if (confirm) {
        try {
          await deleteOAuthCredential(id);
          await refresh();
        } catch (error) {
          console.error(error);

          toast.error(
            error && error instanceof FOCRError
              ? error.messageId
              : typeof error === "string"
              ? error
              : "error.oauth.unknown_error"
          );
        }
      }
    },
    [deleteOAuthCredential, refresh, requestUserConfirmation, toast]
  );
  const { googleAuth } = useGoogleAuth();

  const retryGoogleOAuth = React.useCallback(async () => {
    try {
      await googleAuth([]);
      await refresh();
    } catch (error) {
      if (error instanceof CancelError) {
        return;
      }
      console.log(error);

      toast.error(
        error && error instanceof FOCRError
          ? error.messageId
          : typeof error === "string"
          ? error
          : "error.oauth.unknown_error"
      );
    }
  }, [googleAuth, refresh, toast]);

  return {
    currentPage,
    connectedApps,
    pageCount,
    navigateToPage,
    requestToDeleteOAuthCredential,
    retryGoogleOAuth,
    isLoaded,
  };
}

const ConnectedAppsContainer = () => {
  const {
    connectedApps,
    pageCount,
    currentPage,
    navigateToPage,
    requestToDeleteOAuthCredential,
    retryGoogleOAuth,
    isLoaded,
  } = useConnectedAppsContainerState();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <ConnectedAppsSection
          pageCount={pageCount}
          currentPage={currentPage}
          pageSize={DEFAULT_CONNECTED_APPS_PAGE_SIZE}
          apps={connectedApps}
          navigateToPage={navigateToPage}
          requestToDeleteOAuthCredential={requestToDeleteOAuthCredential}
          retryGoogleOAuth={retryGoogleOAuth}
          isLoaded={isLoaded}
        />
      </Main>
    </Layout>
  );
};

export default ConnectedAppsContainer;
