import * as React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { useCustomModelActionCreator } from "../actions/customModel";
import {
  MultiChoiceModalResponseType,
  useMultiChoiceModalHandle,
} from "../components/MultiChoiceModal";
import {
  FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT,
  SUPPORTED_EXTRACT_MIME,
} from "../constants";
import errors, { FOCRError } from "../errors";
import { useWorkerToken } from "../hooks/app";
import { useFetchFSLCustomModelImages } from "../hooks/customModelImages";
import { useGtm } from "../hooks/gtm";
import { useAppSelector } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";
import {
  FSLCustomModelSessionResult,
  OCRTestReportSingleDocument,
} from "../models";
import { PdfService } from "../services/pdfservice";
import { UploadFileKeeperService } from "../services/uploadFileKeeper";
import {
  CustomModelImageExtraInfo,
  CustomModelNotificationType,
} from "../types/customModel";
import { CustomModelImage } from "../types/customModelImage";
import { FormExtractionMode } from "../types/form";
import { chooseFile } from "../utils/file";
import { WorkerClient } from "../workerClient";
import { useFSLCustomModelEditor } from "./fslCustomModelEditor";

export enum ActionType {
  ContactFormX,
  UseStandardModel,
}

export type ProcessingImage = {
  name: string;
  file: File | undefined;
  image: string;
  ocr: string | string[];
  groupId?: string | null;
};

function useMakeContext() {
  const {
    customModel,
    customModelId,
    navigateToStandardModel,
    updateIsDocumentTooComplex,
    localStorage,
    setPendingNotification,
    triggerGetHelpFromFormXProcess,
    isLoading: isFSLCustoModelEditorLoading,
    isStandardModelEnabled,
  } = useFSLCustomModelEditor();

  const {
    uploadCustomModelImages,
    enableStandardModel: triggerEnableStandardModel,
    patchFslUploadingSampleImagesState,
  } = useCustomModelActionCreator();

  const { customModelImages } = useFetchFSLCustomModelImages(customModel);
  const { token } = useWorkerToken();

  const toast = useToast();

  const userInfo = useAppSelector(state => {
    return state.user.currentUser;
  });

  const isReadyToUseOnce =
    customModel?.config.fslModelState?.isReadyToUseOnce ?? false;

  const isReadyToUse = React.useMemo(() => {
    // Due to the requirement change, the ready_to_use flag in FSLModelState can not
    // be used to determine whether the model is ready to use or not.
    // Instead, we should check the sample image state - Ben Lau
    if (customModelImages === undefined || customModelImages.length === 0) {
      return false;
    }

    return !customModelImages.some(image => {
      return image.state !== "reviewed";
    });
  }, [customModelImages]);

  const noOfSampleImages = customModelImages ? customModelImages.length : 0;

  const [searchParams, setSearchParams] = useSearchParams();
  const shouldShowOnboardingBubble = React.useMemo(
    () => searchParams.get("onboarding") === "1",
    [searchParams]
  );

  const dismissOnboardingBubble = React.useCallback(() => {
    setSearchParams(
      params => {
        params.delete("onboarding");
        return params;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  const [shouldShowUploadingSampleStatus, setShouldShowUploadingSampleStatus] =
    React.useState(false);

  const [isProcessing, setIsProcessing] = React.useState(false);

  const isDeletingCustomModelImages = useAppSelector(state => {
    const ids =
      state.customModel.deletingCustomModelImageIds[customModelId] ?? [];

    return customModelImages?.some(i => ids.includes(i.id)) ?? false;
  });

  const isLoading =
    isFSLCustoModelEditorLoading ||
    isDeletingCustomModelImages ||
    customModelImages === undefined;

  const finishUploadingAllSamplesPromiseRef = React.useRef(Promise.resolve());

  const saveProcessingImage = React.useCallback(
    async (
      file: File,
      fslInput: string | string[],
      fslOutput: FSLCustomModelSessionResult
    ): Promise<CustomModelImage> => {
      if (!customModel) {
        return Promise.reject();
      }
      const noOfSampleImages = customModelImages?.length ?? 0;
      if (noOfSampleImages >= FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT) {
        return Promise.reject();
      }

      const localStorageInfo = localStorage.getItem(customModel.id);

      if (
        noOfSampleImages === 0 &&
        !localStorageInfo.hasUploadedFirstImage &&
        userInfo !== undefined
      ) {
        localStorageInfo.hasUploadedFirstImage = true;
        localStorage.setItem(customModel.id, localStorageInfo);
        setPendingNotification({
          type: CustomModelNotificationType.UploadedFirstImage,
        });
      }

      const pdfInfo = await PdfService.getInstance().getPDFInfo(file);

      const info = {
        fslInput: fslInput,
        fslOutput: JSON.stringify(fslOutput),
        isExtractionDisabled: true,
      } as CustomModelImageExtraInfo;

      patchFslUploadingSampleImagesState(
        customModel.id,
        {
          totalPagesToUpload: pdfInfo.isPdf ? pdfInfo.numPages : 1,
          numberOfUploadedPage: 0,
          numberOfPageFailedToUpload: 0,
        },
        true
      );

      let onAllFilesProcessed: (() => void) | undefined = undefined;
      finishUploadingAllSamplesPromiseRef.current = new Promise<void>(
        resolve => {
          onAllFilesProcessed = resolve;
        }
      );

      return await new Promise((resolve, reject) => {
        uploadCustomModelImages(customModel.id, [file], info, {
          onUploadedAnImage: uploadedFile => {
            patchFslUploadingSampleImagesState(
              customModel.id,
              {
                numberOfUploadedPage: 1,
              },
              false
            );
            resolve(uploadedFile);
          },
          onFailedToUploadAnImage: error => {
            patchFslUploadingSampleImagesState(
              customModel.id,
              {
                numberOfPageFailedToUpload: 1,
              },
              false
            );
            reject(error);
          },
          onAllFilesProcessed,
        });
      });
    },
    [
      customModel,
      uploadCustomModelImages,
      setPendingNotification,
      customModelImages,
      localStorage,
      userInfo,
      patchFslUploadingSampleImagesState,
    ]
  );

  const navigate = useNavigate();

  const reviewExtractionResult = React.useCallback(
    (
      customModelImageId: string,
      _isReviewed: boolean,
      _groupId?: string | null
    ) => {
      navigate(
        `/custom-model/${customModelId}/instant-model/${customModelImageId}`
      );
    },
    [customModelId, navigate]
  );

  const enableStandardModalHandle = useMultiChoiceModalHandle();

  const requestToEnableStandardModel = React.useCallback(async () => {
    if (!customModel) {
      return;
    }
    const res = await enableStandardModalHandle.methods.open();
    if (res.type === MultiChoiceModalResponseType.Cancelled) {
      return;
    }

    if (res.acceptedValue === ActionType.ContactFormX) {
      triggerGetHelpFromFormXProcess();
      return;
    }

    if (res.acceptedValue === ActionType.UseStandardModel) {
      if (!isStandardModelEnabled) {
        await triggerEnableStandardModel(customModel);
      }
      navigateToStandardModel();
    }
  }, [
    enableStandardModalHandle.methods,
    isStandardModelEnabled,
    triggerEnableStandardModel,
    customModel,
    navigateToStandardModel,
    triggerGetHelpFromFormXProcess,
  ]);

  const { pushCustomFslUploadedSampleEvent } = useGtm();

  const uploadSampleImage = React.useCallback(
    (image: File) => {
      if (!token || !customModel || isProcessing) {
        return;
      }
      const formId = customModel.formID;
      if (!formId) {
        return;
      }
      setIsProcessing(true);
      setShouldShowUploadingSampleStatus(true);

      const run = async () => {
        const workerClient = new WorkerClient(token);

        const processingImage = {
          name: image.name,
          image: "",
          file: undefined,
          ocr: "",
        } as ProcessingImage;

        try {
          // It is still using V1 API. In case we need to upgrade to V2
          // You must disable formatter to get valid output for FSL
          // learning
          const result = await workerClient.extractFieldInTestMode(
            formId,
            image,
            FormExtractionMode.combineMultiPagePdf,
            () => {},
            true,
            {
              shouldOutputOcr: "preserve-whitespace",
            }
          );
          pushCustomFslUploadedSampleEvent(formId, noOfSampleImages + 1);
          const report = result as OCRTestReportSingleDocument;
          const extractionResult = report.custom_models?.[0]
            .result as FSLCustomModelSessionResult;
          processingImage.file = image;
          processingImage.ocr = report.ocr ?? "";
          const uploadedCustomModelImage = await saveProcessingImage(
            processingImage.file,
            processingImage.ocr,
            extractionResult
          );
          processingImage.image = uploadedCustomModelImage.url;
          processingImage.groupId = uploadedCustomModelImage.info?.group_id;

          let query = "";
          if (noOfSampleImages === 0) {
            // Show onboarding teaching bubble for first uploaded sample.
            query = "?onboarding=1";
          }

          navigate(
            `/custom-model/${customModelId}/instant-model/${uploadedCustomModelImage.id}${query}`
          );
        } catch (e) {
          if (e instanceof FOCRError) {
            if (e === errors.LLMTokenLimitExceeded) {
              await updateIsDocumentTooComplex(true);
            } else if (e === errors.ErrorPaymentRequired) {
              toast.error("error.usage_reached_hard_limit");
            } else {
              toast.error(e.messageId, undefined, e.detail);
            }
          } else {
            toast.error("error.unknown_error");
          }
        }
        setIsProcessing(false);
        setShouldShowUploadingSampleStatus(false);
      };

      run();
    },
    [
      token,
      customModel,
      isProcessing,
      noOfSampleImages,
      updateIsDocumentTooComplex,
      toast,
      saveProcessingImage,
      navigate,
      customModelId,
      pushCustomFslUploadedSampleEvent,
    ]
  );

  const requestToUploadSampleImage = React.useCallback(() => {
    const onFiles = (files?: File[]) => {
      if (!files || files.length === 0) {
        toast.error("form.editor.no.image.missing.image");
        return;
      }
      uploadSampleImage(files[0]);
    };

    chooseFile(SUPPORTED_EXTRACT_MIME.join(","))
      .then(onFiles)
      .catch(() => {});
  }, [uploadSampleImage, toast]);

  const { getParam } = useSearchParamUtils();

  const uploadSampleImageIfQueryKeySet = React.useCallback(async () => {
    const fileKey = getParam(URLParamsKey.file);
    if (!fileKey) {
      return;
    }

    const file = UploadFileKeeperService.getInstance().getFile(fileKey);
    if (!file) {
      return;
    }
    UploadFileKeeperService.getInstance().deleteFile(fileKey);
    uploadSampleImage(file);
  }, [getParam, uploadSampleImage]);

  return React.useMemo(
    () => ({
      isReadyToUseOnce,
      noOfSampleImages,
      isProcessing,
      uploadSampleImage,
      requestToUploadSampleImage,
      reviewExtractionResult,
      customModelImages,
      isLoading,
      enableStandardModalHandle,
      requestToEnableStandardModel,
      shouldShowUploadingSampleStatus,
      isReadyToUse,
      uploadSampleImageIfQueryKeySet,
      shouldShowOnboardingBubble,
      dismissOnboardingBubble,
    }),
    [
      isReadyToUseOnce,
      noOfSampleImages,
      isProcessing,
      uploadSampleImage,
      requestToUploadSampleImage,
      reviewExtractionResult,
      customModelImages,
      isLoading,
      enableStandardModalHandle,
      requestToEnableStandardModel,
      shouldShowUploadingSampleStatus,
      isReadyToUse,
      uploadSampleImageIfQueryKeySet,
      shouldShowOnboardingBubble,
      dismissOnboardingBubble,
    ]
  );
}

type FSLInstantModelViewerContextValue = ReturnType<typeof useMakeContext>;
const FSLInstantModelViewerContext =
  React.createContext<FSLInstantModelViewerContextValue>(null as any);

interface Props {
  children: React.ReactNode;
}

export const FSLInstantModelViewerProvider = (props: Props) => {
  const value = useMakeContext();
  return <FSLInstantModelViewerContext.Provider {...props} value={value} />;
};

export function useFSLInstantModelViewerContainer() {
  return React.useContext(FSLInstantModelViewerContext);
}
