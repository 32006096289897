import {
  Checkbox,
  IButtonProps,
  ITextFieldProps,
  Icon,
  Label,
  TeachingBubble,
  TextField,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import { PlanQuota } from "../../types/quota";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";

type LimitFields = "softLimit" | "hardLimit" | "usageCap";

interface SetLimitsProps {
  rawQuota: PlanQuota | null;
  planQuota: PlanQuota | null;
  setQuota: (
    softLimit: number | null,
    hardLimit: number | null,
    usageCap: number | null
  ) => Promise<void>;
}

export function SetLimits(props: SetLimitsProps) {
  const { rawQuota, planQuota, setQuota } = props;
  const { localized } = useLocale();

  const defaultQuota = useMemo<Record<LimitFields, number | null>>(
    () => ({
      softLimit: planQuota?.monthly?.softLimit ?? null,
      hardLimit: planQuota?.monthly?.hardLimit ?? null,
      usageCap: planQuota?.monthly?.usageCap ?? null,
    }),
    [planQuota]
  );
  const [draftQuota, setDraftQuota] = useState<
    Record<LimitFields, string | null>
  >({ softLimit: null, hardLimit: null, usageCap: null });
  const [draftIsDefault, setDraftIsDefault] = useState<
    Record<LimitFields, boolean | null>
  >({ softLimit: null, hardLimit: null, usageCap: null });

  const displayQuota = useMemo<Record<LimitFields, number | string>>(
    () => ({
      softLimit:
        draftQuota.softLimit ??
        rawQuota?.monthly?.softLimit ??
        planQuota?.monthly?.softLimit ??
        "",
      hardLimit:
        draftQuota.hardLimit ??
        rawQuota?.monthly?.hardLimit ??
        planQuota?.monthly?.hardLimit ??
        "",
      usageCap:
        draftQuota.usageCap ??
        rawQuota?.monthly?.usageCap ??
        planQuota?.monthly?.usageCap ??
        "",
    }),
    [draftQuota, rawQuota, planQuota]
  );
  const displayIsDefault = useMemo<Record<LimitFields, boolean>>(
    () => ({
      softLimit:
        draftIsDefault.softLimit ?? rawQuota?.monthly?.softLimit == null,
      hardLimit:
        draftIsDefault.hardLimit ?? rawQuota?.monthly?.hardLimit == null,
      usageCap: draftIsDefault.usageCap ?? rawQuota?.monthly?.usageCap == null,
    }),
    [draftIsDefault, rawQuota]
  );

  const isDirty = useMemo(() => {
    return (
      draftQuota.hardLimit != null ||
      draftQuota.softLimit != null ||
      draftQuota.usageCap != null ||
      draftIsDefault.hardLimit != null ||
      draftIsDefault.softLimit != null ||
      draftIsDefault.usageCap != null
    );
  }, [draftQuota, draftIsDefault]);

  const canSubmit = useMemo(() => {
    return (
      isDirty &&
      (displayIsDefault.softLimit || !isNaN(Number(displayQuota.softLimit))) &&
      (displayIsDefault.hardLimit || !isNaN(Number(displayQuota.hardLimit))) &&
      (displayIsDefault.usageCap || !isNaN(Number(displayQuota.usageCap)))
    );
  }, [isDirty, displayQuota, displayIsDefault]);

  const handleSoftLimitOnQuotaChange = useCallback(
    (value: string) => setDraftQuota(d => ({ ...d, softLimit: value })),
    []
  );
  const handleSoftLimitOnIsDefaultChange = useCallback(
    (value: boolean) => setDraftIsDefault(d => ({ ...d, softLimit: value })),
    []
  );
  const handleHardLimitOnQuotaChange = useCallback(
    (value: string) => setDraftQuota(d => ({ ...d, hardLimit: value })),
    []
  );
  const handleHardLimitOnIsDefaultChange = useCallback(
    (value: boolean) => setDraftIsDefault(d => ({ ...d, hardLimit: value })),
    []
  );
  const handleUsageCapOnQuotaChange = useCallback(
    (value: string) => setDraftQuota(d => ({ ...d, usageCap: value })),
    []
  );
  const handleUsageCapOnIsDefaultChange = useCallback(
    (value: boolean) => setDraftIsDefault(d => ({ ...d, usageCap: value })),
    []
  );

  const handleOnSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      const softLimit = displayIsDefault.softLimit
        ? null
        : Number(displayQuota.softLimit);
      const hardLimit = displayIsDefault.hardLimit
        ? null
        : Number(displayQuota.hardLimit);
      const usageCap = displayIsDefault.usageCap
        ? null
        : Number(displayQuota.usageCap);

      setQuota(softLimit, hardLimit, usageCap).then(() => {
        setDraftQuota({ softLimit: null, hardLimit: null, usageCap: null });
        setDraftIsDefault({
          softLimit: null,
          hardLimit: null,
          usageCap: null,
        });
      });
    },
    [setQuota, displayIsDefault, displayQuota]
  );

  return (
    <section>
      <h3 className="pb-3 mb-2.5 border-gray-200 border-0 border-b-2 border-solid text-xl font-semibold">
        <FormattedMessage id="team.detail.subscription.set_limits.title" />
      </h3>
      <form onSubmit={handleOnSubmit}>
        <div className="flex gap-4">
          <LimitField
            className="w-[360px]"
            label={localized(
              "team.detail.subscription.set_limits.soft_limit.label"
            )}
            hint={localized(
              "team.detail.subscription.set_limits.soft_limit.hint"
            )}
            quota={displayQuota.softLimit}
            defaultQuota={defaultQuota.softLimit}
            isDefault={displayIsDefault.softLimit}
            onQuotaChange={handleSoftLimitOnQuotaChange}
            onIsDefaultChange={handleSoftLimitOnIsDefaultChange}
          />
          <LimitField
            className="w-[360px]"
            label={localized(
              "team.detail.subscription.set_limits.hard_limit.label"
            )}
            hint={localized(
              "team.detail.subscription.set_limits.hard_limit.hint"
            )}
            quota={displayQuota.hardLimit}
            defaultQuota={defaultQuota.hardLimit}
            isDefault={displayIsDefault.hardLimit}
            onQuotaChange={handleHardLimitOnQuotaChange}
            onIsDefaultChange={handleHardLimitOnIsDefaultChange}
          />
          <LimitField
            className="w-[360px]"
            label={localized(
              "team.detail.subscription.set_limits.usage_cap.label"
            )}
            hint={localized(
              "team.detail.subscription.set_limits.usage_cap.hint"
            )}
            quota={displayQuota.usageCap}
            defaultQuota={defaultQuota.usageCap}
            isDefault={displayIsDefault.usageCap}
            onQuotaChange={handleUsageCapOnQuotaChange}
            onIsDefaultChange={handleUsageCapOnIsDefaultChange}
          />
        </div>
        <PrimaryButton
          className="mt-4"
          type="submit"
          textId="team.detail.subscription.set_limits.update_limit"
          disabled={!canSubmit}
        />
      </form>
    </section>
  );
}

interface LimitFieldProps {
  className?: string;
  label: string;
  hint: string;
  quota: number | string;
  defaultQuota: number | null;
  onQuotaChange: (value: string) => void;
  isDefault: boolean;
  onIsDefaultChange: (isUnlimited: boolean) => void;
}

function LimitField(props: LimitFieldProps) {
  const {
    className,
    label,
    hint,
    quota,
    defaultQuota,
    onQuotaChange,
    isDefault,
    onIsDefaultChange,
  } = props;

  const { localized } = useLocale();

  const handleQuotaOnChange = useCallback(
    (_e, value?: string) => {
      if (value != null) {
        onQuotaChange(value);
      }
    },
    [onQuotaChange]
  );

  const handleIsDefaultOnChange = useCallback(
    (_e, value?: boolean) => {
      if (value == null) {
        return;
      }
      onIsDefaultChange(value);
    },
    [onIsDefaultChange]
  );

  const bubbleId = useId();

  const [isInfoBubbleVisible, setIsInfoBubbleVisible] = useState(false);
  const handleInfoOnClick = useCallback(() => {
    setIsInfoBubbleVisible(x => !x);
  }, []);
  const handleInfoBubbleOnDismiss = useCallback(() => {
    setIsInfoBubbleVisible(false);
  }, []);

  const handleOnRenderLabel = useCallback(
    (props?: ITextFieldProps) => {
      return (
        <Label>
          <span className="align-middle">{props?.label}</span>
          <Icon
            className="align-middle ml-1.5 w-3.5 h-3.5 text-primary-600"
            id={bubbleId}
            iconName="Info"
            onClick={handleInfoOnClick}
          />
        </Label>
      );
    },
    [bubbleId, handleInfoOnClick]
  );

  const infoButtonPrimaryButtonProps = useMemo<IButtonProps>(
    () => ({
      children: (
        <FormattedMessage id="team.detail.subscription.set_limits.info_bubble.dismiss" />
      ),
      onClick: () => setIsInfoBubbleVisible(false),
    }),
    []
  );

  return (
    <div className={className}>
      <div className="w-full flex">
        <TextField
          className="flex-1"
          label={label}
          onRenderLabel={handleOnRenderLabel}
          type="number"
          min="1"
          onChange={handleQuotaOnChange}
          value={quota == null ? "" : String(quota)}
          placeholder={defaultQuota == null ? "" : String(defaultQuota)}
          disabled={isDefault}
        />
        <span className="text-sm self-end text-gray-700">
          <FormattedMessage id="team.detail.subscription.set_limits.per_month" />
        </span>
      </div>
      <Checkbox
        className="mt-2"
        checked={isDefault}
        label={localized("team.detail.subscription.set_limits.use_default")}
        onChange={handleIsDefaultOnChange}
      />

      {isInfoBubbleVisible && (
        <TeachingBubble
          target={`#${bubbleId}`}
          onDismiss={handleInfoBubbleOnDismiss}
          primaryButtonProps={infoButtonPrimaryButtonProps}
          headline={label}
        >
          {hint}
        </TeachingBubble>
      )}
    </div>
  );
}
