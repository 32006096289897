export function getWorkerSrc() {
  return new URL(
    "npm:pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();
}

export function getCMapUrl() {
  // Caution: When changing the path, you must also update
  //  the `package.json` file
  return "/pdfjs-dist/4.4.168/cmaps/";
}
