import sampleAddressProof1 from "../images/sample/sample-address-proof-1.jpg";
import sampleAddressProof2 from "../images/sample/sample-address-proof-2.jpg";
import businessRegistrationSample1 from "../images/sample/sample-business-registration.jpg";
import hkidNewSample1 from "../images/sample/sample-hkid-new.jpg";
import hkidOldSample1 from "../images/sample/sample-hkid-old.jpg";
import sampleInvoice1 from "../images/sample/sample-invoice-1.jpg";
import sampleInvoice2 from "../images/sample/sample-invoice-2.jpg";
import moidSample1 from "../images/sample/sample-moid-1.jpg";
import passportSample1 from "../images/sample/sample-passport-1.jpg";
import passportSample2 from "../images/sample/sample-passport-2.jpg";
import sampleReceipt1 from "../images/sample/sample-receipt-1.jpg";
import sampleReceipt2 from "../images/sample/sample-receipt-2.jpg";
import sampleReceipt3 from "../images/sample/sample-receipt-3.jpg";
import sampleReceipt4 from "../images/sample/sample-receipt-4.jpg";
import sampleReceipt5 from "../images/sample/sample-receipt-5.jpg";
import sampleReceipt6 from "../images/sample/sample-receipt-6.jpg";
import twidSample from "../images/sample/sample-twid.jpg";
import { SampleImage } from "../types/extractor";

/* List of available prebuilt extractors 
   plus templated instant custom models
 */
const _PrebuiltExtractorDefinition = {
  bank_statement_llm: {
    icon: "IconExtractorBankStatement",
    extractorCardIcon: "IconExtractorCardBankStatement",
    isEditable: true,
    isUsingLLM: true,
    isCreatable: false,
    isFilterable: false,
  },
  invoice: {
    icon: "IconFileInvoice",
    extractorCardIcon: "IconExtractorCardInvoice",
    sampleImages: [{ src: sampleInvoice1 }, { src: sampleInvoice2 }],
    isEditable: true,
    isCreatable: false,
    isFilterable: false,
  },

  invoice_llm: {
    icon: "IconFileInvoice",
    extractorCardIcon: "IconExtractorCardInvoice",
    sampleImages: [{ src: sampleInvoice1 }, { src: sampleInvoice2 }],
    isEditable: true,
    isUsingLLM: true,
    isFilterable: true,
  },

  receipt: {
    icon: "IconReceipt2",
    extractorCardIcon: "IconExtractorCardReceipt",
    sampleImages: [
      { src: sampleReceipt1 },
      { src: sampleReceipt2 },
      { src: sampleReceipt3 },
      { src: sampleReceipt4 },
      { src: sampleReceipt5 },
      { src: sampleReceipt6 },
    ],
    isEditable: true,
    isFilterable: true,
  },

  gov_id_passport: {
    icon: "IconId",
    extractorCardIcon: "IconExtractorCardId",
    sampleImages: [
      { src: hkidNewSample1 },
      { src: hkidOldSample1 },
      { src: moidSample1 },
      { src: twidSample },
      { src: passportSample1 },
      { src: passportSample2 },
    ],
    helpUrl: "https://help.formx.ai/docs/international-idpassports",
    helpMessageId: "prebuilt.gov_id_passport.help",
    isFilterable: true,
  },

  address_proof: {
    icon: "IconProof",
    extractorCardIcon: "IconExtractorCardAddressProof",
    sampleImages: [{ src: sampleAddressProof1 }, { src: sampleAddressProof2 }],
    isEditable: true,
    isFilterable: true,
  },
  business_registration: {
    icon: "IconCertificate",
    extractorCardIcon: "IconExtractorCardBusinessRegistration",
    sampleImages: [{ src: businessRegistrationSample1 }],
    isFilterable: true,
  },
  bill_of_lading_llm: {
    icon: "IconExtractorBillOfLading",
    extractorCardIcon: "IconExtractorCardBillOfLading",
    isEditable: true,
    isUsingLLM: true,
    isCreatable: false,
    isFilterable: false,
  },
} as const;

export const PrebuiltExtractorDefinition: typeof _PrebuiltExtractorDefinition & {
  [key in keyof typeof _PrebuiltExtractorDefinition]: {
    icon: string;
    sampleImages?: readonly SampleImage[];
    helpUrl?: string;
    helpMessageId?: string;
    isEditable?: boolean;
    isCreatable?: boolean;
    isUsingLLM?: boolean;
    isFilterable?: boolean;
  };
} = _PrebuiltExtractorDefinition;

export const PrebuiltExtractors: (keyof typeof PrebuiltExtractorDefinition)[] =
  Object.keys(PrebuiltExtractorDefinition).filter(isPrebuiltExtractors);

function isCreatablePrebuiltExtractors(
  extractor: string
): extractor is keyof typeof PrebuiltExtractorDefinition {
  return (
    isPrebuiltExtractors(extractor) &&
    PrebuiltExtractorDefinition[extractor].isCreatable !== false
  );
}

export const CreatablePrebuiltExtractors: (keyof typeof PrebuiltExtractorDefinition)[] =
  Object.keys(PrebuiltExtractorDefinition).filter(
    isCreatablePrebuiltExtractors
  );

export function isPrebuiltExtractors(
  extractor: string
): extractor is keyof typeof PrebuiltExtractorDefinition {
  return extractor in PrebuiltExtractorDefinition;
}

export function isEditablePrebuiltExtractors(extractor: string) {
  return (
    isPrebuiltExtractors(extractor) &&
    PrebuiltExtractorDefinition[extractor].isEditable === true
  );
}

export function isFilterablePrebuiltExtractors(extractor: string) {
  return (
    isPrebuiltExtractors(extractor) &&
    PrebuiltExtractorDefinition[extractor].isFilterable === true
  );
}
