import {
  DirectionalHint,
  IButtonProps,
  IconButton,
  Label,
  TeachingBubble,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useFSLInstantSampleEditorContainer } from "../../contexts/fslInstantSampleEditor";
import { useLocale } from "../../contexts/locale";
import theme from "../../theme";
import {
  ExtractDataFieldType,
  ExtractListTableDataAccessor,
  ExtractListTableRowData,
} from "../../types/extractDataField";
import {
  ExtractedContentSchemaType,
  normalizeExtractedContentSchemaFieldName,
} from "../../types/extractedContentSchema";
import { DangerButton } from "../DangerButton";
import { ExtractDataField } from "../ExtractDataField";
import { ExtractTableDataBottomSheet } from "../ExtractTableDataBottomSheet";
import { ExtractTableDataType } from "../ExtractTableDataField";
import { ImageViewer } from "../ImageViewer";
import { MissionBar, MissionBarType } from "../MissionBar";
import { PageHeader } from "../PageHeader";
import {
  ActionButton,
  ActionButtonStyleType,
  DefaultButton,
} from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

export enum FSLExtractionReviewerStateType {
  PendingReviewResult,
  EditingPendingReviewResult,
  ReviewedResult,
  EditingReviewedResult,
}

const ACCEPT_RESULT_BUTTON_ID = "accept-result-button";
const CORRECT_DATA_BUTTON_ID = "correct-data-button";

type ContentItemData = {
  label: string;
  value: string | string[];
  type: ExtractedContentSchemaType;
  isList: boolean;
};

function useTeachingBubbleHandle() {
  const [isVisible, setIsVisible] = React.useState(false);

  const hideTeachingBubble = React.useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const toggleVisibility = React.useCallback(() => {
    setIsVisible(!isVisible);
  }, [setIsVisible, isVisible]);

  const primaryButtonProps: IButtonProps = React.useMemo(
    () => ({
      onClick: hideTeachingBubble,
      children: (
        <Label styles={{ root: { color: theme.palette.themePrimary } }}>
          <FormattedMessage id="fsl_custom_model.extraction_reviewer.teaching_bubble.got_it" />
        </Label>
      ),
    }),
    [hideTeachingBubble]
  );

  return React.useMemo(
    () => ({
      isVisible,
      setIsVisible,
      hideTeachingBubble,
      primaryButtonProps,
      toggleVisibility,
    }),
    [
      setIsVisible,
      isVisible,
      hideTeachingBubble,
      primaryButtonProps,
      toggleVisibility,
    ]
  );
}

function AcceptTeachingBubble(
  props: ReturnType<typeof useTeachingBubbleHandle>
) {
  const { hideTeachingBubble, isVisible, primaryButtonProps } = props;
  const { localized } = useLocale();

  const title = localized(
    "fsl_custom_model.extraction_reviewer.teaching_bubble.title"
  );

  return (
    <>
      {isVisible && (
        <TeachingBubble
          target={`#${ACCEPT_RESULT_BUTTON_ID}`}
          hasCloseButton={false}
          headline={title}
          onDismiss={hideTeachingBubble}
          primaryButtonProps={primaryButtonProps}
          calloutProps={{ directionalHint: DirectionalHint.bottomRightEdge }}
        >
          <div className={styles["teaching-bubble-content"]}>
            <div>
              <FormattedMessage id="fsl_custom_model.extraction_reviewer.accept_teaching_bubble.message1" />
            </div>

            <div>
              <FormattedMessage id="fsl_custom_model.extraction_reviewer.accept_teaching_bubble.message2" />
            </div>
          </div>
        </TeachingBubble>
      )}
    </>
  );
}

function InfoButton(props: { id: string; onClick: () => void }) {
  const { id, onClick } = props;

  return (
    <IconButton
      id={id}
      className={styles["info-button"]}
      iconProps={{
        iconName: "info",
        styles: {
          root: {
            color: "#605E5C",
          },
        },
      }}
      onClick={onClick}
    />
  );
}

function Header(props: ReturnType<typeof useFSLExtractionReviewerState>) {
  const { save, state, editExtractionResult } = props;
  const {
    acceptExtractionResult,
    requestToDiscard,
    goToInstantModelViewer,
    shouldShowOnboardingBubble,
    dismissOnboardingBubble,
  } = useFSLInstantSampleEditorContainer();
  const { localized } = useLocale();
  const acceptTeachingBubbleHandle = useTeachingBubbleHandle();

  const { title, subtitle } = React.useMemo(() => {
    switch (state) {
      case FSLExtractionReviewerStateType.PendingReviewResult:
        return {
          title: "fsl_custom_model.extraction_reviewer.header.title",
          subtitle: "fsl_custom_model.extraction_reviewer.header.desc",
        };
      case FSLExtractionReviewerStateType.ReviewedResult:
        return {
          title: "fsl_custom_model.extraction_reviewer.reviewed_header.title",
          subtitle: "fsl_custom_model.extraction_reviewer.reviewed_header.desc",
        };
      default:
        return {
          title: "fsl_custom_model.extraction_reviewer.correct_header.title",
          subtitle: "fsl_custom_model.extraction_reviewer.correct_header.desc",
        };
    }
  }, [state]);

  const handleCorrectDataTeachingBubbleOnClick = React.useCallback(() => {
    dismissOnboardingBubble();
  }, [dismissOnboardingBubble]);

  const handleBackToSamplesOnClick = React.useCallback(() => {
    goToInstantModelViewer();
  }, [goToInstantModelViewer]);

  return (
    <PageHeader
      className={styles["page-header"]}
      title={title}
      subtitle={subtitle}
      dividerVisible={false}
      right={
        <div className={styles["command-bar"]}>
          {state === FSLExtractionReviewerStateType.PendingReviewResult && (
            <>
              <DefaultButton
                id={CORRECT_DATA_BUTTON_ID}
                textId="fsl_custom_model.extraction_reviewer.footer.correct_data_button"
                onClick={editExtractionResult}
              />
              <DangerButton
                textId="fsl_custom_model.extraction_reviewer.footer.look_good_button"
                onClick={acceptExtractionResult}
              />
              <InfoButton
                id={ACCEPT_RESULT_BUTTON_ID}
                onClick={() => {
                  acceptTeachingBubbleHandle.toggleVisibility();
                }}
              />
              {shouldShowOnboardingBubble ? (
                <TeachingBubble
                  target={`#${CORRECT_DATA_BUTTON_ID}`}
                  calloutProps={{
                    directionalHint: DirectionalHint.bottomAutoEdge,
                    onClick: handleCorrectDataTeachingBubbleOnClick,
                  }}
                  headline={localized(
                    "fsl_custom_model.extraction_reviewer.footer.correct_data.guidance.headline"
                  )}
                >
                  <FormattedMessage id="fsl_custom_model.extraction_reviewer.footer.correct_data.guidance.body" />
                </TeachingBubble>
              ) : null}
            </>
          )}
          {state === FSLExtractionReviewerStateType.ReviewedResult && (
            <>
              <DefaultButton
                textId="fsl_custom_model.extraction_reviewer.footer.update_data_buttom"
                onClick={editExtractionResult}
              />
              <DangerButton
                textId="fsl_custom_model.extraction_reviewer.footer.back_to_sample_buttom"
                onClick={handleBackToSamplesOnClick}
              />
            </>
          )}
          {state ===
            FSLExtractionReviewerStateType.EditingPendingReviewResult && (
            <>
              <DefaultButton
                textId="common.cancel"
                onClick={() => {
                  requestToDiscard();
                }}
              />
              <DangerButton
                textId="fsl_custom_model.extraction_reviewer.footer.save_as_sample_button"
                onClick={save}
              />
            </>
          )}
          {state === FSLExtractionReviewerStateType.EditingReviewedResult && (
            <>
              <DefaultButton
                textId="common.cancel"
                onClick={requestToDiscard}
              />
              <DangerButton textId="common.save" onClick={save} />
            </>
          )}
          <AcceptTeachingBubble {...acceptTeachingBubbleHandle} />
        </div>
      }
    />
  );
}

type ContentItemProps = {
  label: string;
  value: any;
  type: ExtractedContentSchemaType;
  isList: boolean;
  isCorrecting: boolean;
  openBottomSheet: (label: string, value: any) => void;
};

function ContentItem(props: ContentItemProps) {
  const { label, value, type, isList, isCorrecting, openBottomSheet } = props;
  const { updateExtractionResult } = useFSLInstantSampleEditorContainer();

  const isMultiline = type === ExtractedContentSchemaType.MultiLineText;

  const dataFieldType = React.useMemo(() => {
    switch (type) {
      case ExtractedContentSchemaType.FieldGroup:
        return ExtractDataFieldType.ListTable;
      case ExtractedContentSchemaType.Currency:
        return ExtractDataFieldType.Currency;
      case ExtractedContentSchemaType.Checkbox:
        return ExtractDataFieldType.Checkbox;
      default:
        return isList ? ExtractDataFieldType.List : ExtractDataFieldType.Basic;
    }
  }, [type, isList]);

  const actionItems = [];

  if (dataFieldType === ExtractDataFieldType.ListTable) {
    actionItems.push(
      <ActionButton
        iconName="FullScreen"
        textId="extract_data_field.show_full_size_table"
        styleType={ActionButtonStyleType.TableRow}
        onClick={() => {
          openBottomSheet(label, value);
        }}
        key={`action-button-${label}`}
      />
    );
  }

  return (
    <ExtractDataField
      label={label}
      value={value}
      isEditable={isCorrecting}
      isMultiline={isMultiline}
      isList={isList}
      type={dataFieldType}
      actions={actionItems}
      onChange={newValue => {
        updateExtractionResult(label, newValue);
      }}
    />
  );
}

interface ContentProps {
  extractionResult?: ContentItemData[];
  isCorrecting: boolean;
  openBottomSheet: (label: string, value: any) => void;
}

export function Content(props: ContentProps) {
  const { extractionResult, isCorrecting, openBottomSheet } = props;

  return (
    <div className={styles["content"]}>
      <div className={styles["content-scrollable"]}>
        {extractionResult?.map(({ label, value, type, isList }, index) => {
          return (
            <ContentItem
              label={label}
              value={value}
              type={type}
              isList={isList}
              isCorrecting={isCorrecting}
              key={`content-item-${label}/${index}`}
              openBottomSheet={openBottomSheet}
            />
          );
        })}
      </div>
    </div>
  );
}

interface ImageViewProps {
  image?: string | string[];
}

function ImageView(props: ImageViewProps) {
  return (
    <div className={styles["image-view-container"]}>
      {props.image !== undefined && (
        <ImageViewer className={styles["image-viewer"]} src={props.image} />
      )}
    </div>
  );
}

export function useFSLExtractionReviewerState() {
  const { extractedContentSchema } = useFSLCustomModelEditor();

  const {
    isReadyToUseOnce,
    noOfSampleImages,
    isCorrecting,
    isExistingImage,
    editExtractionResult,
    save,
    updateExtractionResult,
  } = useFSLInstantSampleEditorContainer();

  const state = React.useMemo(() => {
    if (isCorrecting) {
      return isExistingImage
        ? FSLExtractionReviewerStateType.EditingReviewedResult
        : FSLExtractionReviewerStateType.EditingPendingReviewResult;
    } else {
      return isExistingImage
        ? FSLExtractionReviewerStateType.ReviewedResult
        : FSLExtractionReviewerStateType.PendingReviewResult;
    }
  }, [isCorrecting, isExistingImage]);

  const {
    extractionResult: _extractionResult,
    editingExtractionResult,
    assetFiles,
  } = useFSLInstantSampleEditorContainer();

  const extractionResult = React.useMemo(() => {
    if (_extractionResult == null) {
      return [];
    }

    const input = isCorrecting ? editingExtractionResult : _extractionResult;

    return extractedContentSchema?.payload.map((field, index) => {
      const { name, type, isList } = field;
      const normalizedName = normalizeExtractedContentSchemaFieldName(name);
      const rawValue = input?.[normalizedName] ?? "";
      let value = null;
      if (type === ExtractedContentSchemaType.FieldGroup) {
        value =
          ExtractListTableDataAccessor.createFromExtractedContentSchemaPayloadItem(
            extractedContentSchema,
            index,
            rawValue as any as ExtractListTableRowData[]
          ).data;
      } else {
        value = rawValue;
      }
      return {
        label: normalizedName,
        value,
        type: type as ExtractedContentSchemaType,
        isList,
      };
    }, []);
  }, [
    extractedContentSchema,
    _extractionResult,
    editingExtractionResult,
    isCorrecting,
  ]) as ContentItemData[];

  /* ExtractTableDataBottomSheet state */

  const [activeBottomSheetLabel, setActiveBottomSheetLabel] = React.useState<
    string | undefined
  >();

  const openBottomSheet = React.useCallback(
    (label: string, value: any) => {
      setActiveBottomSheetLabel(label);
      ExtractTableDataBottomSheet.open(
        label,
        value,
        ExtractTableDataType.ListTable,
        isCorrecting,
        newValue => {
          updateExtractionResult(label, newValue);
        }
      );
    },
    [updateExtractionResult, isCorrecting]
  );

  React.useEffect(() => {
    const result = extractionResult?.find(
      item => item.label === activeBottomSheetLabel
    );
    ExtractTableDataBottomSheet.updateValue(result?.value);
  }, [extractionResult, activeBottomSheetLabel]);

  return React.useMemo(
    () => ({
      extractionResult,
      assetFiles,
      isCorrecting,
      extractedContentSchema,
      save,
      isExistingImage,
      state,
      editExtractionResult,
      isReadyToUseOnce,
      noOfSampleImages,
      openBottomSheet,
    }),
    [
      extractionResult,
      isCorrecting,
      extractedContentSchema,
      save,
      isExistingImage,
      state,
      editExtractionResult,
      isReadyToUseOnce,
      noOfSampleImages,
      openBottomSheet,
      assetFiles,
    ]
  );
}

export function FSLExtractionReviewerImpl(
  props: ReturnType<typeof useFSLExtractionReviewerState>
) {
  const {
    extractionResult,
    assetFiles,
    isCorrecting,
    state,
    isReadyToUseOnce,
    noOfSampleImages,
    openBottomSheet,
  } = props;

  const showMissionBar =
    (state === FSLExtractionReviewerStateType.PendingReviewResult ||
      state === FSLExtractionReviewerStateType.EditingPendingReviewResult) &&
    noOfSampleImages === 0 &&
    !isReadyToUseOnce;

  return (
    <div className={styles["extraction-reviewer"]}>
      {showMissionBar && (
        <MissionBar
          type={MissionBarType.Info}
          title={
            <FormattedMessage
              id="fsl_custom_model.extraction_reviewer.mission_bar2.title"
              values={{
                progress: (
                  <b>
                    {" "}
                    <FormattedMessage id="fsl_custom_model.extraction_reviewer.mission_bar2.title_progress" />
                  </b>
                ),
              }}
            />
          }
        />
      )}
      <div className={styles["split-view"]}>
        <ImageView image={assetFiles} />
        <div className={styles["split-view-content"]}>
          <Header {...props} />
          <Content
            extractionResult={extractionResult}
            isCorrecting={isCorrecting}
            openBottomSheet={openBottomSheet}
          />
        </div>
      </div>
      <ExtractTableDataBottomSheet />
    </div>
  );
}

export function FSLExtractionReviewer() {
  const props = useFSLExtractionReviewerState();
  return <FSLExtractionReviewerImpl {...props} />;
}
