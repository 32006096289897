import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { DateTime } from "luxon";
import React, { useMemo } from "react";

import { CopyBlock } from "../CopyBlock";

interface StripeDetailsProps {
  customerId: string;
  subscriptionId: string | null;
  subscriptionSubscribedAt: string | null;
}

export function StripeDetails(props: StripeDetailsProps) {
  const { customerId, subscriptionId, subscriptionSubscribedAt } = props;

  const subscribedDate = useMemo(() => {
    if (subscriptionSubscribedAt == null) return null;
    const date = DateTime.fromISO(subscriptionSubscribedAt, {
      // TODO: use locale: en -> en-HK
      locale: "en-HK",
    });
    return date.toLocaleString(DateTime.DATE_SHORT);
  }, [subscriptionSubscribedAt]);

  return (
    <section>
      <h3 className="pb-3 mb-2 border-gray-200 border-0 border-b-2 border-solid text-xl font-semibold">
        <FormattedMessage id="team.detail.subscription.stripe_details.title" />
      </h3>
      <p className="text-sm mb-2 text-gray-900">
        <FormattedMessage id="team.detail.subscription.stripe_details.description" />
      </p>
      <div className="flex gap-5">
        <div className="w-[360px]">
          <Label className="text-gray-800">
            <FormattedMessage id="team.detail.subscription.stripe_details.customer_id" />
          </Label>
          <CopyBlock content={customerId} />
        </div>
        {subscriptionId == null ? null : (
          <div className="w-[360px]">
            <Label className="text-gray-800">
              <FormattedMessage id="team.detail.subscription.stripe_details.subscription_id" />
            </Label>
            <CopyBlock content={subscriptionId} />
          </div>
        )}
        {subscribedDate == null ? null : (
          <div className="w-[360px]">
            <Label className="text-gray-800">
              <FormattedMessage id="team.detail.subscription.stripe_details.subscribed_date" />
            </Label>
            <CopyBlock content={subscribedDate} />
          </div>
        )}
      </div>
    </section>
  );
}
