import * as yup from "yup";

import { pageInfoWithOffsetSchema } from "./pageInfo";

export const oauthCredentialSchema = yup
  .object({
    id: yup.string().required(),
    email: yup.string().required(),
    resourceServer: yup.string().required(),
    status: yup.string().required(),
  })
  .camelCase();

export const oauthCredentialListSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    oauthCredentials: yup.array().of(oauthCredentialSchema.defined()).defined(),
  })
  .camelCase();

export type OAuthCredential = yup.InferType<typeof oauthCredentialSchema>;

export type OAuthCredentialList = yup.InferType<
  typeof oauthCredentialListSchema
>;
