import { produce } from "immer";
import * as React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useCustomModelActionCreator } from "../actions/customModel";
import { useExtractorActionCreator } from "../actions/extractor";
import { useFormActionCreator } from "../actions/form";
import { SUPPORTED_EXTRACT_MIME } from "../constants";
import errors from "../errors";
import {
  useCommonCustomModelContainerState,
  useCustomModelStandardModelState,
} from "../hooks/custom_model";
import { useFirstSession } from "../hooks/gtm";
import { useN8N } from "../hooks/n8n";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { useUserInfo } from "../hooks/user";
import { GtmEventType, GtmServiceInstance } from "../services/gtmService";
import { ConfirmModalType } from "../types/confirmation";
import {
  CustomModel,
  CustomModelExtraFieldType,
  CustomModelNotificationType,
  FSLModelType,
} from "../types/customModel";
import {
  ExtractedContentSchema,
  ExtractedContentSchemaAccessor,
  ExtractedContentSchemaField,
  ExtractedContentSchemaType,
  accessExtractedContentSchema,
} from "../types/extractedContentSchema";
import { FSLModelState } from "../types/fslModelState";
import { FormatterMapper } from "../types/mappers/formatter";
import { chooseFile } from "../utils/file";
import {
  ExtractedContentSchemaValidationResult,
  ExtractedContentSchemaValidationResultAccessor,
  ExtractedContentSchemaValidator,
} from "../validators/extractedContentSchemaValidator";
import { FormatterValidator } from "../validators/formatterValidator";
import { removeFormatterOutputFromExtractorField } from "./formatterEditor";

export enum Page {
  Main,
  SchemaEditor,
}

export enum EditorType {
  SchemaEditor,
  InstantModelEditor,
  StandardModelEditor,
  OCRTest,
  API,
}

export const DEFAULT_FSL_MODEL_STATE = {
  isReadyToUse: false,
};

const FSL_CUSTOM_MODEL_LOCAL_STORAGE_KEY = "formx.fsl_custom_model";

interface FSLCustomModelLocalStorageType {
  hasUploadedFirstImage: boolean;
}

interface FSLCustomModelLocalStorage {
  [key: string]: FSLCustomModelLocalStorageType;
}

function useEffectOnceWhen(callback: () => void, condition: boolean) {
  const [executed, setExecuted] = React.useState(false);

  React.useEffect(() => {
    if (executed) {
      return;
    }
    if (condition) {
      const res = callback();
      setExecuted(true);
      return res;
    }
  }, [executed, condition, callback]);
}

function useLocalStorage() {
  const [localStorage, setLocalStorage] =
    React.useState<FSLCustomModelLocalStorage>({});

  React.useEffect(() => {
    const localStorageString = window.localStorage.getItem(
      FSL_CUSTOM_MODEL_LOCAL_STORAGE_KEY
    );
    if (localStorageString) {
      setLocalStorage(JSON.parse(localStorageString));
    }
  }, []);

  const getItem = React.useCallback(
    (customModelId: string) => {
      if (!localStorage[customModelId]) {
        return {
          hasUploadedFirstImage: false,
        };
      }
      return localStorage[customModelId];
    },
    [localStorage]
  );

  const setItem = React.useCallback(
    (customModelId: string, value: FSLCustomModelLocalStorageType) => {
      const newLocalStorage = produce(localStorage, draft => {
        draft[customModelId] = value;
      });
      setLocalStorage(newLocalStorage);
      window.localStorage.setItem(
        FSL_CUSTOM_MODEL_LOCAL_STORAGE_KEY,
        JSON.stringify(newLocalStorage)
      );
    },
    [localStorage]
  );

  return React.useMemo(
    () => ({
      getItem,
      setItem,
    }),
    [getItem, setItem]
  );
}

function useFSLFieldSchemaViewerHandle() {
  const [updatedMissionBarVisible, setUpdatedMissionBarVisible] =
    React.useState<boolean>(false);
  const [createdMissionBarVisible, setCreatedMissionBarVisible] =
    React.useState<boolean>(false);

  const dismissUpdatedMissionBar = React.useCallback(() => {
    setUpdatedMissionBarVisible(false);
  }, []);

  const props = React.useMemo(
    () => ({
      updatedMissionBarVisible,
      createdMissionBarVisible,
      dismissUpdatedMissionBar,
    }),
    [
      updatedMissionBarVisible,
      createdMissionBarVisible,
      dismissUpdatedMissionBar,
    ]
  );

  const methods = React.useMemo(
    () => ({
      setUpdatedMissionBarVisible,
      setCreatedMissionBarVisible,
    }),
    [setUpdatedMissionBarVisible]
  );

  return React.useMemo(
    () => ({
      props,
      methods,
    }),
    [props, methods]
  );
}

function useMakeContext(customModelId: string) {
  const toast = useToast();
  const navigate = useNavigate();
  const { getIsFirstSession } = useFirstSession();

  const [searchParams] = useSearchParams();

  type OnboardingStep = "confirm-schema" | "edit-schema" | "test-model";
  const [onboardingStep, setOnboardingStep] =
    React.useState<OnboardingStep | null>(() =>
      searchParams.get("onboarding") === "1" ? "confirm-schema" : null
    );

  const { handleConflict, requestUserConfirmation } =
    useConfirmModalActionCreator();
  const { deployModelVersion, undeployModel } = useCustomModelActionCreator();

  const { getExtractorFieldSchemaTable } = useExtractorActionCreator();

  const localStorage = useLocalStorage();

  const [
    editingExtractedContentSchemaValidationResult,
    setEditingExtractedContentSchemaValidationResult,
  ] = React.useState<ExtractedContentSchemaValidationResult>(
    ExtractedContentSchemaValidationResultAccessor.create().data
  );

  const containerState = useCommonCustomModelContainerState(customModelId, [
    CustomModelExtraFieldType.StandardMobelLabelledImageCount,
  ]);

  const customModel =
    containerState.state === "success" ? containerState.customModel : undefined;

  const { getForm } = useFormActionCreator();
  const { currentForm: underlyingForm } = useAppSelector(state => state.form);

  React.useEffect(() => {
    if (
      containerState.state !== "success" ||
      containerState.customModel?.formID == null
    ) {
      return;
    }
    getForm(containerState.customModel.formID);
    // @ts-expect-error containerState only has customModel if success
  }, [containerState.customModel?.formID, containerState.state, getForm]);

  const isFailedToFetchCustomModel = containerState.state === "error";

  const [isProcessing, setIsProcessing] = React.useState(false);

  const noOfSampleImages = customModel?.noOfSampleImages ?? 0;

  const isDocumentTooComplex =
    customModel?.config.fslModelState?.isDocumentTooComplex ?? false;

  const { isStandardModelEnabled, isStandardModelAvailable } =
    useCustomModelStandardModelState(customModel);

  const isReadyToUse = customModel?.config.fslModelState?.isReadyToUse ?? false;

  const navigateToTest = React.useCallback(() => {
    navigate(`/custom-model/${customModel?.id}/test`);
  }, [navigate, customModel]);

  const {
    uploadCustomModelImages,
    updateCustomModelFSLModelState,
    deleteCustomModelImages,
    updateCustomModelExtractedContentSchema,
    sendNotification,
  } = useCustomModelActionCreator();

  const updateIsDocumentTooComplex = React.useCallback(
    async (isDocumentTooComplex: boolean) => {
      const fslModelState = produce(
        customModel?.config.fslModelState,
        draft => {
          if (!draft) {
            return {
              ...DEFAULT_FSL_MODEL_STATE,
              isDocumentTooComplex,
            };
          }
          draft.isDocumentTooComplex = isDocumentTooComplex;
          return draft;
        }
      ) as FSLModelState;

      await updateCustomModelFSLModelState(fslModelState);
    },
    [updateCustomModelFSLModelState, customModel]
  );

  const uploadingCustomModelImageCount = useAppSelector(state => {
    const { successCount, failedCount, totalCount } = state.customModel
      .customModelImageUploadStatistics[customModelId] ?? {
      successCount: 0,
      failedCount: 0,
      totalCount: 0,
    };
    return totalCount - successCount - failedCount;
  });

  const isUploadingCustomModelImage = uploadingCustomModelImageCount !== 0;

  const isLoading = customModel === undefined;

  const [isCustomModelLoaded, setIsCustomModelLoaded] = React.useState(false);

  const [hasAddedField, setHasAddedField] = React.useState(false);

  const isFSLModel = React.useMemo(() => {
    if (!isCustomModelLoaded) {
      return undefined;
    }
    return customModel?.config?.isFSLModel ?? false;
  }, [isCustomModelLoaded, customModel]);

  const userInfo = useAppSelector(state => {
    return state.user.currentUser;
  });

  const triggerGetHelpFromFormXProcess = React.useCallback(() => {
    requestUserConfirmation(
      {
        titleId: "fsl_custom_model.get_help_dialog.title",
        messageId: "fsl_custom_model.get_help_dialog.message",
        actionId: "common.dismiss",
        type: ConfirmModalType.Notify,
      },
      false
    );
    if (!userInfo || !customModel) {
      return;
    }

    sendNotification(
      CustomModelNotificationType.ClickedGetHelp,
      customModel
    ).catch(() => {
      toast.error("error.custom_model.get_help_not_sent");
    });
  }, [userInfo, customModel, requestUserConfirmation, sendNotification, toast]);

  const [editingExtractedContentSchema, setEditingExtractedContentSchema] =
    React.useState<ExtractedContentSchema | undefined>(undefined);

  const [isExtractedContentSchemaChanged, setIsExtractedContentSchemaChanged] =
    React.useState<boolean>(false);

  const [selectedEditingSchemaFieldIndex, setSelectedEditingSchemaFieldIndex] =
    React.useState<number | undefined>(undefined);

  const teamId = useAppSelector(state => state.resourceOwner.resourceOwnerId);

  const extractedContentSchema = React.useMemo(() => {
    if (customModel === undefined) {
      return undefined;
    }

    return customModel.config.extractedContentSchema;
  }, [customModel]);

  const validateExtractedContentSchema = React.useCallback(
    (schema: ExtractedContentSchema) => {
      const validator = new ExtractedContentSchemaValidator();

      const result = validator.validateExtractedContentSchema(schema);
      setEditingExtractedContentSchemaValidationResult(result);

      return result;
    },
    [setEditingExtractedContentSchemaValidationResult]
  );

  const updateEditingExtractedContentSchema = React.useCallback(
    (schema: ExtractedContentSchema | undefined) => {
      setEditingExtractedContentSchema(schema);
      if (schema === undefined) {
        return;
      }
      if (editingExtractedContentSchemaValidationResult.hasError) {
        validateExtractedContentSchema(schema);
      }
    },
    [
      editingExtractedContentSchemaValidationResult,
      validateExtractedContentSchema,
      setEditingExtractedContentSchema,
    ]
  );

  const [page, setPage] = React.useState<Page>(Page.Main);

  React.useEffect(() => {
    // When the custom model loaded
    if (isCustomModelLoaded) {
      return;
    }
    if (customModel !== undefined && customModel?.id === customModelId) {
      setIsCustomModelLoaded(true);
      if (customModel.config.extractedContentSchema?.payload.length ?? 0 > 0) {
        setHasAddedField(true);
      } else {
        // Empty panel should show editor directly
        setPage(Page.SchemaEditor);
      }
    }
  }, [
    isCustomModelLoaded,
    customModel,
    setIsCustomModelLoaded,
    setPage,
    customModelId,
  ]);

  const backToMain = React.useCallback(() => {
    setIsExtractedContentSchemaChanged(false);
    setPage(Page.Main);
  }, [setPage]);

  const editSchema = React.useCallback(() => {
    updateEditingExtractedContentSchema(extractedContentSchema);
    setPage(Page.SchemaEditor);
    setSelectedEditingSchemaFieldIndex(undefined);
  }, [
    setPage,
    extractedContentSchema,
    updateEditingExtractedContentSchema,
    setSelectedEditingSchemaFieldIndex,
  ]);

  const [pendingNotification, setPendingNotification] = React.useState<
    | {
        type: CustomModelNotificationType;
      }
    | undefined
  >();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (pendingNotification) {
      sendNotification(pendingNotification.type, customModel).catch(e => {
        console.error(e);
      });
      setPendingNotification(undefined);
    }
  }, [isLoading, pendingNotification, customModel, sendNotification]);

  const fslFieldSchemaViewerHandle = useFSLFieldSchemaViewerHandle();

  const navigateToInstantModel = React.useCallback(() => {
    navigate(`/custom-model/${customModel?.id}/instant-model`);
    fslFieldSchemaViewerHandle.methods.setCreatedMissionBarVisible(false);
  }, [navigate, customModel, fslFieldSchemaViewerHandle.methods]);

  const navigateToStandardModel = React.useCallback(() => {
    navigate(`/custom-model/${customModel?.id}/standard-model`);
    fslFieldSchemaViewerHandle.methods.setCreatedMissionBarVisible(false);
  }, [navigate, customModel, fslFieldSchemaViewerHandle.methods]);

  const setEditingDocumentType = React.useCallback(
    (documentType: string) => {
      if (!editingExtractedContentSchema) {
        updateEditingExtractedContentSchema({
          name: documentType,
          payload: [],
          definitions: [],
        });
      } else {
        updateEditingExtractedContentSchema(
          produce(editingExtractedContentSchema, draft => {
            if (!draft) {
              draft = {
                name: documentType,
                payload: [],
                definitions: [],
              };
            } else {
              draft.name = documentType;
            }
          })
        );
      }
      setIsExtractedContentSchemaChanged(true);
    },
    [editingExtractedContentSchema, updateEditingExtractedContentSchema]
  );

  const removeEditingSchemaField = React.useCallback(
    (index: number) => {
      updateEditingExtractedContentSchema(
        produce(editingExtractedContentSchema, draft => {
          if (!draft) {
            return;
          }
          draft.payload.splice(index, 1);
        })
      );
      setIsExtractedContentSchemaChanged(true);
      setSelectedEditingSchemaFieldIndex(undefined);
    },
    [
      updateEditingExtractedContentSchema,
      editingExtractedContentSchema,
      setSelectedEditingSchemaFieldIndex,
    ]
  );

  const addEditingSchemaField = React.useCallback(() => {
    const newField = {
      id: uuidv4(),
      name: "",
      type: ExtractedContentSchemaType.SingleLineText,
      isList: false,
    };

    const index = editingExtractedContentSchema?.payload.length ?? 0;

    updateEditingExtractedContentSchema(
      produce(editingExtractedContentSchema, draft => {
        if (!draft) {
          return {
            name: "",
            definitions: [],
            payload: [newField],
          };
        }
        draft.payload.push(newField);
        return draft;
      })
    );

    setIsExtractedContentSchemaChanged(true);
    setSelectedEditingSchemaFieldIndex(index);
    setHasAddedField(true);
  }, [
    updateEditingExtractedContentSchema,
    editingExtractedContentSchema,
    setSelectedEditingSchemaFieldIndex,
    setHasAddedField,
  ]);

  const removeAllEditingSchemaFields = React.useCallback(() => {
    updateEditingExtractedContentSchema(
      produce(editingExtractedContentSchema, draft => {
        if (!draft) {
          return;
        }
        draft.payload = [];
      })
    );
    setIsExtractedContentSchemaChanged(true);
  }, [updateEditingExtractedContentSchema, editingExtractedContentSchema]);

  const setSelectedEditingSchemaField = React.useCallback(
    (field: ExtractedContentSchemaField) => {
      if (
        editingExtractedContentSchema === undefined ||
        selectedEditingSchemaFieldIndex === undefined
      ) {
        return;
      }
      updateEditingExtractedContentSchema(
        new ExtractedContentSchemaAccessor(
          editingExtractedContentSchema
        ).changePayload(selectedEditingSchemaFieldIndex, field).data
      );
      setIsExtractedContentSchemaChanged(true);
    },
    [
      selectedEditingSchemaFieldIndex,
      editingExtractedContentSchema,
      updateEditingExtractedContentSchema,
    ]
  );

  const setSelectedEditingSchemaSubField = React.useCallback(
    (subFieldIndex: number, updates: Partial<ExtractedContentSchemaField>) => {
      if (
        editingExtractedContentSchema === undefined ||
        selectedEditingSchemaFieldIndex === undefined
      ) {
        return;
      }

      updateEditingExtractedContentSchema(
        new ExtractedContentSchemaAccessor(
          editingExtractedContentSchema
        ).changePayloadSubField(
          selectedEditingSchemaFieldIndex,
          subFieldIndex,
          updates
        ).data
      );
      setIsExtractedContentSchemaChanged(true);
    },
    [
      editingExtractedContentSchema,
      selectedEditingSchemaFieldIndex,
      updateEditingExtractedContentSchema,
    ]
  );

  const addEditingSchemaSubField = React.useCallback(() => {
    if (
      editingExtractedContentSchema === undefined ||
      selectedEditingSchemaFieldIndex === undefined
    ) {
      return;
    }

    updateEditingExtractedContentSchema(
      new ExtractedContentSchemaAccessor(
        editingExtractedContentSchema
      ).addPayloadSubField(selectedEditingSchemaFieldIndex).data
    );
    setIsExtractedContentSchemaChanged(true);
  }, [
    editingExtractedContentSchema,
    updateEditingExtractedContentSchema,
    selectedEditingSchemaFieldIndex,
  ]);

  const removeEditingSchemaSubField = React.useCallback(
    (subFieldIndex: number) => {
      if (
        editingExtractedContentSchema === undefined ||
        selectedEditingSchemaFieldIndex === undefined
      ) {
        return;
      }

      updateEditingExtractedContentSchema(
        new ExtractedContentSchemaAccessor(
          editingExtractedContentSchema
        ).removePayloadSubField(selectedEditingSchemaFieldIndex, subFieldIndex)
          .data
      );
      setIsExtractedContentSchemaChanged(true);
    },
    [
      editingExtractedContentSchema,
      updateEditingExtractedContentSchema,
      selectedEditingSchemaFieldIndex,
    ]
  );

  const moveEditingSchemaSubField = React.useCallback(
    (fromIndex: number, toIndex: number) => {
      if (
        editingExtractedContentSchema === undefined ||
        selectedEditingSchemaFieldIndex === undefined
      ) {
        return;
      }

      updateEditingExtractedContentSchema(
        new ExtractedContentSchemaAccessor(
          editingExtractedContentSchema
        ).movePayloadSubField(
          selectedEditingSchemaFieldIndex,
          fromIndex,
          toIndex
        ).data
      );
      setIsExtractedContentSchemaChanged(true);
    },
    [
      editingExtractedContentSchema,
      updateEditingExtractedContentSchema,
      selectedEditingSchemaFieldIndex,
    ]
  );

  const moveToLastEditingSchemaSubField = React.useCallback(
    (fromIndex: number) => {
      if (
        editingExtractedContentSchema === undefined ||
        selectedEditingSchemaFieldIndex === undefined
      ) {
        return;
      }

      updateEditingExtractedContentSchema(
        new ExtractedContentSchemaAccessor(
          editingExtractedContentSchema
        ).moveToLastPayloadSubField(selectedEditingSchemaFieldIndex, fromIndex)
          .data
      );
      setIsExtractedContentSchemaChanged(true);
    },
    [
      editingExtractedContentSchema,
      updateEditingExtractedContentSchema,
      selectedEditingSchemaFieldIndex,
    ]
  );

  const handleCustomModelFormatterConflict = React.useCallback(
    async (customModel: CustomModel, onSuccess?: () => void) => {
      try {
        await handleConflict(
          async () => {
            if (customModel.formID === null) {
              return Promise.reject();
            }
            const extractorFieldSchemaTable =
              await getExtractorFieldSchemaTable(customModel.id);
            const formatter = FormatterMapper.fromStorage(
              underlyingForm?.config.formatter
            );
            if (formatter === undefined) {
              return Promise.reject();
            }
            const originalFormatterOutputs =
              formatter?.steps
                .filter(step => step.outputAs.enabled)
                .map(step => step.outputAs.fields.map(field => field.name))
                .flat() ?? [];
            const existingInputFields = removeFormatterOutputFromExtractorField(
              extractorFieldSchemaTable ?? {},
              originalFormatterOutputs
            );
            const validator = new FormatterValidator();
            const { hasError } = validator.validateFormatter(
              formatter,
              existingInputFields
            );
            if (hasError) {
              throw errors.ConflictFound;
            }

            if (onSuccess) {
              onSuccess();
            }
            return Promise.resolve();
          },
          async () => {
            navigate(`/custom-model/${customModel.id}/format`);
            return Promise.resolve();
          },
          {
            titleId: "form_editor.formatter_error_prompt.title",
            messageId: "form_editor.formatter_error_prompt.desc",
            actionId: "form_editor.formatter_error_prompt.go_to_formatter",
          }
        );
      } catch (_) {
        // execute if handleConflict rejected by clicking "cancel"
        if (onSuccess) {
          onSuccess();
        }
      }
    },
    [
      getExtractorFieldSchemaTable,
      handleConflict,
      navigate,
      underlyingForm?.config.formatter,
    ]
  );

  const saveSchema = React.useCallback(
    (onSuccess?: () => void) => {
      if (!editingExtractedContentSchema || !customModel) {
        return;
      }

      setIsProcessing(true);

      const validator = new ExtractedContentSchemaValidator();
      const isSchemaUpdated =
        extractedContentSchema === undefined ||
        validator.checkIfSchemaUpdated(
          editingExtractedContentSchema,
          extractedContentSchema
        );
      if (isSchemaUpdated) {
        editingExtractedContentSchema.modifiedAt = Math.floor(
          new Date().getTime() / 1000
        );
      }
      const strippedSchema = validator.stripExtractedContentSchema(
        editingExtractedContentSchema
      );

      const runner = async () => {
        const isNewSchemaCreated =
          customModel.config.extractedContentSchema === undefined;
        const shouldSendCreatedNotification =
          isNewSchemaCreated && userInfo !== undefined;

        try {
          if (isReadyToUse || isDocumentTooComplex) {
            // Unset isReadyToUse and isDocumentTooComplex
            const fslModelState = produce(
              customModel.config.fslModelState,
              draft => {
                if (!draft) {
                  return {
                    ...DEFAULT_FSL_MODEL_STATE,
                    isDocumentTooComplex: false,
                  };
                }
                draft.isReadyToUse = false;
                draft.isDocumentTooComplex = false;
                return draft;
              }
            ) as FSLModelState;

            await updateCustomModelFSLModelState(fslModelState);
          }
        } catch (e) {
          setIsProcessing(false);
          toast.error("error.fail_to_save_extractor");
          return;
        }

        try {
          await updateCustomModelExtractedContentSchema(strippedSchema);
          toast.success("custom_model_editor.custom_model_is_saved");
          if (shouldSendCreatedNotification) {
            sendNotification(
              CustomModelNotificationType.CreatedSchema,
              customModel
            ).catch(e => {
              console.error(e);
            });
          }

          if (
            isNewSchemaCreated ||
            // If there is no sample image and standard model is not enabled, don't show error
            (noOfSampleImages === 0 && !isStandardModelEnabled)
          ) {
            fslFieldSchemaViewerHandle.methods.setCreatedMissionBarVisible(
              true
            );
          } else if (isSchemaUpdated) {
            fslFieldSchemaViewerHandle.methods.setUpdatedMissionBarVisible(
              true
            );
          }

          setIsExtractedContentSchemaChanged(false);
          handleCustomModelFormatterConflict(customModel, onSuccess);
        } catch (e) {
          toast.error("error.fail_to_save_extractor");
        }
        setIsProcessing(false);
      };
      runner();
    },
    [
      extractedContentSchema,
      editingExtractedContentSchema,
      customModel,
      userInfo,
      isReadyToUse,
      isDocumentTooComplex,
      updateCustomModelFSLModelState,
      toast,
      updateCustomModelExtractedContentSchema,
      noOfSampleImages,
      isStandardModelEnabled,
      handleCustomModelFormatterConflict,
      sendNotification,
      fslFieldSchemaViewerHandle.methods,
    ]
  );

  const requestToSaveSchema = React.useCallback(
    (onSuccess?: () => void) => {
      if (!editingExtractedContentSchema) {
        return;
      }
      const result = validateExtractedContentSchema(
        editingExtractedContentSchema
      );

      if (result.hasError) {
        if (result.missingPayload) {
          toast.error(
            "fsl_custom_model.field_schema_editor.error.missing_field_error"
          );
        }
        return;
      }

      const noOfSampleImages = customModel?.noOfSampleImages ?? 0;
      if (noOfSampleImages !== 0) {
        requestUserConfirmation(
          {
            titleId: "fsl_custom_model.save_schema_confirmation_dialog.title",
            messageId:
              "fsl_custom_model.save_schema_confirmation_dialog.message",
            actionId: "fsl_custom_model.save_schema_confirmation_dialog.action",
            type: ConfirmModalType.Normal,
          },
          false
        ).then(res => {
          if (!res) {
            return;
          }
          saveSchema(onSuccess);
          if (onboardingStep != null) {
            setOnboardingStep("test-model");
          }
        });
      } else {
        saveSchema(onSuccess);
        if (onboardingStep != null) {
          setOnboardingStep("test-model");
        }
      }
    },
    [
      saveSchema,
      validateExtractedContentSchema,
      editingExtractedContentSchema,
      toast,
      customModel,
      requestUserConfirmation,
      onboardingStep,
    ]
  );

  const deleteCustomModelImage = React.useCallback(
    (customModelImageId: string) => {
      if (!customModel) {
        return;
      }
      deleteCustomModelImages(customModel.id, [customModelImageId]);
    },
    [customModel, deleteCustomModelImages]
  );

  const createExtractedContentSchema = React.useCallback(
    (schema: ExtractedContentSchema) => {
      updateEditingExtractedContentSchema(
        accessExtractedContentSchema(schema).randomizeIds().data
      );
      setIsExtractedContentSchemaChanged(true);
    },
    [updateEditingExtractedContentSchema]
  );

  const requestToDeleteCustomModelImages = React.useCallback(
    async (customModelImageIds: string[], shouldDeleteGroup: boolean) => {
      if (!customModel) {
        return false;
      }

      const res = await requestUserConfirmation({
        titleId:
          "fsl_instant_model_editor.sample_images.remove_confirmation.title",
        messageId:
          "fsl_instant_model_editor.sample_images.remove_confirmation.message",
        actionId: "common.delete",
        type: ConfirmModalType.Destory,
      });
      if (!res) {
        return res;
      }

      deleteCustomModelImages(
        customModel.id,
        customModelImageIds,
        shouldDeleteGroup
      );
      if (isDocumentTooComplex) {
        await updateIsDocumentTooComplex(false);
      }

      return res;
    },
    [
      deleteCustomModelImages,
      customModel,
      requestUserConfirmation,
      isDocumentTooComplex,
      updateIsDocumentTooComplex,
    ]
  );

  const requestToUploadStandardModelImages = React.useCallback(() => {
    const onFiles = (files?: File[]) => {
      if (!files || files.length === 0) {
        toast.error("form.editor.no.image.missing.image");
        return;
      }
      uploadCustomModelImages(customModel?.id ?? "", files);
    };
    chooseFile(SUPPORTED_EXTRACT_MIME.join(","), true)
      .then(onFiles)
      .catch(() => {});
  }, [customModel, toast, uploadCustomModelImages]);

  const uploadStandardModelImages = React.useCallback(
    (files: File[]) => {
      uploadCustomModelImages(customModel?.id ?? "", files);
    },
    [customModel?.id, uploadCustomModelImages]
  );

  const standardModelVersions = React.useMemo(() => {
    return (
      customModel?.modelVersions
        ?.filter(version => {
          return !version.isInTraining;
        })
        ?.map(version => {
          return {
            key: version.tag,
            text: version.tag,
          };
        }) ?? []
    );
  }, [customModel]);

  const plan = useAppSelector(state => state.resourceOwner.planName ?? "");

  const updateActiveModel = React.useCallback(
    async (modelType: FSLModelType, modelVersion?: string) => {
      if (!customModel) {
        return;
      }

      if (modelType === FSLModelType.StandardModel) {
        try {
          await deployModelVersion(customModel.id, modelVersion ?? "");
        } catch (e) {
          console.error(e);
          toast.error("custom_model_model.deploy.error");
          throw e;
        }
      } else {
        try {
          await undeployModel(customModel.id);
        } catch (e) {
          console.error(e);
          toast.error("fsl_custom_model.error.unable_switch_to_instant_model");
          throw e;
        }
      }
      GtmServiceInstance.push({
        event: GtmEventType.CustomSwitchedModel,
        event_data: {
          team_id: teamId ?? "",
          plan,
          extractor_id: customModel.id,
          model_type: modelType === FSLModelType.InstantModel ? "fsl" : "msl",
          model_id: modelVersion,
        },
      });

      const shouldFallbackToOriginalCustomModel =
        modelType === FSLModelType.StandardModel;
      const fslModelState = produce(customModel.config.fslModelState, draft => {
        if (!draft) {
          return {
            ...DEFAULT_FSL_MODEL_STATE,
            shouldFallbackToOriginalCustomModel,
          };
        }
        draft.shouldFallbackToOriginalCustomModel =
          shouldFallbackToOriginalCustomModel;
        return draft;
      }) as FSLModelState;

      try {
        await updateCustomModelFSLModelState(fslModelState);
      } catch (e) {
        console.error(e);
        toast.error("error.fail_to_save_extractor");
        throw e;
      }
    },
    [
      customModel,
      teamId,
      deployModelVersion,
      toast,
      undeployModel,
      updateCustomModelFSLModelState,
      plan,
    ]
  );

  const { userId, displayName, email } = useUserInfo();

  const {
    submitManageFieldSupportRequest: submitN8NManageFieldSupportRequest,
  } = useN8N();

  const submitManageFieldSupportRequest = React.useCallback(
    (message: string) => {
      submitN8NManageFieldSupportRequest(
        userId ?? "",
        displayName,
        email ?? "",
        customModel?.id ?? "",
        message
      );
    },
    [
      userId,
      displayName,
      email,
      customModel,
      submitN8NManageFieldSupportRequest,
    ]
  );

  const [manageFieldGuidanceEnabled, setManageFieldGuidanceEnabled] =
    React.useState<boolean>(false);

  const startGuidanceForFirstSession = React.useCallback(() => {
    const firstSession = getIsFirstSession();
    if (firstSession) {
      editSchema();
      if ((extractedContentSchema?.payload.length ?? 0) > 0) {
        setSelectedEditingSchemaFieldIndex(0);
        setManageFieldGuidanceEnabled(true);
      }
    }
  }, [getIsFirstSession, editSchema, extractedContentSchema]);

  useEffectOnceWhen(
    startGuidanceForFirstSession,
    extractedContentSchema !== undefined
  );

  return React.useMemo(() => {
    return {
      isLoading,
      customModel,
      isFSLModel,
      isFailedToFetchCustomModel,
      customModelId,
      editingExtractedContentSchema,
      isExtractedContentSchemaChanged,
      updateEditingExtractedContentSchema,
      extractedContentSchema,
      page,
      backToMain,
      editSchema,
      setEditingDocumentType,
      saveSchema,
      removeEditingSchemaField,
      selectedEditingSchemaFieldIndex,
      setSelectedEditingSchemaFieldIndex,
      addEditingSchemaField,
      setSelectedEditingSchemaField,
      isProcessing,
      deleteCustomModelImage,
      requestToSaveSchema,
      createExtractedContentSchema,
      navigateToTest,
      isDocumentTooComplex,
      hasAddedField,
      editingExtractedContentSchemaValidationResult,
      triggerGetHelpFromFormXProcess,
      removeAllEditingSchemaFields,
      fslFieldSchemaViewerHandle,
      navigateToInstantModel,
      requestToDeleteCustomModelImages,
      requestToUploadStandardModelImages,
      uploadStandardModelImages,
      isStandardModelEnabled,
      isStandardModelAvailable,
      updateActiveModel,
      navigateToStandardModel,
      noOfSampleImages,
      uploadingCustomModelImageCount,
      updateIsDocumentTooComplex,
      localStorage,
      setPendingNotification,
      containerState,
      standardModelVersions,
      isUploadingCustomModelImage,
      setSelectedEditingSchemaSubField,
      addEditingSchemaSubField,
      removeEditingSchemaSubField,
      moveEditingSchemaSubField,
      moveToLastEditingSchemaSubField,
      manageFieldGuidanceEnabled,
      submitManageFieldSupportRequest,
      onboardingStep,
      setOnboardingStep,
    };
  }, [
    isLoading,
    customModel,
    isFSLModel,
    isFailedToFetchCustomModel,
    customModelId,
    editingExtractedContentSchema,
    isExtractedContentSchemaChanged,
    updateEditingExtractedContentSchema,
    extractedContentSchema,
    page,
    backToMain,
    editSchema,
    setEditingDocumentType,
    saveSchema,
    removeEditingSchemaField,
    selectedEditingSchemaFieldIndex,
    setSelectedEditingSchemaFieldIndex,
    addEditingSchemaField,
    setSelectedEditingSchemaField,
    isProcessing,
    deleteCustomModelImage,
    requestToSaveSchema,
    createExtractedContentSchema,
    navigateToTest,
    isDocumentTooComplex,
    hasAddedField,
    editingExtractedContentSchemaValidationResult,
    removeAllEditingSchemaFields,
    triggerGetHelpFromFormXProcess,
    fslFieldSchemaViewerHandle,
    navigateToInstantModel,
    requestToDeleteCustomModelImages,
    requestToUploadStandardModelImages,
    uploadStandardModelImages,
    isStandardModelEnabled,
    isStandardModelAvailable,
    updateActiveModel,
    navigateToStandardModel,
    noOfSampleImages,
    uploadingCustomModelImageCount,
    updateIsDocumentTooComplex,
    localStorage,
    setPendingNotification,
    containerState,
    standardModelVersions,
    isUploadingCustomModelImage,
    setSelectedEditingSchemaSubField,
    addEditingSchemaSubField,
    removeEditingSchemaSubField,
    moveEditingSchemaSubField,
    moveToLastEditingSchemaSubField,
    manageFieldGuidanceEnabled,
    submitManageFieldSupportRequest,
    onboardingStep,
    setOnboardingStep,
  ]);
}

type FSLCustomModelEditorContextValue = ReturnType<typeof useMakeContext>;
const FSLCustomModelEditorContext =
  React.createContext<FSLCustomModelEditorContextValue>(null as any);

interface Props {
  customModelId: string;
  children: React.ReactNode;
}

export const FSLCustomModelEditorProvider = (props: Props) => {
  const { customModelId } = props;
  const value = useMakeContext(customModelId);
  return <FSLCustomModelEditorContext.Provider {...props} value={value} />;
};

export function useFSLCustomModelEditor() {
  return React.useContext(FSLCustomModelEditorContext);
}
