import * as React from "react";

import { useCustomModelActionCreator } from "../actions/customModel";
import { UserFeatureFlag } from "../constants";
import { CustomModel, CustomModelExtraFieldType } from "../types/customModel";
import { WorkerToken } from "../types/workerToken";
import { useWorkerToken } from "./app";
import { useAppSelector } from "./redux";

export function isActionInProgress(
  startAt: number | null,
  finishAt: number | null
) {
  if (startAt !== null && finishAt !== null) {
    return finishAt < startAt;
  }
  return startAt !== null;
}

export function useAutoReloadCustomModel(
  customModelId: string | null,
  reloadCondition: boolean,
  reloadInterval: number
) {
  const { getCustomModel } = useCustomModelActionCreator();

  const [isFailedToFetchCustomModel, setIsFailedToFetchCustomModel] =
    React.useState(false);

  React.useEffect(() => {
    if (reloadCondition && customModelId && !isFailedToFetchCustomModel) {
      const timerId = setInterval(() => {
        getCustomModel(customModelId).catch(() => {
          setIsFailedToFetchCustomModel(true);
        });
      }, reloadInterval);

      return () => {
        clearInterval(timerId);
      };
    }

    return () => {};
  }, [
    customModelId,
    reloadCondition,
    reloadInterval,
    getCustomModel,
    isFailedToFetchCustomModel,
  ]);
}

export function useCustomModel(
  customModelId: string,
  extraFields?: CustomModelExtraFieldType[]
) {
  const { getCustomModel } = useCustomModelActionCreator();

  const {
    currentCustomModel: customModel,
    isGettingCustomModel,
    customModelError,
  } = useAppSelector(state => state.customModel);

  const shouldFetchCustomModel =
    (!customModel || customModel.id !== customModelId) && !isGettingCustomModel;

  const [isFailedToFetchCustomModel, setIsFailedToFetchCustomModel] =
    React.useState(false);

  React.useEffect(() => {
    if (shouldFetchCustomModel && !isFailedToFetchCustomModel) {
      getCustomModel(customModelId, extraFields).catch(() =>
        setIsFailedToFetchCustomModel(true)
      );
    }
  }, [
    getCustomModel,
    customModelId,
    shouldFetchCustomModel,
    isFailedToFetchCustomModel,
    extraFields,
  ]);

  return React.useMemo(
    () => ({
      customModel,
      isGettingCustomModel,
      isFailedToFetchCustomModel: customModelError !== undefined,
    }),
    [customModel, isGettingCustomModel, customModelError]
  );
}

interface CommonCustomModelContainerSuccessState {
  state: "success";
  workerTokens: WorkerToken[];
  workerToken: string | undefined;
  onSelectWorkerToken: (token: string) => void;
  customModel: CustomModel;
  isSyncWithCVATInProgress: boolean;
  canAccessCVATProject: boolean;
  isTrainingInProgress: boolean;
  isDeploymentInProgress: boolean;
  isFSLModel: boolean;
}

interface CommonCustomModelContainerErrorState {
  state: "error";
}

interface CommonCustomModelContainerLoadingState {
  state: "loading";
}

export type CommonCustomModelContainerState =
  | CommonCustomModelContainerLoadingState
  | CommonCustomModelContainerErrorState
  | CommonCustomModelContainerSuccessState;

export function useCommonCustomModelContainerState(
  customModelId: string,
  extraFields?: CustomModelExtraFieldType[]
) {
  const { customModel, isFailedToFetchCustomModel } = useCustomModel(
    customModelId,
    extraFields
  );
  const {
    token: workerToken,
    isFailed: isFailedToGetWorkerToken,
    tokens: workerTokens,
    onSelectToken: onSelectWorkerToken,
  } = useWorkerToken();

  const containerState: CommonCustomModelContainerState =
    isFailedToGetWorkerToken || isFailedToFetchCustomModel
      ? { state: "error" }
      : customModel &&
        customModel.id === customModelId &&
        workerTokens !== undefined
      ? {
          state: "success",
          customModel,
          workerToken,
          workerTokens,
          onSelectWorkerToken,
          isSyncWithCVATInProgress: isActionInProgress(
            customModel.lastCVATProjectStartSyncAt,
            customModel.lastCVATProjectFinishSyncAt
          ),
          isTrainingInProgress: isActionInProgress(
            customModel.startTrainingAt,
            customModel.finishTrainingAt
          ),
          isDeploymentInProgress: isActionInProgress(
            customModel.startDeploymentAt,
            customModel.finishDeploymentAt
          ),
          canAccessCVATProject:
            customModel.lastCVATProjectFinishSyncAt !== null,
          isFSLModel: customModel.config.isFSLModel ?? false,
        }
      : { state: "loading" };

  return containerState;
}

export function useCustomModelStandardModelState(
  customModel: CustomModel | undefined
) {
  const isStandardModelEnabled =
    customModel?.config.fslModelState?.isStandardModelEnabled ?? false;

  const isStandardModelAvailable = useAppSelector(
    state =>
      isStandardModelEnabled ||
      state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
        UserFeatureFlag.EnableStandardModel
      )
  );

  return { isStandardModelEnabled, isStandardModelAvailable };
}
