import {
  Checkbox,
  ChoiceGroup,
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";

import { PLAN_NAME_MESSAGE_IDS } from "../../constants";
import { useLocale } from "../../contexts/locale";
import errors from "../../errors";

const INITIAL_QUOTA = 100;

interface Props {
  isOpen: boolean;
  targetPlanName: string;
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  onCancel: () => void;
  onConfirm: (quota: number | null, planEndAt: Date | null) => void;
}

type PlanMode = "unlimited" | "quota";

interface State {
  mode: PlanMode;
  quota: string;
  isIndefinitely: boolean;
  minPlanEndAt: Date;
  planEndAt: Date;
}

export function useSetPlanModalHandle() {
  const initialState: State = useMemo(() => {
    const now = new Date();
    return {
      mode: "unlimited",
      quota: String(INITIAL_QUOTA),
      isIndefinitely: true,
      minPlanEndAt: now,
      planEndAt: now,
    };
  }, []);
  const [state, setState] = useState<State>(initialState);

  const [targetPlanName, setTargetPlanName] = useState("");
  const [dialogProps, setDialogProps] = useState<Pick<
    Props,
    "onConfirm" | "onCancel"
  > | null>(null);

  interface Result {
    quota: number | null;
    planEndAt: Date | null;
  }

  const open = useCallback(
    async (
      targetPlanName: string,
      quota?: number | null,
      planEndAt?: Date | null
    ) => {
      setTargetPlanName(targetPlanName);
      setState({
        ...initialState,
        mode: quota == null ? "unlimited" : "quota",
        quota: String(quota ?? INITIAL_QUOTA),
        isIndefinitely: planEndAt == null,
        planEndAt: planEndAt ?? initialState.planEndAt,
      });

      return await new Promise<Result>((resolve, reject) => {
        setDialogProps({
          onConfirm: (quota, planEndAt) => {
            setDialogProps(null);
            resolve({ quota, planEndAt });
          },
          onCancel: () => {
            setDialogProps(null);
            reject(errors.ConfirmationRejected);
          },
        });
      });
    },
    [initialState]
  );

  return useMemo(
    () => ({
      open,
      props: {
        isOpen: dialogProps != null,
        targetPlanName,
        state,
        setState,
        onCancel: dialogProps?.onCancel ?? (() => {}),
        onConfirm: dialogProps?.onConfirm ?? (() => {}),
      } as Props,
    }),
    [open, dialogProps, targetPlanName, state, setState]
  );
}

export function SetPlanModal(props: Props) {
  const { isOpen, onCancel, onConfirm, targetPlanName, state, setState } =
    props;
  const { localized } = useLocale();

  const isStateValid = useMemo(() => {
    if (state.mode === "quota") {
      return !isNaN(Number(state.quota));
    }
    return true;
  }, [state]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const quota = Number(state.quota);
      onConfirm(
        state.mode === "quota" && !isNaN(quota) ? quota : null,
        state.isIndefinitely ? null : state.planEndAt
      );
    },
    [onConfirm, state]
  );

  const planDisplayName = useMemo(() => {
    const messageId = PLAN_NAME_MESSAGE_IDS[targetPlanName];
    if (messageId == null) {
      return targetPlanName;
    }
    return localized(messageId);
  }, [targetPlanName, localized]);

  const dialogContentProps = useMemo<IDialogContentProps>(
    () => ({
      type: DialogType.normal,
      title: localized("team.detail.subscription.set_plan.title", {
        plan: planDisplayName,
      }),
    }),
    [localized, planDisplayName]
  );

  const options: IChoiceGroupOption[] = useMemo(() => {
    return [
      {
        key: "unlimited",
        text: localized("team.detail.subscription.set_plan.mode.unlimited"),
      },
      {
        key: "quota",
        text: localized("team.detail.subscription.set_plan.mode.quota"),
      },
    ];
  }, [localized]);

  const handleModeOnChange = useCallback(
    (_e, option?: IChoiceGroupOption) => {
      if (option) {
        setState(s => ({ ...s, mode: option.key as PlanMode }));
      }
    },
    [setState]
  );

  const handleQuotaOnChange = useCallback(
    (_e, value?: string) => {
      if (value != null) {
        setState(s => ({ ...s, quota: value }));
      }
    },
    [setState]
  );

  const handleIsIndefinitelyOnChange = useCallback(
    (_e, value?: boolean) => {
      if (value == null) {
        return;
      }
      setState(s => ({ ...s, isIndefinitely: value }));
    },
    [setState]
  );

  const handlePlanEndAtOnChange = useCallback(
    (date?: Date | null) => {
      if (date != null) {
        setState(s => ({ ...s, planEndAt: date }));
      }
    },
    [setState]
  );

  const formatDate = useCallback((date?: Date) => {
    return date == null
      ? ""
      : DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT, {
          // TODO: use locale: en -> en-HK
          locale: "en-HK",
        });
  }, []);

  return (
    <Dialog
      minWidth={400}
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onSubmit}>
        <ChoiceGroup
          selectedKey={state.mode}
          options={options}
          onChange={handleModeOnChange}
          label={localized("team.detail.subscription.set_plan.mode.title", {
            plan: planDisplayName,
          })}
        />

        {state.mode === "quota" && (
          <TextField
            label={localized("team.detail.subscription.set_plan.quota.label")}
            type="number"
            min="1"
            onChange={handleQuotaOnChange}
            value={state.quota}
            errorMessage={
              isNaN(Number(state.quota))
                ? localized("team.detail.subscription.set_plan.quota.error")
                : undefined
            }
          />
        )}

        <Checkbox
          className="mt-4"
          checked={state.isIndefinitely}
          label={localized(
            "team.detail.subscription.set_plan.is_indefinitely.title",
            { plan: planDisplayName }
          )}
          onChange={handleIsIndefinitelyOnChange}
        />
        {state.isIndefinitely ? null : (
          <DatePicker
            className="mt-3"
            label={localized(
              "team.detail.subscription.set_plan.plan_end_at.label"
            )}
            onSelectDate={handlePlanEndAtOnChange}
            value={state.planEndAt}
            minDate={state.minPlanEndAt}
            formatDate={formatDate}
          />
        )}

        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton
            type="submit"
            disabled={!isStateValid}
            text={localized("common.save")}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
