import {
  CustomModel,
  CustomModelLabelSchema,
  CustomModelLabelSchemaAttribute,
} from "../types/customModel";
import { CustomModelLabelSchemaTypeDefintion } from "./customModelLabelSchema";

export const DefaultCustomModel: CustomModel = {
  id: "",
  name: "",
  config: {
    remark: "",
    trainingRequested: false,
    labellingStarted: false,
    fxcmType: "doc_info",
    labelSchema: [],
    unfrozenLabelSchema: [],
    llmParameters: {
      should_preserve_horizontal_whitespace: true,
    },
    separatePromptByFields: [],
  },
  CVATProjectID: null,
  formID: null,
  lastCVATProjectStartSyncAt: null,
  lastCVATProjectFinishSyncAt: null,
  startTrainingAt: null,
  finishTrainingAt: null,
  isTrainingFailed: false,
  startDeploymentAt: null,
  finishDeploymentAt: null,
  modelVersions: [],
  resourceOwnerId: "",
  status: "not_synced",
  noOfSampleImages: 0,
  workspaces: [],
};

export const DefaultCustomModelLabelSchema: CustomModelLabelSchema = {
  name: "",
  type: "text",
  attributes: CustomModelLabelSchemaTypeDefintion["text"].attributes.map(
    (x): CustomModelLabelSchemaAttribute => x
  ),
};

export const CustomModelEditorRightBarTargetIds = {
  NextStepButton: "custom-model-editor-right-bar-next-step",
};

export const MinSampleImageForTraining = 10;

export const CustomModelPresets = [
  "intl_id",
  "passport",
  "receipt",
  "invoice",
] as const;
