import { Icon } from "@fluentui/react";
import cn from "classnames";
import React from "react";

type TextButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: "normal" | "destroy";
  iconName: string;
  iconClassName?: string;
  reverseIconPosition?: boolean;
};

export function TextButton(props: TextButtonProps) {
  const {
    className,
    children,
    variant = "normal",
    iconName,
    iconClassName,
    reverseIconPosition,
    ...rest
  } = props;

  return (
    <button
      className={cn(
        "flex",
        "select-none enabled:cursor-pointer",
        "bg-transparent border-none",
        "text-sm font-semibold",
        {
          "text-primary-500 hover:text-primary-400 active:text-primary-600":
            variant === "normal",
          "text-red-600 hover:text-red-500 active:text-red-400":
            variant === "destroy",
          "flex-row-reverse": reverseIconPosition ?? false,
        },
        "disabled:text-gray-600 disabled:hover:text-gray-600 disabled:active:text-gray-600",
        className
      )}
      {...rest}
    >
      <Icon iconName={iconName} className={cn("w-5 h-5 mr-2", iconClassName)} />
      <span>{children}</span>
    </button>
  );
}
