import React, { useCallback } from "react";

import { useConfirmModalActionCreator } from "../../actions/confirmModal";
import { PLAN_NAMES } from "../../constants";
import { ConfirmModalType } from "../../types/confirmation";
import { TeamDetail } from "../../types/team";
import LoadingModal from "../LoadingModal";
import { ManagePlan } from "./ManagePlan";
import { SetLimits } from "./SetLimits";
import { SetPlanModal, useSetPlanModalHandle } from "./SetPlanModal";
import { StripeDetails } from "./StripeDetails";

interface Props {
  team: TeamDetail;
  isLoading: boolean;
  giveTrial: (quota: number | null, planEndAt: Date | null) => Promise<void>;
  removeTrial: () => Promise<void>;
  upgradeToEnterprise: (
    quota: number | null,
    planEndAt: Date | null
  ) => Promise<void>;
  downgradeFromEnterprise: () => Promise<void>;
  setQuota: (
    softLimit: number | null,
    hardLimit: number | null,
    usageCap: number | null
  ) => Promise<void>;
}

function _TeamDetailPlan(props: Props) {
  const {
    team,
    isLoading,
    giveTrial,
    removeTrial,
    upgradeToEnterprise,
    downgradeFromEnterprise,
    setQuota,
  } = props;

  const {
    planName,
    planIsPaid,
    planEndAt,
    rawQuota,
    planQuota,
    subscriptionSubscribedAt,
    stripeSubscriptionId,
    stripeCustomerId,
  } = team;

  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const { open, props: setPlanModalProps } = useSetPlanModalHandle();

  const handleOnUpgradePaid = useCallback(() => {
    requestUserConfirmation(
      {
        titleId:
          "team.detail.subscription.manage_plan.modals.upgrade_paid.title",
        actionId:
          "team.detail.subscription.manage_plan.modals.upgrade_paid.confirm",
        messageId:
          "team.detail.subscription.manage_plan.modals.upgrade_paid.message",
        type: ConfirmModalType.Normal,
        hideCancel: true,
      },
      false
    );
  }, [requestUserConfirmation]);

  const handleOnGiveTrial = useCallback(() => {
    open(PLAN_NAMES.trial)
      .then(({ quota, planEndAt }) => giveTrial(quota, planEndAt))
      .catch(() => {});
  }, [open, giveTrial]);

  const handleOnUpgradeEnterprise = useCallback(() => {
    if (planIsPaid) {
      requestUserConfirmation(
        {
          titleId:
            "team.detail.subscription.manage_plan.modals.paid_upgrade_enterprise.title",
          actionId:
            "team.detail.subscription.manage_plan.modals.paid_upgrade_enterprise.confirm",
          messageId:
            "team.detail.subscription.manage_plan.modals.paid_upgrade_enterprise.message",
          type: ConfirmModalType.Normal,
          hideCancel: true,
        },
        false
      );
      return;
    }

    open(PLAN_NAMES.enterprise)
      .then(({ quota, planEndAt }) => upgradeToEnterprise(quota, planEndAt))
      .catch(() => {});
  }, [planIsPaid, requestUserConfirmation, open, upgradeToEnterprise]);

  const handleOnRemoveTrial = useCallback(() => {
    requestUserConfirmation({
      titleId: "team.detail.subscription.manage_plan.modals.remove_trial.title",
      actionId:
        "team.detail.subscription.manage_plan.modals.remove_trial.confirm",
      messageId:
        "team.detail.subscription.manage_plan.modals.remove_trial.message",
      type: ConfirmModalType.Normal,
    })
      .then(() => removeTrial())
      .catch(() => {});
  }, [removeTrial, requestUserConfirmation]);

  const handleOnDowngradeEnterprise = useCallback(() => {
    requestUserConfirmation({
      titleId:
        "team.detail.subscription.manage_plan.modals.downgrade_enterprise.title",
      actionId:
        "team.detail.subscription.manage_plan.modals.downgrade_enterprise.confirm",
      messageId:
        "team.detail.subscription.manage_plan.modals.downgrade_enterprise.message",
      type: ConfirmModalType.Normal,
    })
      .then(() => downgradeFromEnterprise())
      .catch(() => {});
  }, [downgradeFromEnterprise, requestUserConfirmation]);

  const handleOnEditPlan = useCallback(
    (quota: number | null, planEndAt: Date | null) => {
      if (planName === PLAN_NAMES.trial) {
        open(PLAN_NAMES.trial, quota, planEndAt)
          .then(({ quota, planEndAt }) => giveTrial(quota, planEndAt))
          .catch(() => {});
      } else if (planName === PLAN_NAMES.enterprise) {
        open(PLAN_NAMES.enterprise, quota, planEndAt)
          .then(({ quota, planEndAt }) => upgradeToEnterprise(quota, planEndAt))
          .catch(() => {});
      }
    },
    [planName, open, giveTrial, upgradeToEnterprise]
  );

  return (
    <div className="py-5 space-y-12">
      <ManagePlan
        planName={planName}
        planEndAt={planEndAt ?? null}
        rawQuota={rawQuota}
        planQuota={planQuota}
        onGiveTrial={handleOnGiveTrial}
        onUpgradePaid={handleOnUpgradePaid}
        onUpgradeEnterprise={handleOnUpgradeEnterprise}
        onRemoveTrial={handleOnRemoveTrial}
        onDowngradeEnterprise={handleOnDowngradeEnterprise}
        onEditPlan={handleOnEditPlan}
      />
      {stripeCustomerId != null ? (
        <StripeDetails
          customerId={stripeCustomerId}
          subscriptionId={stripeSubscriptionId ?? null}
          subscriptionSubscribedAt={subscriptionSubscribedAt ?? null}
        />
      ) : null}
      <SetLimits
        rawQuota={rawQuota}
        planQuota={planQuota}
        setQuota={setQuota}
      />

      <SetPlanModal {...setPlanModalProps} />
      <LoadingModal isOpen={isLoading} />
    </div>
  );
}

export const TeamDetailPlan = React.memo(_TeamDetailPlan);
export default TeamDetailPlan;
