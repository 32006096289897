import * as React from "react";

import { README_IO_PAGE_URL } from "../../constants";
import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useWorkerToken } from "../../hooks/app";
import { useDesktopApp } from "../../hooks/desktopApp";
import { useReadmeIO } from "../../hooks/readmeIO";
import { CustomModel } from "../../types/customModel";
import { ExtractNote } from "../ExtractNote";
import { FSLActiveModelPicker } from "../FSLActiveModelPicker";
import { FSLSplitView } from "../FSLSplitView";
import { MissionBar, MissionBarType } from "../MissionBar";
import styles from "./styles.module.scss";

export enum State {
  SchemaNotSetState,
  ReadyState,
}

export function useFSLExtractNote(customModel: CustomModel) {
  const state =
    customModel.config.extractedContentSchema !== undefined
      ? State.ReadyState
      : State.SchemaNotSetState;
  const {
    token: workerToken,
    tokens: workerTokens,
    onSelectToken: onSelectWorkerToken,
  } = useWorkerToken();
  const formId = customModel?.formID ?? "";

  const { onRedirectToReadmeIO } = useReadmeIO();

  const onOpenReference = React.useCallback(() => {
    onRedirectToReadmeIO(README_IO_PAGE_URL.referenceDocumentExtraction, {
      parameters: { formId },
    });
  }, [formId, onRedirectToReadmeIO]);

  return React.useMemo(
    () => ({
      workerToken,
      workerTokens,
      state,
      customModel,
      onSelectWorkerToken,
      onOpenReference,
    }),
    [
      workerToken,
      workerTokens,
      state,
      customModel,
      onSelectWorkerToken,
      onOpenReference,
    ]
  );
}

export function FSLExtractNoteImpl(
  props: ReturnType<typeof useFSLExtractNote>
) {
  const {
    workerToken,
    workerTokens,
    state,
    customModel,
    onSelectWorkerToken,
    onOpenReference,
  } = props;

  const { onMacOsAppClick, onWindowsAppClick, onOpenInDesktopApp } =
    useDesktopApp(
      workerToken,
      customModel?.formID ?? "",
      customModel?.name ?? ""
    );

  const { isStandardModelAvailable } = useFSLCustomModelEditor();

  return (
    <FSLSplitView
      left={
        <>
          {state === State.SchemaNotSetState && (
            <div className={styles["mission-ar"]}>
              <MissionBar
                type={MissionBarType.Warning}
                title="fsl_custom_model.api.schema_not_set.warning"
              />
            </div>
          )}
          <ExtractNote
            token={workerToken}
            tokens={workerTokens}
            onSelectToken={onSelectWorkerToken}
            extractorId={customModel?.formID ?? ""}
            onMacOsAppClick={onMacOsAppClick}
            onWindowsAppClick={onWindowsAppClick}
            onOpenInDesktopApp={onOpenInDesktopApp}
            onOpenReference={onOpenReference}
            showNavigateBar={false}
          />
        </>
      }
      right={<FSLActiveModelPicker />}
      rightVisible={isStandardModelAvailable}
      isDividerVisible={true}
      rightMaxWidth={284}
      rightMinWidth={284}
    />
  );
}

interface FSLExtractNoteProps {
  customModel: CustomModel;
}

export function FSLExtractNote(props: FSLExtractNoteProps) {
  const { customModel } = props;
  const states = useFSLExtractNote(customModel);
  return <FSLExtractNoteImpl {...states} />;
}
