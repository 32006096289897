import { TextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";

import { PLAN_NAMES, PLAN_NAME_MESSAGE_IDS } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { PlanQuota } from "../../types/quota";
import { TextButton } from "../TextButton";
import { DefaultButton } from "../WrappedMSComponents/Buttons";

interface ManagePlanProps {
  planName: string;
  planEndAt: Date | null;
  rawQuota: PlanQuota | null;
  planQuota: PlanQuota | null;

  onGiveTrial: () => void;
  onUpgradePaid: () => void;
  onUpgradeEnterprise: () => void;
  onRemoveTrial: () => void;
  onDowngradeEnterprise: () => void;
  onEditPlan: (quota: number | null, planEndAt: Date | null) => void;
}

export function ManagePlan(props: ManagePlanProps) {
  const {
    planName,
    planEndAt,
    rawQuota,
    planQuota,
    onGiveTrial,
    onUpgradePaid,
    onUpgradeEnterprise,
    onRemoveTrial,
    onDowngradeEnterprise,
    onEditPlan,
  } = props;
  const { localized } = useLocale();

  const quota =
    rawQuota?.aggregate?.hardLimit ?? planQuota?.aggregate?.hardLimit ?? null;
  const showQuota =
    planName === PLAN_NAMES.enterprise || planName === PLAN_NAMES.trial;

  const planDisplayName = useMemo(() => {
    const messageId = PLAN_NAME_MESSAGE_IDS[planName];
    if (messageId == null) {
      return planName;
    }
    return localized(messageId);
  }, [planName, localized]);

  const planEndDate = useMemo(() => {
    if (planEndAt == null) {
      return null;
    }

    const date = DateTime.fromJSDate(planEndAt);
    return date.toLocaleString(DateTime.DATE_SHORT, {
      // TODO: use locale: en -> en-HK
      locale: "en-HK",
    });
  }, [planEndAt]);

  const handleEditOnClick = useCallback(() => {
    onEditPlan(quota, planEndAt);
  }, [onEditPlan, planEndAt, quota]);

  return (
    <section>
      <h3 className="pb-3 mb-2 border-gray-200 border-0 border-b-2 border-solid text-xl font-semibold">
        <FormattedMessage id="team.detail.subscription.manage_plan.title" />
      </h3>
      <div className="flex gap-5">
        <TextField
          className="w-[360px]"
          inputClassName="bg-gray-50 text-gray-800"
          label={localized("team.detail.subscription.manage_plan.current_plan")}
          value={planDisplayName}
          borderless={true}
          readOnly={true}
        />
        {showQuota ? (
          <div className="w-[360px] relative">
            <TextField
              inputClassName="bg-gray-50 text-gray-800"
              label={localized("team.detail.subscription.manage_plan.quota")}
              value={
                quota == null
                  ? localized(
                      "team.detail.subscription.manage_plan.quota_unlimited"
                    )
                  : String(quota)
              }
              borderless={true}
              readOnly={true}
            />
            <TextButton
              className="absolute top-0 right-0 mt-1"
              iconName="Edit"
              onClick={handleEditOnClick}
            >
              <FormattedMessage id="team.detail.subscription.manage_plan.actions.edit" />
            </TextButton>
          </div>
        ) : null}
        {planEndDate != null ? (
          <TextField
            className="w-[360px]"
            inputClassName="bg-gray-50 text-gray-800"
            label={localized(
              "team.detail.subscription.manage_plan.plan_end_at"
            )}
            value={planEndDate}
            borderless={true}
            readOnly={true}
          />
        ) : null}
      </div>

      <div className="flex gap-4 mt-2 empty:hidden">
        {planName === PLAN_NAMES.trial ? (
          <TextButton
            iconName="Cancel"
            variant="destroy"
            onClick={onRemoveTrial}
          >
            <FormattedMessage id="team.detail.subscription.manage_plan.actions.remove_trial" />
          </TextButton>
        ) : null}
        {planName === PLAN_NAMES.enterprise ? (
          <TextButton
            iconName="Cancel"
            variant="destroy"
            onClick={onDowngradeEnterprise}
          >
            <FormattedMessage id="team.detail.subscription.manage_plan.actions.downgrade_enterprise" />
          </TextButton>
        ) : null}
      </div>

      <div className="flex gap-4 mt-4">
        {planName === PLAN_NAMES.free ? (
          <DefaultButton
            textId="team.detail.subscription.manage_plan.actions.give_trial"
            onClick={onGiveTrial}
          />
        ) : null}
        {planName === PLAN_NAMES.free || planName === PLAN_NAMES.trial ? (
          <DefaultButton
            textId="team.detail.subscription.manage_plan.actions.upgrade_paid"
            onClick={onUpgradePaid}
          />
        ) : null}
        {planName === PLAN_NAMES.payAsYouGo ? (
          <DefaultButton
            textId="team.detail.subscription.manage_plan.actions.upgrade_starter"
            onClick={onUpgradePaid}
          />
        ) : null}
        {planName !== PLAN_NAMES.enterprise ? (
          <DefaultButton
            textId="team.detail.subscription.manage_plan.actions.upgrade_enterprise"
            onClick={onUpgradeEnterprise}
          />
        ) : null}
      </div>
    </section>
  );
}
