import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { WorkspaceIntegrationSection } from "../components/WorkspaceIntegration";
import { WorkspaceTab } from "../components/WorkspaceNavBar";
import {
  WorkspaceIntegrationProvider,
  useWorkspaceIntegration,
} from "../contexts/workspaceIntegration";
import { useOnload } from "../hooks/asyncguard/asyncguard";
import { useSharePointIntegration } from "../hooks/workspace_integration";
import HeaderContainer from "./Header";
import { WorkspaceNavBarLayoutContainer as WorkspaceNavBarLayout } from "./WorkspaceNavBarLayout";

export function WorkspaceIntegrationContainerImpl() {
  const containerProps = useWorkspaceIntegration();
  const sharePointIntegrationProps = useSharePointIntegration();
  const {
    workspace,
    isLoading,
    googleAuth,
    googleSheetIntegrationProps,
    oauthProps,
    workspaceWebhooks,
  } = containerProps;

  useOnload(() => {
    containerProps.load();
  });

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={isLoading} />
      <Main hasTop={true}>
        {workspace.state === "error" && (
          <ErrorPlaceholder messageId="common.fail_to_fetch_workspace" />
        )}
        {workspace.state === "success" && (
          <WorkspaceNavBarLayout selectedTab={WorkspaceTab.Integrations}>
            <WorkspaceIntegrationSection
              googleAuth={googleAuth}
              googleSheetIntegrationProps={googleSheetIntegrationProps}
              oauthProps={oauthProps}
              sharePointIntegrationProps={sharePointIntegrationProps}
              workspace={workspace.data}
              workspaceWebhooks={workspaceWebhooks}
            />
          </WorkspaceNavBarLayout>
        )}
      </Main>
    </Layout>
  );
}

export function WorkspaceIntegrationContainer() {
  return (
    <WorkspaceIntegrationProvider>
      <WorkspaceIntegrationContainerImpl />
    </WorkspaceIntegrationProvider>
  );
}

export default React.memo(WorkspaceIntegrationContainer);
