import React from "react";
import { useNavigate } from "react-router";

import { useWorkspaceActionCreator } from "../actions/workspace";
import { Layout, Main, Top } from "../components/Layout";
import {
  ExtractorConnection,
  WorkspaceCreateSection,
  WorkspaceCreateSectionProps,
} from "../components/WorkspaceCreate";
import { useLocale } from "../contexts/locale";
import { useCreatePrebuiltTemplatedExtractor } from "../hooks/extractor";
import { useExtractorOptions } from "../hooks/extractorOption";
import { useToast } from "../hooks/toast";
import { CustomModel } from "../types/customModel";
import {
  PrebuiltTemplatedExtractor,
  mapExtractorTypeToMessageId,
} from "../types/extractor";
import { DetailedForm } from "../types/form";
import { DetailFormGroup } from "../types/formGroup";
import HeaderContainer from "./Header";

function useWorkspaceCreateContainer() {
  const { createWorkspace } = useWorkspaceActionCreator();
  const { createPrebuiltTemplatedExtractor } =
    useCreatePrebuiltTemplatedExtractor();
  const { extractorOptions } = useExtractorOptions();

  const toast = useToast();
  const navigate = useNavigate();
  const { localized } = useLocale();
  const [isCreating, setIsCreating] = React.useState(false);

  const tryCreateWorkspace = React.useCallback(
    async (args: { name: string; formId?: string; formGroupId?: string }) => {
      try {
        return await createWorkspace(args);
      } catch (e) {
        toast.error("error.workspace.fail_to_create_workspace");
        throw e;
      }
    },
    [createWorkspace, toast]
  );

  const tryCreatePreTrained = React.useCallback<
    (args: { name: string; type: PrebuiltTemplatedExtractor }) => Promise<{
      form?: DetailedForm;
      formGroup?: DetailFormGroup;
      customModel?: CustomModel;
    }>
  >(
    async args => {
      try {
        return await createPrebuiltTemplatedExtractor(args.name, args.type);
      } catch (e) {
        toast.error("error.workspace.fail_to_create_pre_trained");
        throw e;
      }
    },
    [createPrebuiltTemplatedExtractor, toast]
  );

  const onCreateWorkspace = React.useCallback<
    WorkspaceCreateSectionProps["onCreateWorkspace"]
  >(
    async input => {
      setIsCreating(true);
      try {
        switch (input.extractorConnection) {
          case ExtractorConnection.PreTrained: {
            const { form, formGroup, customModel } = await tryCreatePreTrained({
              name: `${input.workspaceName} - ${localized(
                mapExtractorTypeToMessageId(input.extractor)
              )}`,
              type: input.extractor,
            });
            const workspace = await tryCreateWorkspace({
              name: input.workspaceName,
              ...(form != null ? { formId: form.id } : {}),
              ...(formGroup != null ? { formGroupId: formGroup.id } : {}),
              ...(customModel?.formID != null
                ? { formId: customModel.formID }
                : {}),
            });
            navigate(`/workspace/${workspace.id}`);
            break;
          }
          case ExtractorConnection.Existing: {
            const extractorId =
              input.extractor.associatedExtractorId ?? input.extractor.id;
            const workspace = await tryCreateWorkspace({
              name: input.workspaceName,
              ...(input.extractor.resourceType === "form_group"
                ? { formGroupId: extractorId }
                : { formId: extractorId }),
            });
            navigate(`/workspace/${workspace.id}`);
            break;
          }
        }
      } catch {
        setIsCreating(false);
        // error handled within
      }
    },
    [localized, navigate, tryCreatePreTrained, tryCreateWorkspace]
  );

  return React.useMemo(
    () => ({
      extractorOptions,
      onCreateWorkspace,
      isCreating,
      didLoadExtractorOptions: extractorOptions != null,
    }),
    [extractorOptions, isCreating, onCreateWorkspace]
  );
}

export default function WorkspaceCreateContainer() {
  const props = useWorkspaceCreateContainer();
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <WorkspaceCreateSection
          extractorOptions={props.extractorOptions}
          onCreateWorkspace={props.onCreateWorkspace}
          isCreating={props.isCreating}
          didLoadExtractorOptions={props.didLoadExtractorOptions}
        />
      </Main>
    </Layout>
  );
}
